// local

// export default {
//     nativetoken: {
//         56: '',
//         97: '0xB91cE3a9095CD9A01Ab419081c8F63bf6d9ABe2a',
//     },
//     xtoken: {
//         56: '',
//         97: '0x0D3B32a16dab18173696b6cbB267D6fc0018D3a8',
//     },
//     dividends: {
//         56: '',
//         97: '0x51cf7388044bB4C119941973161328aEbB52809b',
//     },
//     multicall:{
//         56: '',
//         97: '0x232e410b1fa58fa37921ad03E5deC6a507cd5CDe',
//     },
//     router:{
//         56: '',
//         97: '0x773212bAea847b7b091eCc2Eb9a96E3b240Af21D'//'0x4E03F9e5B375008d84F54C5feE6f5835843fAE77' //'0x15b9456f3F4c6F147bE2493B45C19f510cf1337f',
//     },
//     factory:{
//         56: '',
//         97: '0x60e6d21F53b5cB53085B71785F26afC0Dd923aa8'//'0xD919BC4Bed9FcA4264dc178D94D2Db762833Dc96' //'0xCAEE07F8A8C1DA4546E864863139137F0951F450',
//         // 97: '0x4E03F9e5B375008d84F54C5feE6f5835843fAE77',
//     },
//     masterChef:{
//         56: '',
//         97:'0x52883c4253b064e3787FD808fdDe6d7d5cEC8bfB'//"0x82928cBB69CED2D7a301c2B468acbD066cF1c3Fc",//'0x99Cc266c865f480d9C21Dbd0C538CFC9c843e4d8'//'0x05990d2619b89BdDa6eD51A3e461A9145487634E' //'0xCde08C466BD78a67f1c0a7b27779aBc012063d25',
//     },
//     SmartChefFactory:{
//         56: '',
//         97:'0x9ce139296D1c1d2704f384f57055fd2c59C12853'
//     },
//     poolAddress:{
//         56:'',
//         97:'0xeAa81cc2174e73b4C4311f4c7182dC746D1B1627'
//     }
// }


//demo

export default {
    nativetoken: {
        56: '',
        97: '0xB91cE3a9095CD9A01Ab419081c8F63bf6d9ABe2a',
    },
    xtoken: {
        56: '',
        97: '0x0D3B32a16dab18173696b6cbB267D6fc0018D3a8',
    },
    dividends: {
        56: '',
        97: '0x51cf7388044bB4C119941973161328aEbB52809b',
    },
    multicall:{
        56: '',
        97: '0x232e410b1fa58fa37921ad03E5deC6a507cd5CDe',
    },
    router:{
        56: '',
        97: '0xF54F35676184eC110a4B7a64A1952f678eA2aadE',//'0xca0b854D63028A05d8d7c30CD37bcbA21949cd0d',
    },
    factory:{
        56: '',
        97: '0x6b127794988a7d2697281aAcBd2663d1a6A4D600',//'0xe88992e2D1319Fa87b92524B43493104D3E8E152'
    },
    masterChef:{
        56: '',
        97:'0xa69e72EDA8E1F6BFEAff0bF11d84E1a91951F90A',//0xe3e9828af25d100FFc23470529DdE9Bc7e477619',//'0xD1b48eE72cB6F6c54BAa9D327Fb1f38cc1ba8011',//'0xe57dFF76A740274314b163A892D81E7C0B5E21a8',
    },
    SmartChefFactory:{
        56: '',
        97:'0xc7529dce5146f88c9c6f8aa0a9f0e6edd1f58d0d' //'0xeb44385550cfAa33596Cf4bB5f5B144af4299B17'//'0xABA4718512e21D973eeEb5DEAA6C36fE0Da212df'
    },
    poolAddress:{
        56:'',
        97:'0xa270c919a30838D82B5b763b82a9D6AFA699Ee12'
    }
}