import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import cascading from "../assests/css/buyticketstyle.module.css";
import { FaRegQuestionCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { IoMdClose } from "react-icons/io";



export default function Viewticketmodal(props) {
  // state
  const [viewticketmodal, setViewticketmodal] = useState(true);



  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal
          centered
          show={viewticketmodal}
          onHide={props.onHide}
          className={`${cascading.modalstyle}`}
        >
          <Modal.Header
            className={`${cascading.modalclr} ${cascading.modalheader}`}
          >
            <h4 className={`${cascading.modaltitle} mb-0`}>Round 1207</h4>
            <IoMdClose
              className={`${cascading.closebtn}`}
              onClick={props.onHide}
            />
          </Modal.Header>
          <Modal.Body
            className={`${cascading.modalclr} ${cascading.modalbody}`}
          >
            <p className={`${cascading.innerhead}`}>Your Tickets:</p>
            <>           
             <p>#1</p>

            <div className={`${cascading.inputsec} `}>
                   

                 
                    <div className={`${cascading.balancerow}`}>
                    
                     
                    <p className={`${cascading.inputlabelticket} mb-0 mt-0`}>
                        <span>4</span>
                        <span>0</span>
                        <span>2</span>
                        <span>5</span>
                        <span>4</span>
                        <span>2</span>

                    </p>
              
                </div>
                  </div>
                  <hr />

                  <p>#2</p>

<div className={`${cascading.inputsec} `}>
       

     
        <div className={`${cascading.balancerow}`}>
        
         
        <p className={`${cascading.inputlabelticket} mb-0 mt-0`}>
            <span>8</span>
            <span>1</span>
            <span>0</span>
            <span>9</span>
            <span>2</span>
            <span>7</span>

        </p>
  
    </div>
      </div>
      <hr />


</>

     
         


       
            <button 
              className={`btn ${cascading.connectwalletbtn}`}
              type="button"
              onClick={props.onShowBuy}
            >
              Buy Tickets
            </button>
          

          </Modal.Body>
        </Modal>
      </div>
    
    </>
  );
}
