import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import cascading from "../assests/css/confirmswapstyle.module.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import infoimg from "../assests/images/infoicon.png";

import { IoMdClose } from "react-icons/io";
import { FaDownLong } from "react-icons/fa6";
export default function ClaimModal(props) {
  return (
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal
        centered
        show={props.show}
        onHide={props.onHide}
        className={`${cascading.modalstyle}`}
      >
        <Modal.Header
          className={`${cascading.modalclr} ${cascading.modalheader}`}
        >
          <h4 className={`${cascading.modaltitle} mb-0`}>Nothing to Collect</h4>
          <IoMdClose
            className={`${cascading.closebtn}`}
            onClick={props.onHide}
          />
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          <div>
            <p className={`${cascading.desccontent} text-start mb-2`}>
              You have no unclaimed v0.1 prizes.
            </p>
            <p className={`${cascading.desccontent} text-start`}>
              Download your v0.1 Prediction history below.
            </p>
            <button
              className={`btn ${cascading.connectwalletbtn}`}
              type="button"
              onClick={() => {
                props.onHide();
              }}
            >
              Download CSV
            </button>
          </div>
          {/* <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button> */}
        </Modal.Body>
      </Modal>
    </div>
  );
}
