import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import cascading from "../assests/css/buyticketstyle.module.css";
import { FaRegQuestionCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { IoMdClose } from "react-icons/io";



export default function Editticketmodal(props) {
  // state
  const [editticketmodal, setEditticketmodal] = useState(true);
  const [random, setRandom] = useState(false);




  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal
          centered
          show={editticketmodal}
          onHide={props.onHide}
          className={`${cascading.modalstyle}`}
        >
          <Modal.Header
            className={`${cascading.modalclr} ${cascading.modalheader}`}
          >
            <h4 className={`${cascading.modaltitle} mb-0`}>Edit Numbers</h4>
            <IoMdClose
              className={`${cascading.closebtn}`}
              onClick={props.onHide}
            />
          </Modal.Header>
          <Modal.Body
            className={`${cascading.modalclr} ${cascading.modalbody}`}
          >

<div className={`mt-3 ${cascading.secrow}`}>
                      <p
                        className={`mb-0 d-flex align-items-center ${cascading.labeledit}`}
                      >
                        Total Cost:
                     
                      </p>
                      <p className={`mb-0 ${cascading.labelsedit}`}>
                      1 BSC
                      </p>
                    </div>

                    <p className="mt-3">Numbers are randomized, with no duplicates among your tickets. Tap a number to edit it. Available digits: 0-9</p>
                    <button 
              className={`btn ${cascading.connectwalletbtn}`}
              type="button"
              onClick={()=>{setRandom(!random)}}
            >
            <span>{random?"Nonrandomize":"Randomize"}</span>
            </button>
             <p className="mt-3">#001</p>
               
            {random ?
            
            <>     

            <div className={`${cascading.inputsec} `}>
                   

            <div className={`${cascading.balancerow}`}>
        
         
        <p className={`${cascading.inputlabelticket} mb-0 mt-0`}>
            <span>8</span>
            <span>1</span>
            <span>0</span>
            <span>9</span>
            <span>2</span>
            <span>7</span>

        </p>
  
    </div>


                 
                  </div>
                  </>
                  :
                  <>
                  <div className={`${cascading.inputsec} `}>



                  <div className={`${cascading.balancerow}`}>
                    
                     
                    <p className={`${cascading.inputlabelticket} mb-0 mt-0`}>
                        <span>
                            <input className={`${cascading.inputticket}`} type="text" />
                        </span>
                        <span>
                        <input className={`${cascading.inputticket}`} type="text" />

                        </span>
                        <span>
                            
                        <input className={`${cascading.inputticket}`} type="text" />

                        </span>
                        <span>
                        <input className={`${cascading.inputticket}`} type="text" />
                            
                        </span>
                        <span>
                        <input className={`${cascading.inputticket}`} type="text" />

                        </span>
                        <span>
                        <input className={`${cascading.inputticket}`} type="text" />

                        </span>

                    </p>
              
                </div>
                </div>
                </>
                }
                
                  <hr />

          



     
         


       
            <button 
              className={`btn ${cascading.connectwalletbtn}`}
              type="button"
              onClick={props.onShowBuy}
            >
            Confirm and Buy
            </button>
          

          </Modal.Body>
        </Modal>
      </div>
    
    </>
  );
}
