import bnbimg from '../assests/images/bnb.png'

export const sleep = (ms) => {
  new Promise(resolve => setTimeout(resolve, ms))
}

export const testnetTokens = [
  {
    address: '0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c',
    decimal: 18,
    symbol: 'BNB',
    name: 'BNB',
    image: bnbimg,
    commonBase:true 
  },
  {
    address: '0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c',
    decimal: 18,
    symbol: 'WBNB',
    name: 'Wrapped BNB',
    image: bnbimg ,
    commonBase :true
  },
  {
    address: '0x91C7bE4449c76a47EAe1605De7Bcd5a03Cc7d3d2' ,//'0xf9b391Eef761F255Bcde2815fE615F5A1e8b0414',
    decimal: 18,
    symbol: 'BUSD',
    name: 'Binance USD',
    image: bnbimg ,
    commonBase :true
  },
  {
    address: '0xa2c5C316365981B1ff903b76AB0b1B7f3A77C7E5',
    decimal: 18,
    symbol: 'DT',
    name: 'DONUT Token',
    image: bnbimg ,
    commonBase :true
  },
  {
    address:'0x2F30560d17044230eF4c6cEb9768D860e2cFf36E',
    decimal: 18,
    symbol: 'DB',
    name: 'DOUB Token',
    image: bnbimg,
    commonBase:false
  },
  {
    address: '0x0A1F4c4E14Dd83018942c5813768A8bf70b78599',
    decimal: 18,
    symbol: 'USDT',
    name: 'USDT Token',
    image: bnbimg ,
    commonBase :false
  },
  {
    address: '0x3Da81833d01C55462d21744E792e47813F24F7c6',
    decimails: 18,
    symbol: 'Garil',
    name: 'Garil Token',
    image: bnbimg,
    commonBase:true
  },
  {
    address:"0x440b481A1F65deA03CA80E370284D0aC457eA929",
    decimal: 18,
    symbol: 'testB',
    name: 'testB Token',
    image: bnbimg ,
    commonBase :false
  }
]

export const tokens = {
  56: '',
  97: testnetTokens
}
