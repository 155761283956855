import { CURRENT_CHAIN_ID, useWeb3 } from "./useWeb3"
import { CHAINS } from "../config/env";
import MASTERCHEF_ABI from '../config/abi/masterChef.json';
import Pair_ABI from '../config/abi/pairAbi.json'
import contractAddress from '../config/constant/contract'
import { getWalletAddress } from "../lib/localStorage";
import { EstGas } from "./useCommon";
//lib
import { toFixedNumber } from '../lib/FixedNumber';
import { approvelocal } from "../lib/localStorage";
export const getMasterChefAddress = () => {
    let CHAIN_ID = CURRENT_CHAIN_ID()
    let masterChefaddress = contractAddress.masterChef[`${CHAIN_ID}`]
    console.log(masterChefaddress,'masterChefaddress')
    return masterChefaddress;
}
export const UseMasterChef = async () => {
    const web3 = await useWeb3();
    const contract = new web3.eth.Contract(MASTERCHEF_ABI, getMasterChefAddress());
    console.log("contract", contract);
    return contract;
}


export const CheckIsApproved = async (Token, amount) => {
    const web3 = await useWeb3()
    try {
        console.log(Token, amount, "checkIsApproved")
        const account = getWalletAddress()
        const spenderAddress = getMasterChefAddress()
        const contract = new web3.eth.Contract(Pair_ABI,Token)
        const allow = await contract.methods?.allowance(account, spenderAddress).call();
        console.log(parseInt(allow) >= amount, parseInt(allow), amount, 'checkIsApproved')
        return (parseInt(allow) >= amount) ? true : false;
    } catch (err) {
        console.log("checkIsApproved", err)
    }
}



export const ApproveFarms = async (lpAddress,qureyString) => {
    const web3 = await useWeb3()
    try {
        const spenderAddress = getMasterChefAddress()
        let amount = "115792089237316195423570985008687907853269984665640564039457584007913129639935"
        const account = getWalletAddress()
        let contract = new web3.eth.Contract(Pair_ABI,lpAddress)
        let params = [web3.utils.toChecksumAddress(spenderAddress), amount]
        const { gasLimit, gasPrice } = await EstGas(params, MASTERCHEF_ABI, lpAddress, 'approve', account)
        let Approve = await contract.methods.approve(web3.utils.toChecksumAddress(spenderAddress), amount).send({ from: web3.utils.toChecksumAddress(account), gasLimit: gasLimit, gasPrice: gasPrice })
        console.log("approve", Approve)
        if (Approve) {
            let localdata ={
                qureyString:qureyString,
                txhash:Approve.transactionHash,
            }
            approvelocal(JSON.stringify(localdata))
            return {
                status: true,
                data: Approve
            }
        }
    } catch (err) {
        console.log("ApproveFarms__err", err, true)
        return false
    }
}



//unstake
export const UnStake = async (pid, amount) => {
    const web3 = await useWeb3()
    try {
        const spenderAddress = getMasterChefAddress()
        const account = getWalletAddress()
        let contract = await UseMasterChef()
        let params = [pid, amount.toString()]
        const { gasLimit, gasPrice } = await EstGas(params, MASTERCHEF_ABI, spenderAddress, 'withdraw', account)
        let result = await contract.methods.withdraw(pid, amount.toString()).send({ from: web3.utils.toChecksumAddress(account), gasLimit: gasLimit, gasPrice: gasPrice })
        console.log("result", result)
        if (result) {
            // approvelocal(Approve.transactionHash)
            return true
        }
    } catch (err) {
        console.log("Harvest__err", err, true)
        return false
    }
}

//Stake
export const Deposit = async (pid, amount) => {
    const web3 = await useWeb3()
    try {
        console.log("Deposit",pid, amount)
        const spenderAddress = getMasterChefAddress()
        const account = getWalletAddress()
        let contract = await UseMasterChef()
        let params = [pid, amount.toString()]
        const { gasLimit, gasPrice } = await EstGas(params, MASTERCHEF_ABI, spenderAddress, 'deposit', account)
        let result = await contract.methods.deposit(pid, amount.toString()).send({ from: web3.utils.toChecksumAddress(account), gasLimit: gasLimit, gasPrice: gasPrice })
        console.log("result", result)
        if (result) {
            // approvelocal(Approve.transactionHash)
            return true
        }
    } catch (err) {
        console.log("Deposit__err", err, true)
        return false
    }
}