// import constant
import {
    DEADLINE,
    SLIPPAGE,
    MULTI_HOPS,
    EXPERT_MODE
} from '../constants';

const initialValue = {
    slippage: '',
    deadline: ''
}

//  console.log("initialValue----->>>",initialValue);
const settings = (state = initialValue, action) => {

    switch (action.type) {
        case DEADLINE:
            return {
                ...state,
                deadline: action.payload
            };
        case SLIPPAGE:
            return {
                ...state,
                slippage: action.payload
            };
        case MULTI_HOPS:
            return {
                ...state,
                multihops: action.payload
            };
            case EXPERT_MODE:
                return {
                    ...state,
                    expertmode: action.payload
                };
        default:
            return state;
    }
}

export default settings;