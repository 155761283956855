import { useWeb3 } from "./useWeb3";

//lib
import { consolelog } from "../lib/consolelog";

export const EstGas = async (params, abi, contractAddress, methodName,account) => {
    try {
        const web3 = await useWeb3();
        const gasPrice = await web3.eth.getGasPrice();
        const Contract = new web3.eth.Contract(abi, contractAddress)
        const encoded = await Contract.methods[methodName].apply(null,params).encodeABI();
        let estimatedGasLimit = await web3.eth.estimateGas({
            from:web3.utils.toChecksumAddress(account),
            to: web3.utils.toChecksumAddress(contractAddress),
            data: encoded,
        });

        return{
            gasLimit:parseInt(estimatedGasLimit* 1.5,10),
            gasPrice:gasPrice
        }
    } catch (e) {
        consolelog('EstGas_err',e,true);
        return false
    }
}

