import { Interface } from '@ethersproject/abi'
import BigNumber from 'bignumber.js'
import { FARMS } from "../config/farms"
import { getMasterChefAddress, UseMasterChef } from './useMasterChef';
import { GetMultiCall } from './useMultiCall'
import { UseERC20, Balance } from './useErc20'
import { getNativeAddress } from './useNativeToken'
// import { MULTICALL_ABI } from '../config/abi/multical.json'
import ERC20_ABI from '../config/abi/ERC20.json';
import MASTERCHEF_ABI from '../config/abi/masterChef.json';
// import { UseWeb3, useWeb3 } from './useWeb3';
import { getWalletAddress } from "../lib/localStorage";
import { BLOCKS_PER_YEAR, BURN, INTERVAL, DAYS_TO_CALCULATE_AGAINST } from '../config/env';
import { isEmpty } from "../lib/isEmpty"
import { getTokens } from './useTokens';
import { UserDeposits } from './useStake'

const ZERO = new BigNumber(0)

const BIG_ZERO = new BigNumber(0)
const BIG_ONE = new BigNumber(1)
const BIG_TEN = new BigNumber(10)

export const getFarmstats = async () => {
    const farms = await getFarmsInfo();
    let value = new BigNumber(0);
    for (let i = 0; i < farms.length; i++) {
        value = value.plus(farms[i].earnings)
    }
    const balance = await Balance(getNativeAddress(), getWalletAddress());
    return {
        harvest: value,
        balance: balance
    }
}

export const totalValueLocked = async () => {
    const farms = await getFarmsInfo();
    const nativePrice = await getNativePriceUSD();
    const xdcPrice = await getXDCPriceUSD();
    console.log("Natve : ", nativePrice.toNumber(), "xdc : ", xdcPrice.toNumber(), farms)
    let value = new BigNumber(0);
    for (let i = 0; i < farms.length; i++) {
        const farm = farms[i]
        if (farm.lpTotalInQuoteToken) {
            let val;
            if (farm.quoteTokenSymbol === "XDC" || farm.quoteTokenSymbol === "WXDC") {
                val = (xdcPrice.times(farm.lpTotalInQuoteToken));
            } else if (farm.quoteTokenSymbol === "BKLZ") {
                val = (nativePrice.times(farm.lpTotalInQuoteToken));
            } else {
                val = (farm.lpTotalInQuoteToken);
            }
            value = value.plus(val);
        }
    }
    console.log("TVL : ", value.toNumber())
    return value;

}

export const getFarmsInfo = async () => {
   try{ console.log(FARMS, "FARMS")
    if ((localStorage.getItem("Farms")) && parseInt(localStorage.getItem("lastUpdated") || "0") + INTERVAL > Date.now()) {
        return JSON.parse(localStorage.getItem("Farms"));
    }
    const nativePrice = await getNativePriceUSD();
    const xdcPrice = await getXDCPriceUSD();
    console.log(FARMS, "FARMS")
    let farms = []
    for (let i = 0; i < FARMS.length; i++) {
        let farm = FARMS[i]
        console.log(farm, "farm")
        if (!isEmpty(farm)) {
            const farmData = await getFarmsTokensInfo(farm, nativePrice);
            let result = await UserDeposits(farmData?.pid)
            console.log("amount_farms", result?.amount / 10 ** 18)
            // let farm  =  data[i]
            farmData['StakedAmount'] = result?.amount / 10 ** 18
            // farms.push(farm)
            console.log(farmData, 'farmData')
            FARMS[i] = farmData;
        }
        if (i === FARMS.length - 1) {
            farms = await fetchFarmsPrices(FARMS)
            console.log(farms, "fetchFarmsPrices")
            if (Object.keys(farms[0]).length > 9)
                localStorage.setItem("Farms", JSON.stringify(farms));


            localStorage.setItem("lastUpdated", (Date.now()).toString())
            console.log(" localstorage setted total Farms : ", farms)
            return farms;
        }
    }


}catch(err){
    console.log(err,"getFarmsInfo__err")
}

}

export const getFarmsTokensInfo = async (farmConfig, nativePrice, xdcPrice) => {
    console.log("getFarmsTokensInfo", farmConfig)
    const lpAdress = farmConfig?.lpAddress;
    const calls = [
        // Balance of token in the LP contract
        {
            address: farmConfig?.tokenAddress,
            name: 'balanceOf',
            params: [lpAdress],
        },
        // Balance of quote token on LP contract
        {
            address: farmConfig?.quoteTokenAddress,
            name: 'balanceOf',
            params: [lpAdress],
        },
        // Balance of LP tokens in the master chef contract
        {
            address: farmConfig?.isTokenOnly ? farmConfig?.tokenAddress : lpAdress,
            name: 'balanceOf',
            params: [getMasterChefAddress()],
        },
        // Total supply of LP tokens
        {
            address: lpAdress,
            name: 'totalSupply',
        },
        // Token decimals
        {
            address: farmConfig?.tokenAddress,
            name: 'decimals',
        },
        // Quote token decimals
        {
            address: farmConfig?.quoteTokenAddress,
            name: 'decimals',
        },
        // Reward token decimals
        {
            address: farmConfig?.rewardToken,
            name: 'decimals',
        },
        // Reward token symbol
        {
            address: farmConfig?.rewardToken,
            name: 'symbol',
        },
    ]

    const [
        tokenBalanceLP,
        quoteTokenBlanceLP,
        lpTokenBalanceMC,
        lpTotalSupply,
        tokenDecimals,
        quoteTokenDecimals,
        rewardTokenDecimals,
        rewardTokenSymbol
    ] = await multicall(ERC20_ABI, calls)
console.log("mulyticallll")
    let tokenAmount;
    let lpTotalInQuoteToken;
    let tokenPriceVsQuote;

    if (farmConfig.isTokenOnly) {
        tokenAmount = new BigNumber(lpTokenBalanceMC).div(new BigNumber(10).pow(tokenDecimals));
        if (farmConfig.tokenSymbol === "BUSD" && farmConfig.quoteTokenSymbol === "BUSD") {
            tokenPriceVsQuote = new BigNumber(1);
        } else {
            tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP));
        }
        lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote);
    } else {
        // Ratio in % a LP tokens that are in staking, vs the total number in circulation
        const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

        // Total value in staking in quote token value
        lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
            .div(new BigNumber(10).pow(18))
            .times(new BigNumber(2))
            .times(lpTokenRatio)
        // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
        tokenAmount = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatio)
        const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP)
            .div(new BigNumber(10).pow(quoteTokenDecimals))
            .times(lpTokenRatio)

        if (tokenAmount.comparedTo(0) > 0) {
            tokenPriceVsQuote = quoteTokenAmount.div(tokenAmount);
        } else {
            tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP));
        }
    }

    const [info, totalAllocPoint, emissionPerBlock] = await multicall(MASTERCHEF_ABI, [
        {
            address: getMasterChefAddress(),
            name: 'poolInfo',
            params: [farmConfig.pid],
        },
        {
            address: getMasterChefAddress(),
            name: 'totalAllocPoint',
        },
        {
            address: getMasterChefAddress(),
            name: 'DONUTPerBlock',
        }

    ])
    console.log(info, 'info', info?.lastRewardBlock.toString(), emissionPerBlock)
    const eggPerBlock = emissionPerBlock.toString();
    console.log('eggPerBlock', eggPerBlock)
    //   const poolLimit = info.poolLimit.toString();
    //   let poolBalance = new BigNumber(lpTokenBalanceMC).div(new BigNumber(poolLimit));
    let poolBalance = new BigNumber(lpTokenBalanceMC)
    poolBalance = poolBalance.times(100);
    console.log("poolBalance", poolBalance)
    const earnings = getWalletAddress() ? await getUserFarmBalances(farmConfig, getWalletAddress()) : 0;
    const allocPoint = new BigNumber(info?.allocPoint._hex)
    const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))
    console.log("allocPoint", allocPoint, poolWeight)

    const cakeRewardPerBlock = new BigNumber(eggPerBlock || 1).times(new BigNumber(poolWeight)).div(new BigNumber(10).pow(18))
    const cakeRewardPerYear = cakeRewardPerBlock.times(BLOCKS_PER_YEAR)

    let apy = new BigNumber(nativePrice).times(cakeRewardPerYear);

    let totalValue = new BigNumber(lpTotalInQuoteToken || 0);
    console.log("totalValue",totalValue.toNumber(),lpTotalInQuoteToken.toNumber())

    const tokenAmountTotal = new BigNumber(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals))

    //   if (farmConfig.quoteTokenSymbol === "XDC") {
    //     totalValue = totalValue.times(xdcPrice);
    //   }
    console.log("totalValue1",totalValue > 0)

    // if (totalValue > 0) {
        console.log("totalValue2",apy.toNumber())
        apy = apy.div(totalValue);
        console.log("totalValue3",apy.toNumber())

    // }
    console.log("totalValue4",info,info.isFixed)

    if (info.isFixed) {
        console.log("totalValue5",info,info.isFixed)
        apy = UseRoundValue(eggPerBlock, 18);
    }
    console.log("totalValue6",apy.toNumber())
    return {
        ...farmConfig,
        tokenAmount: tokenAmount.toJSON(),
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        tokenPriceVsQuote: tokenPriceVsQuote.toJSON(),
        poolWeight: poolWeight?.toNumber(),
        multiplier: `${allocPoint.div(100).toString()}X`,
        depositFeeBP: info.depositFeeBP,
        eggPerBlock: new BigNumber(eggPerBlock).toNumber(),
        earnings: earnings,
        apy: apy,
        isFixed: info.isFixed,
        lpTotalSupply: new BigNumber(lpTotalSupply).toNumber(),
        tokenAmountTotal: (tokenAmountTotal).toNumber(),
        // intervalPeriod: info.intervalBlock.toString(),
        poolLimit: poolBalance.toFixed(2),
        rewardTokenDecimals: rewardTokenDecimals,
        rewardTokenSymbol: rewardTokenSymbol
    }


}

export const fetchFarmsPrices = async (farms) => {
    console.log("fetchFarmsPrices 3 farmsWithoutHelperLps : ", farms)
    const bnbBusdFarm = FARMS.find((farm) => farm.lpSymbol == 'BNB-BUSD LP' || farm.lpSymbol == 'BUSD-BNB LP')
    const bnbPriceBusd = bnbBusdFarm.tokenPriceVsQuote ? new BigNumber(1) / (bnbBusdFarm.tokenPriceVsQuote) : new BigNumber(0)
    console.log(bnbPriceBusd.toLocaleString(), "farmsWithoutHelperLps bnbPriceBusd");

    const farmsWithPrices = farms.map((farm) => {
        console.log(farm,farm.quoteTokenSymbo, 'fetchFarmsPrices_farm')
        const quoteTokenFarm = getFarmFromTokenSymbol(farms, farm.quoteTokenSymbol)
        const tokenPriceBusd = getFarmBaseTokenPrice(farm, quoteTokenFarm, bnbPriceBusd)
        const quoteTokenPriceBusd = getFarmQuoteTokenPrice(farm, quoteTokenFarm, bnbPriceBusd)
        console.log(farm,quoteTokenFarm, tokenPriceBusd, quoteTokenPriceBusd, 'quoteTokenPriceBusd')
        return {
            ...farm,
            tokenPriceBusd: new BigNumber(tokenPriceBusd).toNumber(),
            quoteTokenPriceBusd: new BigNumber(quoteTokenPriceBusd).toNumber(),
        }
    })
    console.log(farmsWithPrices, 'farmsWithPrices')
    return farmsWithPrices
}
const getFarmFromTokenSymbol = (
    farms,
    tokenSymbol,
    preferredQuoteTokens,
) => {
    console.log(farms,
        tokenSymbol,
        preferredQuoteTokens, 'getFarmFromTokenSymbol')
    const farmsWithTokenSymbol = farms.filter((farm) => farm.tokenSymbol === tokenSymbol)
    console.log(farmsWithTokenSymbol, "farmsWithTokenSymbol")
    const filteredFarm = filterFarmsByQuoteToken(farmsWithTokenSymbol, preferredQuoteTokens)
    console.log(filteredFarm, "filteredFarm")
    return filteredFarm
}

const filterFarmsByQuoteToken = (
    farms,
    preferredQuoteTokens = ['BUSD', 'WBNB'],
) => {
    const preferredFarm = farms.find((farm) => {
        return preferredQuoteTokens.some((quoteToken) => {
            return farm.quoteTokenSymbol === quoteToken
        })
    })
    return preferredFarm || farms[0]
}
const getFarmBaseTokenPrice = (
    farm,
    quoteTokenFarm,
    bnbPriceBusd,
) => {
    const hasTokenPriceVsQuote = Boolean(farm.tokenPriceVsQuote)

    let busd = getTokens().find((val) => (val.symbol == 'BUSD'))
    let wbnb = getTokens().find((val) => (val.symbol == 'WBNB'))
    if (farm.quoteTokenSymbol === busd.symbol) {
        return hasTokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : BIG_ZERO
    }

    if (farm.quoteTokenSymbol === wbnb.symbol) {
        return hasTokenPriceVsQuote ? new BigNumber(bnbPriceBusd).times(farm.tokenPriceVsQuote) : BIG_ZERO
    }

    // We can only calculate profits without a quoteTokenFarm for BUSD/ETH farms
    if (!quoteTokenFarm) {
        return BIG_ZERO
    }

    // Possible alternative farm quoteTokens:
    // UST (i.e. MIR-UST), pBTC (i.e. PNT-pBTC), BTCB (i.e. bBADGER-BTCB), ETH (i.e. SUSHI-ETH)
    // If the farm's quote token isn't BUSD or WBNB, we then use the quote token, of the original farm's quote token
    // i.e. for farm PNT - pBTC we use the pBTC farm's quote token - ETH, (pBTC - ETH)
    // from the ETH - pBTC price, we can calculate the PNT - BUSD price
    if (quoteTokenFarm.quoteTokenSymbol === wbnb.symbol) {
        const quoteTokenInBusd = new BigNumber(bnbPriceBusd).times(quoteTokenFarm.tokenPriceVsQuote)
        return hasTokenPriceVsQuote && quoteTokenInBusd
            ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInBusd)
            : BIG_ZERO
    }

    if (quoteTokenFarm.quoteTokenSymbol === busd.symbol) {
        const quoteTokenInBusd = quoteTokenFarm.tokenPriceVsQuote
        return hasTokenPriceVsQuote && quoteTokenInBusd
            ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInBusd)
            : BIG_ZERO
    }

    // Catch in case token does not have immediate or once-removed BUSD/WBNB quoteToken
    return BIG_ZERO
}

const getFarmQuoteTokenPrice = (
    farm,
    quoteTokenFarm,
    bnbPriceBusd,
) => {
    if (farm.quoteTokenSymbol === 'BUSD') {
        return BIG_ONE
    }

    if (farm.quoteTokenSymbol === 'WBNB') {
        return bnbPriceBusd
    }

    if (!quoteTokenFarm) {
        return BIG_ZERO
    }
    // if (quoteTokenFarm.quoteToken.symbol === 'WBNB') {
    //   return quoteTokenFarm.tokenPriceVsQuote ? bnbPriceBusd.times(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
    // }
    if (quoteTokenFarm.quoteTokenSymbol === 'BUSD') {
        return quoteTokenFarm.tokenPriceVsQuote ? new BigNumber(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
    }

    if (quoteTokenFarm.quoteTokenSymbol === 'DT') {
        return quoteTokenFarm.tokenPriceVsQuote ? new BigNumber(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
    }
    return BIG_ZERO
}
export const multicall = async (abi, calls) => {
try{
    const multi = await GetMultiCall();

    const itf = new Interface(abi)
    console.log(calls,'multicall')
    const calldata = calls?.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])
    const { returnData } = await multi.methods.aggregate(calldata).call()
    const res = returnData.map((call, i) => itf.decodeFunctionResult(calls[i].name, call))

    return res
}
catch(err){
    console.log("multicall_err",err)
}
}


export const getNativePriceUSD = async () => {
    const PID = 0; // NATIVE - STABLE USD
    console.log(FARMS[PID], 'getNativePriceUSD')
    const farm = await getFarmsTokensInfo(FARMS[PID]);
    return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
}


export const getXDCPriceUSD = async () => {
    const PID = 1; // XDC - STABLE USD
    const farm = await getFarmsTokensInfo(FARMS[PID]);
    return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
}

export const getUserFarmBalances = async (farmConfig, account) => {
    const master = await UseMasterChef();
    const pending = await master?.methods?.pendingDONUT(farmConfig?.pid, account).call();
    return pending;
}

export const UseTotalSupply = async (VALUE) => {
    const erc20 = await UseERC20(VALUE);
    const total = await erc20.methods.totalSupply().call();
    const decimals = await erc20.methods.decimals().call();
    return UseRoundValue(total, decimals);

}

export const UseBurnedBalance = async () => {
    const value = await Balance(getNativeAddress(), BURN);
    return UseRoundValue(value, 18);
}

export const UseRoundValue = (VALUE, DECIMALS) => {
    return VALUE > 0 ? new BigNumber(VALUE).div(new BigNumber(10).pow(DECIMALS)) : new BigNumber(0);

}

export const getPerInterval = (VALUE) => {
    if (parseInt(VALUE / 31560000) > 0) {
        return "YEAR";
    } else if (parseInt(VALUE / 2628000) > 0) {
        return "MONTH";
    } else if (parseInt(VALUE / 86400) > 0) {
        return "DAY";
    } else if (parseInt(VALUE / 3600) > 0) {
        return "HOUR";
    } else if (parseInt(VALUE / 60) > 0) {
        return "MINUTE";
    } else {
        return "SECONDS";
    }
}

export const UseLpTokenPrice = (symbol) => {
    try {
        const farm = UseFarmFromLpSymbol(symbol)
        console.log("UseLpTokenPrice", farm)
        const farmTokenPriceInUsd = UseBusdPriceFromPid(farm?.pid)
        console.log("farmTokenPriceInUsd", farmTokenPriceInUsd)
        let lpTokenPrice = BIG_ZERO

        if (farm.lpTotalSupply > 0 && farm.lpTotalInQuoteToken > 0) {
            // Total value of base token in LP
            const valueOfBaseTokenInFarm = new BigNumber(farmTokenPriceInUsd).times(farm.tokenAmountTotal)
            console.log(valueOfBaseTokenInFarm, 'valueOfBaseTokenInFarm')
            // Double it to get overall value in LP
            const overallValueOfAllTokensInFarm = new BigNumber(valueOfBaseTokenInFarm).times(2)
            console.log(overallValueOfAllTokensInFarm, 'overallValueOfAllTokensInFarm')
            // Divide total value of all tokens, by the number of LP tokens
            const totalLpTokens = farm.lpTotalSupply / 10 ** 18
            lpTokenPrice = overallValueOfAllTokensInFarm / (totalLpTokens)
            console.log(totalLpTokens, lpTokenPrice, 'lpTokenPrice')
        }
        return lpTokenPrice
    }
    catch (err) {
        console.log("UseLpTokenPrice", err)
    }
}

export const UseFarmFromLpSymbol = (lpSymbol) => {
    try {
        var farmData = localStorage.getItem("Farms")
        farmData = JSON.parse(farmData)
        let farm = farmData?.find((val) => (val?.lpSymbol === lpSymbol))
        console.log(farm, "useFarmFromLpSymbol", lpSymbol)
        return farm
    } catch (err) {
        console.log("useFarmFromLpSymbol", err)
    }
}


export const UseBusdPriceFromPid = (pid) => {
    try {
        const farm = UseFarmFromPid(pid)
        return farm && new BigNumber(farm.tokenPriceBusd)
    } catch (err) {
        console.log("useBusdPriceFromPid", err)
    }
}

export const UseFarmFromPid = (pid) => {
    try {
        var farmData = localStorage.getItem("Farms")
        farmData = JSON.parse(farmData)
        let farm = farmData?.find((val) => (val?.pid == pid))
        return farm
    } catch (err) {
        console.log("useFarmFromPid", err)
    }
}

export const getApy = (apr, compoundFrequency = 1, days = 365, performanceFee = 0) => {
    console.log("getApy", apr, compoundFrequency = 1, days = 365, performanceFee = 0)
    const daysAsDecimalOfYear = days / 365
    const aprAsDecimal = apr / 100
    const timesCompounded = 365 * compoundFrequency
    let apyAsDecimal = (apr / 100) * daysAsDecimalOfYear
    console.log("getApy1", daysAsDecimalOfYear, aprAsDecimal, timesCompounded, performanceFee)
    if (timesCompounded > 0) {
        apyAsDecimal = (1 + aprAsDecimal / timesCompounded) ** (timesCompounded * daysAsDecimalOfYear) - 1
    }
    if (performanceFee) {
        const performanceFeeAsDecimal = performanceFee / 100
        const takenAsPerformanceFee = apyAsDecimal * performanceFeeAsDecimal
        apyAsDecimal -= takenAsPerformanceFee
        console.log("getApy2", performanceFeeAsDecimal, takenAsPerformanceFee, apyAsDecimal)

    }
    return apyAsDecimal
}

export const getTokenPricesFromFarm = () => {
    let farms = localStorage.getItem("Farms")
    farms = JSON.parse(farms)
    return farms?.reduce((prices, farm) => {
        const quoteTokenAddress = farm.quoteTokenAddress.toLocaleLowerCase()
        const tokenAddress = farm.tokenAddress.toLocaleLowerCase()
        console.log("quoteTokenAddress", quoteTokenAddress, tokenAddress)
        if (!prices[quoteTokenAddress]) {
            prices[quoteTokenAddress] = new BigNumber(farm.quoteTokenPriceBusd).toNumber()
        }
        if (!prices[tokenAddress]) {
            prices[tokenAddress] = new BigNumber(farm.tokenPriceBusd).toNumber()
        }
        console.log("prices", prices)
        return prices
    }, {})
}

export const getInterestBreakdown = (
   { principalInUSD,
    apr,
    earningTokenPrice,
    compoundFrequency = 1,
    performanceFee = 0}) => {

    console.log("getInterestBreakdown", principalInUSD,apr,earningTokenPrice,compoundFrequency, performanceFee)
    // Everything here is worked out relative to a year, with the asset compounding at the compoundFrequency rate. 1 = once per day
    const timesCompounded = 365 * compoundFrequency
    console.log("timesCompounded",timesCompounded)
    // We use decimal values rather than % in the math for both APY and the number of days being calculates as a proportion of the year
    const aprAsDecimal = apr / 100

    // special handling for tokens like tBTC or BIFI where the daily token rewards for $1000 dollars will be less than 0.001 of that token
    // and also cause rounding errors
    const isHighValueToken = Math.round(earningTokenPrice / 1000) > 0
    const roundingDecimalsNew = isHighValueToken ? 5 : 3

    return DAYS_TO_CALCULATE_AGAINST.map((days) => {
        const daysAsDecimalOfYear = days / 365
        // Calculate the starting TOKEN balance with a dollar balance of principalInUSD.
        const principal = principalInUSD / earningTokenPrice
        let interestEarned = principal * aprAsDecimal * (days / 365)
        if (timesCompounded !== 0) {
            // This is a translation of the typical mathematical compounding APY formula. Details here: https://www.calculatorsoup.com/calculators/financial/compound-interest-calculator.php
            const accruedAmount = principal * (1 + aprAsDecimal / timesCompounded) ** (timesCompounded * daysAsDecimalOfYear)
            // To get the TOKEN amount earned, deduct the amount after compounding (accruedAmount) from the starting TOKEN balance (principal)
            interestEarned = accruedAmount - principal
            if (performanceFee) {
                const performanceFeeAsDecimal = performanceFee / 100
                const performanceFeeAsAmount = interestEarned * performanceFeeAsDecimal
                interestEarned -= performanceFeeAsAmount
            }
        }
        return parseFloat(interestEarned.toFixed(roundingDecimalsNew))
    })
}


export const getPrincipalForInterest = (
    interest,
    apr,
    compoundingFrequency,
    performanceFee = 0,
) => {
    console.log('inside', interest,
    apr,
    compoundingFrequency,
    performanceFee)

    return DAYS_TO_CALCULATE_AGAINST.map((days) => {
        const apyAsDecimal = getApy(apr, compoundingFrequency, days, performanceFee)
        console.log('inside', interest, apyAsDecimal)
        // const apyAsBN = new BigNumber(apyAsDecimal).decimalPlaces(6, BigNumber.ROUND_DOWN).toNumber()
        return parseFloat((interest / apyAsDecimal).toFixed(2))
    })
}


export const getRoi = ({ amountEarned, amountInvested }) => {
    if (amountInvested === 0) {
      return 0
    }
    const percentage = (amountEarned / amountInvested) * 100
    return percentage
  }
  