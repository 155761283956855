import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IoMdSettings } from "react-icons/io";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { MdHistory } from "react-icons/md";
import cascading from "../../assests/css/liquidity.module.css";
import iconimg1 from "../../assests/images/coinicon.png";

import Footer from "../../common/footer";
import { FaArrowDown } from "react-icons/fa";
import Header from "../../common/header";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FaAngleDown, FaPlus, FaArrowLeft } from "react-icons/fa";
import { IoIosHelpCircleOutline } from "react-icons/io";
import bnbimg from "../../assests/images/bnb.png";
import Walletmodal from "../../modals/walletmodal";
import { useSelector } from "react-redux";
import Recenthistory from "../../modals/recenthistory";
import Selecttoken from "../../modals/selecttoken";
import { Tooltip } from "react-bootstrap";
import Settingsmodal from "../../modals/settingsmodal";
import ConfirmRemovemodal from "../../modals/confirmRemovemodal";
import CompletedRemovemodal from "../../modals/completedRemovemodal";
import WaitingRemovemodal from "../../modals/waitingRemovemodal";

import coinimg from "../../assests/images/infoicon.png";
import { ZEROTH_ADDRESS } from "../../config/env";

//lib
import { isEmpty } from "../../lib/isEmpty";
import fileObjectUrl from "../../lib/img";
import { toFixedNumber } from "../../lib/FixedNumber";
//hooks
import { GetPairInfo } from "../../hooks/useFactory";
import { GetAmountsOut, GetAmountsIn } from "../../hooks/useRouter";
import { ApproveToken, checkIsApproved } from "../../hooks/useErc20";
import {
  Removeliquidity,
  RemoveLiquidityETH,
  getRouterAddress,
} from "../../hooks/useRouter";
import { getTokens } from "../../hooks/useTokens";
import { getWalletAddress } from "../../lib/localStorage";

export default function RemoveLiquidity() {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("state", state);
  const [swapsec, setSwapsec] = useState(true);
  const [walletmodalshow, setWalletmodalshow] = useState(false);
  const [recentshow, setRecentshow] = useState(false);
  const [tokenshow, setTokenshow] = useState(false);
  const [tokenshow2, setTokenshow2] = useState(false);
  const [settingshow, setSettingshow] = useState(false);
  const [commontoken, setCommontoken] = useState(false);
  const [removetab, setRemovetab] = useState(false);
  const [fromToken, setFromToken] = useState("");
  const [toToken, setToToken] = useState("");
  const [token, setToken] = useState("");
  const [lpValue, setLpValue] = useState("");
  const [out1, setOut1] = useState("");
  const [out2, setOut2] = useState("");
  const [pairInfo, setPairInfo] = useState("");
  const [lpApprove, setLpApprove] = useState(false);
  const [points, setPoints] = useState(0);
  const [changeBNB, setChangeBNB] = useState("");
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [toTokenPer, setToTokenPer] = useState("");
  const [fromTokenPer, setFromTokenPer] = useState("");
  const [succesSupply, setSuccesSupply] = useState(false);
  const [waitingRemove, setWaitingRemove] = useState(false);

  const userdetail = useSelector((state) => state.admin);
  const { slippage, deadline } = useSelector((state) => state.settings);
  console.log("slippage", slippage);

  useEffect(() => {
    if (state) {
      console.log("statte", state.token);
      if (!isEmpty(state?.token?.token1)) {
        setFromToken(state?.token?.token1);
        if (state?.token?.token1.symbol == "WBNB") {
          setChangeBNB("BNB");
        } else if (state?.token?.token1.symbol == "BNB") {
          setChangeBNB("WBNB");
        }
      }
      if (!isEmpty(state?.token?.token2)) {
        setToToken(state?.token?.token2);
        if (state?.token?.token2.symbol == "WBNB") {
          setChangeBNB("BNB");
        } else if (state?.token?.token2.symbol == "BNB") {
          setChangeBNB("WBNB");
        }
      }
      if (!isEmpty(state?.token)) {
        setToken(state?.token);
        let amount0 =
          (state.token?.lpBalance * state.token?.reserveA) /
          state.token?.totalSupply;
        let amount1 =
          (state.token?.lpBalance * state.token?.reserveB) /
          state.token?.totalSupply;
        console.log("amount0_amount0", state?.token, amount0, amount1);
        setFromTokenPer(amount0 / amount1);
        setToTokenPer(amount1 / amount0);
      }
    }
  }, [state]);

  const onFromTokenChange = async (value) => {
    if (value.address == toToken?.address) {
      setToToken(fromToken);
    }
    setFromToken(value);
  };

  const onToTokenChange = async (value) => {
    console.log(value, "value");
    if (value.address == fromToken?.address) {
      setFromToken(toToken);
    }
    setToToken(value);
  };
  console.log(toToken, "toToken");

  const lpMax = () => {
    setLpValue(token?.lpBalance);
    let amount0 = (token?.lpBalance * token?.reserveA) / token?.totalSupply;
    let amount1 = (token?.lpBalance * token?.reserveB) / token?.totalSupply;
    console.log("amount1", amount0, amount1);
    setOut1(amount0);
    setOut2(amount1);
  };

  const GetAmounts = async (out1) => {
    let addressArr = [token?.token1?.address, token?.token2?.address];
    let amountsIn = toFixedNumber(out1 * 10 ** 18);
    var amount = await GetAmountsOut(addressArr, amountsIn);
    setOut2(amount?.amountOut);
    console.log(
      "amount",
      amount,
      amount?.amountOut,
      toFixedNumber(
        Math.min(
          (out1 * token?.totalSupply) / token?.reserveA,
          (amount?.amountOut * token?.totalSupply) / token?.reserveB
        )
      )
    );
    setLpValue(
      toFixedNumber(
        Math.min(
          (out1 * token?.totalSupply) / token?.reserveA,
          (amount?.amountOut * token?.totalSupply) / token?.reserveB
        )
      )
    );
  };

  const GetAmountsin = async (out2) => {
    let addressArr = [token?.token1?.address, token?.token2?.address];
    let amountsIn = toFixedNumber(out2 * 10 ** 18);
    var amount = await GetAmountsIn(addressArr, amountsIn);
    setOut1(amount?.amountIn);
    console.log(
      "amount",
      amount,
      amount?.amountIn,
      toFixedNumber(
        Math.min(
          (amount?.amountIn * token?.totalSupply) / token?.reserveA,
          (amount?.amountOut * token?.totalSupply) / token?.reserveB
        )
      )
    );
    setLpValue(
      toFixedNumber(
        Math.min(
          (amount?.amountIn * token?.totalSupply) / token?.reserveA,
          (out2 * token?.totalSupply) / token?.reserveB
        )
      )
    );
  };

  // useEffect(() => {

  // }, [out1])

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name == "lpValue") {
      var numbers = /^\d+((.)|(.\d{0,30})?)$/;
      if (!numbers.test(e.target.value) && e.target.value !== "") {
        return false;
      }
      let amount0 = (value * token?.reserveA) / token?.totalSupply;
      let amount1 = (value * token?.reserveB) / token?.totalSupply;
      setLpValue(value);
      setOut1(toFixedNumber(amount0));
      setOut2(toFixedNumber(amount1));
    }
    if (name == "out1") {
      var numbers = /^\d+((.)|(.\d{0,30})?)$/;
      if (!numbers.test(e.target.value) && e.target.value !== "") {
        return false;
      }
      GetAmounts(value);
      setOut1(value);
    }
    if (name == "out2") {
      var numbers = /^\d+((.)|(.\d{0,30})?)$/;
      if (!numbers.test(e.target.value) && e.target.value !== "") {
        return false;
      }
      GetAmountsin(value);
      setOut2(value);
    }
  };

  useEffect(() => {
    getpair();
  }, [fromToken, toToken]);

  const getpair = async () => {
    var get = await GetPairInfo(fromToken?.address, toToken?.address);
    console.log("get", get);
    setPairInfo(get);
  };

  const Approve = async (address, value) => {
    var result = await checkIsApproved(address, value);
    setLpApprove(result);
    console.log("setLpApprove", result);
  };

  useEffect(() => {
    Approve(token?.lpaddress, token?.lpBalance);
  }, [token?.lpaddress, token?.lpBalance]);

  const supplyData = async () => {
    setConfirmRemove(false);
    setWaitingRemove(true);
    if (token?.token1?.name == "BNB" || token?.token2?.name == "BNB") {
      let tokens =
        token?.token1?.name == "BNB"
          ? token?.token2?.address
          : token?.token1?.address;

      console.log("slippage", slippage);
      let slippageval = parseFloat(slippage) / 100;
      console.log("slippageval", slippageval);

      let amountTokenDesired =
        token?.token1?.name == "BNB"
          ? toFixedNumber(out2)
          : toFixedNumber(out1);
      var amountTokenMin = parseFloat(amountTokenDesired) * (1 - slippageval);
      amountTokenMin =
        token?.token1?.name == "BNB"
          ? amountTokenMin * 10 ** token?.token2?.decimal
          : amountTokenMin * 10 ** token?.token1?.decimal;
      amountTokenMin = toFixedNumber(parseFloat(amountTokenMin).toFixed(0));
      console.log("amountTokenMin", amountTokenMin);

      let amountETHDesired =
        token?.token1?.name == "BNB"
          ? toFixedNumber(out1)
          : toFixedNumber(out2);
      var amountETHMin = parseFloat(amountETHDesired) * (1 - slippageval);
      amountETHMin = amountETHMin * 10 ** 18;
      amountETHMin = toFixedNumber(parseFloat(amountETHMin).toFixed(0));
      console.log("amountETHMin", amountETHMin);

      let deadLine = (
        new Date().getTime() / 1000 +
        parseFloat(deadline)
      ).toFixed(0);
      let liquidity = toFixedNumber(lpValue * 10 ** 18).toFixed(0);
      let qureyString = `Remove ${out1} ${token.token1.symbol} and ${out2} ${token?.token2?.symbol}`;
      let result = await RemoveLiquidityETH(
        tokens,
        liquidity,
        amountTokenMin,
        amountETHMin,
        deadLine,
        qureyString
      );
      setWaitingRemove(false);
      if (result) {
        setSuccesSupply(true);
      }
      console.log("AddLiquidityETH", result);
    } else {
      let deadLine = (
        new Date().getTime() / 1000 +
        parseFloat(deadline)
      ).toFixed(0);
      let liquidity = toFixedNumber(lpValue * 10 ** 18).toFixed(0);
      let amountA = toFixedNumber(out1);
      let amountB = toFixedNumber(out2);

      let slippageval = parseFloat(slippage) / 100;
      console.log(amountA, amountB, slippageval, "slippage_slip");
      var TokenAMin = parseFloat(amountA) * (1 - slippageval);
      TokenAMin = TokenAMin * 10 ** token?.token1?.decimal;
      TokenAMin = toFixedNumber(parseFloat(TokenAMin).toFixed(0));
      console.log("TokenAMin", TokenAMin);

      var TokenBMin = parseFloat(amountB) * (1 - slippageval);
      TokenBMin = TokenBMin * 10 ** token?.token2?.decimal;
      TokenBMin = toFixedNumber(parseFloat(TokenBMin).toFixed(0));
      console.log("TokenBMin", TokenBMin);

      let qureyString = `Remove ${out1} ${token.token1.symbol} and ${out2} ${token?.token2?.symbol}`;
      let result = await Removeliquidity(
        token?.token1?.address,
        token?.token2?.address,
        liquidity,
        TokenAMin,
        TokenBMin,
        deadLine,
        qureyString
      );
      setWaitingRemove(false);
      if (result) {
        setSuccesSupply(true);
      }
      console.log("RemoveLiquidity", result);
    }
  };

  const rangeOnchange = async (value) => {
    // const { name, value } = e.target;
    setPoints(value);
    let lpbalance = (token?.lpBalance * value) / 100;
    setLpValue(lpbalance);
    let amount0 = (lpbalance * token?.reserveA) / token?.totalSupply;
    let amount1 = (lpbalance * token?.reserveB) / token?.totalSupply;
    console.log("amount1", amount0, amount1, token?.reserveA, token?.reserveB);
    setOut1(amount0);
    setOut2(amount1);
  };

  const changeToken = async () => {
    if (fromToken?.symbol == "BNB" || toToken?.symbol == "BNB") {
      let Totoken =
        fromToken?.symbol == "BNB"
          ? true
          : toToken?.symbol == "BNB"
          ? false
          : undefined;
      setChangeBNB("BNB");
      if (Totoken == false) {
        let tokens = getTokens().find((val) => val.symbol == "WBNB");
        console.log("setToToken", tokens);
        setToToken(tokens);
      } else if (Totoken == true) {
        let tokens = getTokens().find((val) => val.symbol == "WBNB");
        console.log("setFromToken", tokens);
        setFromToken(tokens);
      }
    } else if (fromToken?.symbol == "WBNB" || toToken?.symbol == "WBNB") {
      let Totoken =
        fromToken?.symbol == "WBNB"
          ? true
          : toToken?.symbol == "WBNB"
          ? false
          : undefined;
      setChangeBNB("WBNB");
      if (Totoken == false) {
        let tokens = getTokens().find((val) => val.symbol == "BNB");
        console.log("setToToken", tokens);
        setToToken(tokens);
      } else if (Totoken == true) {
        let tokens = getTokens().find((val) => val.symbol == "BNB");
        console.log("setFromToken", tokens);
        setFromToken(tokens);
      }
    }
  };

  var obj = {
    fromToken: fromToken,
    toToken: toToken,
    out1: out1,
    out2: out2,
    lpValue: lpValue,
    fromTokenPer: fromTokenPer,
    toTokenPer: toTokenPer,
    // toTokenper: toTokenper,
    // fromTokenper: fromTokenper,
    // lpBalance: lpBalance,
    // poolShare:(poolShare).toFixed(2)
  };

  return (
    <>
      <Header />
      <div
        className={`${cascading.innerpages} ${cascading.liquiditypage}  ${cascading.removepage}`}
      >
        <div className={`container`}>
          <div
            className={`row justify-content-center ${cascading.swapcardrow}`}
          >
            <div className={`col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5`}>
              <div className={` ${cascading.cardstyle}`}>
                <div className={`${cascading.toprow}`}>
                  <div className={`${cascading.backsec}`}>
                    <div className={`${cascading.backbtn}`}>
                      <Link to="/liquidity">
                        {" "}
                        <FaArrowLeft fill="#fff" fontSize={18} />
                      </Link>
                    </div>
                    <div>
                      <p className={`${cascading.headings}`}>
                        Remove {`${fromToken?.symbol}-${toToken?.symbol}`}{" "}
                        liquidity
                      </p>
                      <p className={`${cascading.desc}`}>
                        To receive{" "}
                        {`${fromToken?.symbol} and ${toToken?.symbol}`}{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={`${cascading.tabrow}`}>
                  <p className={`${cascading.labelname}`}>Amount</p>
                  <button
                    className={`btn ${cascading.tabbtn}`}
                    onClick={() => setRemovetab(!removetab)}
                  >
                    {removetab ? "Simple" : "Detailed"}
                  </button>
                </div>
                {removetab ? (
                  <>
                    <div className={`${cascading.inputsec} mb-4`}>
                      <div className={`${cascading.balancerow}`}>
                        <p className={`${cascading.inputlabel}`}>Input</p>
                        {userdetail?.walletStatus == "connect" &&
                          !isEmpty(getWalletAddress()) && (
                            <p className={`${cascading.inputlabel}`}>
                              Balance :{token?.lpBalance}
                            </p>
                          )}
                      </div>

                      <div className={`${cascading.inputsecrow}`}>
                        <div>
                          <input
                            type="text"
                            className={`from-control`}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="0.0"
                            name="lpValue"
                            value={lpValue}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        </div>

                        <div
                          className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}
                        >
                          {userdetail?.walletStatus == "connect" &&
                            !isEmpty(getWalletAddress()) && (
                              <button
                                className={`btn ${cascading.currencybtn}`}
                                onClick={() => {
                                  lpMax();
                                }}
                              >
                                {" "}
                                Max{" "}
                              </button>
                            )}
                          <button
                            className={`btn ${cascading.currencybtn}`}
                            onClick={() => {
                              setCommontoken(true);
                            }}
                          >
                            {!isEmpty(fromToken) &&
                            !isEmpty(fromToken) &&
                            pairInfo !== ZEROTH_ADDRESS ? (
                              <>
                                <img
                                  src={fileObjectUrl(fromToken?.image)}
                                  alt="bnb"
                                  className={`img-fluid ${cascading.coinimg}`}
                                />
                                <img
                                  src={fileObjectUrl(toToken?.image)}
                                  alt="bnb"
                                  className={`img-fluid ${cascading.coinimg}`}
                                />
                              </>
                            ) : (
                              ""
                            )}
                            {pairInfo === ZEROTH_ADDRESS
                              ? "Select a currency"
                              : `${fromToken?.symbol}-${toToken?.symbol}`}{" "}
                            <FaAngleDown fill="#fff" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className={`row ${cascading.arrowrow}`}>
                      <div className={`${cascading.swaparrow}`}>
                        <FaArrowDown />
                      </div>
                    </div>
                    <div className={`${cascading.inputsec} mb-4`}>
                      <div className={`${cascading.balancerow}`}>
                        <p className={`${cascading.inputlabel}`}>Output</p>
                        {/* {(userdetail.walletStatus == 'connect') && <p className={`${cascading.inputlabel}`}>Balance : {fromToken?.balance}</p>} */}
                      </div>

                      <div className={`${cascading.inputsecrow}`}>
                        <div>
                          <input
                            type="email"
                            className={`from-control`}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="0.0"
                            name="out1"
                            value={out1}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        </div>

                        <div
                          className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}
                        >
                          {userdetail?.walletStatus == "connect" &&
                            !isEmpty(getWalletAddress()) && (
                              <button
                                className={`btn ${cascading.currencybtn}`}
                                onClick={() => {
                                  lpMax();
                                }}
                              >
                                {" "}
                                Max{" "}
                              </button>
                            )}
                          <button
                            className={`btn ${cascading.currencybtn}`}
                            onClick={() => {
                              setTokenshow(true);
                              setCommontoken(true);
                            }}
                          >
                            {!isEmpty(fromToken) ? (
                              <img
                                src={fileObjectUrl(fromToken?.image)}
                                alt="bnb"
                                className={`img-fluid ${cascading.coinimg}`}
                              />
                            ) : (
                              ""
                            )}
                            {isEmpty(fromToken)
                              ? "Select a currency"
                              : fromToken && fromToken?.symbol}{" "}
                            <FaAngleDown fill="#fff" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className={`row ${cascading.arrowrow}`}>
                      <div className={`${cascading.swaparrow}`}>
                        <FaPlus />
                      </div>
                    </div>
                    <div className={`${cascading.inputsec} mb-4`}>
                      <div className={`${cascading.balancerow}`}>
                        <p className={`${cascading.inputlabel}`}>Output</p>
                        {/* {(userdetail.walletStatus == 'connect') && <p className={`${cascading.inputlabel}`}>Balance : {toToken?.balance}</p>} */}
                      </div>

                      <div className={`${cascading.inputsecrow}`}>
                        <div>
                          <input
                            type="email"
                            className={`from-control`}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="0.0"
                            name="out2"
                            value={out2}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        </div>

                        <div
                          className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}
                        >
                          {userdetail?.walletStatus == "connect" &&
                            !isEmpty(getWalletAddress()) && (
                              <button
                                className={`btn ${cascading.currencybtn}`}
                                onClick={() => {
                                  lpMax();
                                }}
                              >
                                {" "}
                                Max{" "}
                              </button>
                            )}
                          <button
                            className={`btn ${cascading.currencybtn}`}
                            onClick={() => {
                              setTokenshow2(true);
                              setCommontoken(true);
                            }}
                          >
                            {!isEmpty(toToken) ? (
                              <img
                                src={fileObjectUrl(toToken?.image)}
                                alt="bnb"
                                className={`img-fluid ${cascading.coinimg}`}
                              />
                            ) : (
                              ""
                            )}
                            {isEmpty(toToken)
                              ? "Select a currency"
                              : toToken && toToken?.symbol}{" "}
                            <FaAngleDown fill="#fff" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {token?.lpBalance > 0 && pairInfo !== ZEROTH_ADDRESS ? (
                      <div className={`${cascading.pricesec}`}>
                        <p className="text-white">Prices</p>
                        <div className={`${cascading.pricecard}`}>
                          <ul>
                            <li>
                              <p>1 {fromToken?.symbol} =</p>
                              <p>
                                {toTokenPer} {toToken?.symbol}
                              </p>
                            </li>
                            <li>
                              <p>1 {toToken?.symbol} =</p>
                              <p>
                                {fromTokenPer} {fromToken?.symbol}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    <div className={`${cascading.rangesec}`}>
                      <p className={`${cascading.rangeshow}`}>{points} %</p>

                      <input
                        type="range"
                        className={`form-range ${cascading.rangeinput}`}
                        id="customRange"
                        name="points"
                        min="0"
                        max="100"
                        value={points}
                        onChange={(e) => {
                          rangeOnchange(e.target.value);
                        }}
                      />
                      <div className={`${cascading.btngrpsec}`}>
                        <button
                          className={`btn ${cascading.btnstyle}`}
                          onClick={() => {
                            rangeOnchange(25);
                          }}
                        >
                          25%
                        </button>
                        <button
                          className={`btn ${cascading.btnstyle}`}
                          onClick={() => {
                            rangeOnchange(50);
                          }}
                        >
                          50%
                        </button>
                        <button
                          className={`btn ${cascading.btnstyle}`}
                          onClick={() => {
                            rangeOnchange(75);
                          }}
                        >
                          75%
                        </button>
                        <button
                          className={`btn ${cascading.btnstyle}`}
                          onClick={() => {
                            rangeOnchange(100);
                          }}
                        >
                          Max
                        </button>
                      </div>
                    </div>
                    <div className={`row ${cascading.arrowrow}`}>
                      <div className={`${cascading.swaparrow}`}>
                        <FaArrowDown />
                      </div>
                    </div>

                    <div className={`${cascading.pricesec}`}>
                      <p className={`${cascading.pricename}`}>
                        You will Receive
                      </p>
                      <div className={`${cascading.pricecard}`}>
                        <ul>
                          <li>
                            <p>
                              <img
                                src={fileObjectUrl(fromToken?.image)}
                                className={`img-fluid ${cascading.coinimg}`}
                              />{" "}
                              {fromToken?.symbol}{" "}
                            </p>
                            <p>{isEmpty(out1) ? "-" : toFixedNumber(out1)}</p>
                          </li>
                          <li>
                            <p>
                              <img
                                src={fileObjectUrl(toToken?.image)}
                                className={`img-fluid ${cascading.coinimg}`}
                              />{" "}
                              {toToken?.symbol}
                            </p>
                            <p>{isEmpty(out2) ? "-" : toFixedNumber(out2)}</p>
                          </li>
                          <li>
                            <p></p>
                            {!isEmpty(changeBNB) ? (
                              <p
                                onClick={() => {
                                  changeToken();
                                }}
                              >
                                Receive {changeBNB}
                              </p>
                            ) : (
                              ""
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>

                    {token?.lpBalance > 0 && pairInfo !== ZEROTH_ADDRESS ? (
                      <div className={`${cascading.pricesec}`}>
                        <p className={`text-white ${cascading.pricename}`}>
                          Prices
                        </p>
                        <div className={`${cascading.pricecard}`}>
                          <ul>
                            <li>
                              <p>1 {fromToken?.symbol} =</p>
                              <p>
                                {toTokenPer} {toToken?.symbol}
                              </p>
                            </li>
                            <li>
                              <p>1 {toToken?.symbol} =</p>
                              <p>
                                {fromTokenPer} {fromToken?.symbol}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}

                <div className={`${cascading.btnsec}`}>
                  <button
                    className={`btn ${cascading.connect_btn}`}
                    onClick={async () => {
                      let data = await ApproveToken(
                        token?.lpaddress,
                        getRouterAddress()
                      );
                      setLpApprove(data?.status);
                    }}
                    disabled={lpApprove}
                  >
                    Enable
                  </button>

                  <button
                    className={`btn ${cascading.connect_btn}`}
                    onClick={() => {
                      setConfirmRemove(true);
                    }}
                    disabled={lpApprove == false || lpValue == 0}
                  >
                    Remove
                  </button>
                </div>
              </div>
              {token?.lpBalance > 0 && pairInfo !== ZEROTH_ADDRESS ? (
                <div className={`${cascading.balancecardsec} mt-4`}>
                  <p className={`${cascading.head}`}>
                    LP tokens in your wallet
                  </p>
                  <ul>
                    <li>
                      <p>
                        <img
                          src={fileObjectUrl(fromToken?.image)}
                          className={`img-fluid ${cascading.coinimg}`}
                        />{" "}
                        <img
                          src={fileObjectUrl(toToken?.image)}
                          className={`img-fluid ${cascading.coinimg}`}
                        />{" "}
                        {`${fromToken?.symbol}-${toToken?.symbol}`} LP:
                      </p>
                      <p>{token?.lpBalance}</p>
                    </li>
                    <li>
                      <p>Share of Pool:</p>
                      <p>
                        {!isEmpty(token?.poolShare) && !isNaN(token?.poolShare)
                          ? parseFloat(token?.poolShare)?.toFixed(2)
                          : "-"}
                        %
                      </p>
                    </li>
                    <li>
                      <p>Pooled {fromToken?.symbol}:</p>
                      <p>{toFixedNumber(token?.reserveA)}</p>
                    </li>
                    <li>
                      <p>Pooled {toToken?.symbol}:</p>
                      <p>{toFixedNumber(token?.reserveB)}</p>
                    </li>
                  </ul>
                </div>
              ) : pairInfo !== ZEROTH_ADDRESS ? (
                <div className={`${cascading.desccardsec} mt-4`}>
                  <p className={`${cascading.head}`}>
                    <img
                      src={iconimg1}
                      className={`img-fluid ${cascading.coinimg}`}
                    />{" "}
                    By adding liquidity you will earn 0.17% of all trades on
                    this pair propotional to your share of the pool.Fees are
                    added to the pool, accure in real time and can be claimed by
                    withdrawing your liquidity.
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
      {walletmodalshow && (
        <Walletmodal
          show={walletmodalshow}
          onHide={() => setWalletmodalshow(false)}
        />
      )}
      {recentshow && (
        <Recenthistory show={recentshow} onHide={() => setRecentshow(false)} />
      )}
      {/* {tokenshow && <Selecttoken show={tokenshow} commontoken={commontoken} onHide={() => { setTokenshow(false); setCommontoken(false) }} />} */}
      {settingshow && (
        <Settingsmodal
          show={settingshow}
          onHide={() => setSettingshow(false)}
        />
      )}
      {tokenshow && (
        <Selecttoken
          show={tokenshow}
          SetToken={(data) => {
            onFromTokenChange(data);
          }}
          commontoken={commontoken}
          onHide={() => {
            setTokenshow(false);
            setCommontoken(false);
          }}
        />
      )}
      {tokenshow2 && (
        <Selecttoken
          show={tokenshow2}
          SetToken={(data) => {
            console.log(data, "onToTokenChange");
            onToTokenChange(data);
          }}
          commontoken={commontoken}
          onHide={() => {
            setTokenshow2(false);
            setCommontoken(false);
          }}
        />
      )}
      {confirmRemove && (
        <ConfirmRemovemodal
          show={confirmRemove}
          supplyData={() => {
            supplyData();
          }}
          supplyObj={obj}
          pairInfo={pairInfo}
          onHide={() => setConfirmRemove(false)}
        />
      )}
      {succesSupply && (
        <CompletedRemovemodal
          show={succesSupply}
          onHide={() => setSuccesSupply(false)}
        />
      )}
      {waitingRemove && (
        <WaitingRemovemodal
          show={waitingRemove}
          supplyObj={obj}
          onHide={() => setWaitingRemove(false)}
        />
      )}
    </>
  );
}
