import { combineReducers } from "redux";
import wallet from "./walletReducers";
import adminReducer from './admin'
import settings from "./settingsReducer";
import poolRedux from './poolReducer'

export default combineReducers({
    wallet:wallet,
    admin:adminReducer,
    settings:settings,
    pool:poolRedux
});