import React, { useEffect, useState } from "react";
import { IoMdSettings } from "react-icons/io";
import { MdHistory } from "react-icons/md";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FaRegQuestionCircle } from "react-icons/fa";
import cascading from "../../assests/css/swap.module.css";
import Footer from "../../common/footer";
import { FaArrowDown } from "react-icons/fa";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { FaSyncAlt } from "react-icons/fa";

import Header from "../../common/header";
import { useFetcher, useNavigate } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import bnbimg from "../../assests/images/bnb.png";
import Walletmodal from "../../modals/walletmodal";
import { useSelector } from "react-redux";
import Recenthistory from "../../modals/recenthistory";
import Selecttoken from "../../modals/selecttoken";
import Settingsmodal from "../../modals/settingsmodal";
import coinimg from "../../assests/images/infoicon.png";
import { ZEROTH_ADDRESS } from "../../config/env";
import BigNumber from "bignumber.js";
import ConfirmSwapmodal from "../../modals/confirmSwapmodal";
import WaitingSwapmodal from "../../modals/waitingSwapmodal";
import CompletedSwapmodal from "../../modals/completedSwapmodal";

//import lib
import { consolelog } from "../../lib/consolelog";
import { isEmpty } from "../../lib/isEmpty";
import fileObjectUrl from "../../lib/img";

import {
  BLOCKED_PRICE_IMPACT_NON_EXPERT,
  ALLOWED_PRICE_IMPACT_HIGH,
  ALLOWED_PRICE_IMPACT_MEDIUM,
  ALLOWED_PRICE_IMPACT_LOW,
} from "../../config/env";

//hooks
import { GetPairInfo } from "../../hooks/useFactory";
import {
  GetAmountsIn,
  GetAmountsOut,
  getRouterAddress,
} from "../../hooks/useRouter";
import { toFixedNumber } from "../../lib/FixedNumber";
import { getSlippage, getWalletAddress } from "../../lib/localStorage";
import { multicall } from "../../hooks/useMultiCall";
import {
  SwapExactETHForTokens,
  SwapETHForExactTokens,
  SwapExactTokensForETH,
  SwapTokensForExactETH,
  SwapExactTokensForTokens,
  SwapTokensForExactTokens,
} from "../../hooks/useRouter";
import { UseAllCommonPairs } from "../../hooks/useTokens";
import { UseTradeExactIn, UseTradeExactOut } from "../../hooks/useExactTrade";
import { CheckIsApproved, ApproveToken } from "../../hooks/useTokens";

//import abi
import pairABI from "../../config/abi/pairAbi.json";

//config
import { MAX_UNIT256 } from "../../config/env";
import HeaderTabs from "../../common/HeaderTabs";

const headerTabsNames = [
  {
    id: 1,
    name: "Swap",
    linkTo: "/swap",
  },
  {
    id: 2,
    name: "Liquidity",
    linkTo: "/liquidity",
  },
  {
    id: 3,
    name: "Buy Crypto",
    linkTo: "/buycrypto",
  }
];

export default function Swap() {
  const navigate = useNavigate();

  //state
  const [swapsec, setSwapsec] = useState(true);
  const [walletmodalshow, setWalletmodalshow] = useState(false);
  const [recentshow, setRecentshow] = useState(false);
  const [tokenshowA, setTokenshowA] = useState(false);
  const [tokenshowB, setTokenshowB] = useState(false);
  const [settingshow, setSettingshow] = useState(false);
  const [tokenA, setTokenA] = useState({});
  const [tokenB, setTokenB] = useState({});
  const [tokenAval, setTokenAval] = useState("");
  const [tokenBval, setTokenBval] = useState("");
  const [lpAddress, setlpAddress] = useState("");
  const [price, setPrice] = useState("");
  const [MiniReceived, setMiniReceived] = useState("");
  const [MaxSold, setMaxSold] = useState("");
  const [ReserveA, setReserveA] = useState("");
  const [ReserveB, setReserveB] = useState("");
  const [priceImpact, setpriceImpact] = useState("");
  const [lpFee, setlpFee] = useState("");
  const [disableSwap, setdisableSwap] = useState(false);
  const [SwapShow, setSwapShow] = useState("");
  const [isFromEdit, setisFromEdit] = useState(true);
  const [isToEdit, setisToEdit] = useState(false);
  const [confirmShow, setCofirmShow] = useState(false);
  const [waitingShow, setWaitingShow] = useState(false);
  const [compeletedShow, setCompeletedShow] = useState(false);
  const [approve, setApprove] = useState(false);
  const [addsend, setAddsend] = useState(false);
  const [recipient, setRecipient] = useState("");
  const [Routepath, setRoutepath] = useState([]);
  const [Route, setRoute] = useState("");

  //selector
  const userdetail = useSelector((state) => state.admin);
  const walletData = useSelector((state) => state.wallet);
  const { slippage, deadline, expertmode, multihops } = useSelector(
    (state) => state.settings
  );
  console.log("userdetail", userdetail);

  useEffect(() => {
    if (!isEmpty(tokenA) && !isEmpty(tokenB)) {
      getPairAddress();
    }
    if (!isEmpty(tokenAval) && !isEmpty(tokenA) && !isEmpty(tokenB)) {
      console.log("call__UseTradeExactIn");
      // UseTradeExactIn(tokenAval, tokenA.address, tokenB.address)
      if (lpAddress !== ZEROTH_ADDRESS && !isEmpty(lpAddress)) {
        if (isFromEdit) {
          getAmountOut();
        }
        CheckApprove();
      }
    }
    if (!isEmpty(tokenA) && !isEmpty(tokenB) && !isEmpty(tokenBval)) {
      if (lpAddress !== ZEROTH_ADDRESS && !isEmpty(lpAddress)) {
        if (isToEdit) {
          getAmountIn();
        }
        CheckApprove();
      }
    }
  }, [tokenA, tokenB, tokenAval, tokenBval]);

  useEffect(() => {}, []);

  // useEffect(() => {
  //     if (lpAddress !== ZEROTH_ADDRESS && !isEmpty(lpAddress)) {
  //         getAmountOut()
  //     }
  // }, [tokenAval])

  const CheckApprove = async () => {
    try {
      if (tokenA.symbol == "BNB") {
        setApprove(true);
        return true;
      }
      let account = getWalletAddress();
      let amount = toFixedNumber(parseFloat(tokenAval) * 10 ** 18).toString();
      let isApproved = await CheckIsApproved(
        getRouterAddress(),
        amount,
        account,
        tokenA.address
      );
      setApprove(isApproved);
    } catch (err) {
      consolelog("CheckApprove__err", err, true);
    }
  };

  const ApproveCall = async () => {
    try {
      let spenderAddress = getRouterAddress();
      let amount = toFixedNumber(parseFloat(tokenAval) * 10 ** 18).toString();
      let account = getWalletAddress();
      let isApproved = await CheckIsApproved(
        spenderAddress,
        amount,
        account,
        tokenA.address
      );
      if (isApproved) {
        setApprove(true);
        return true;
      }
      let approve = await ApproveToken(
        spenderAddress,
        MAX_UNIT256,
        account,
        tokenA.address
      );
      if (approve) {
        setApprove(true);
      }
    } catch (err) {
      consolelog("CheckApprove__err", err, true);
    }
  };

  //get function
  const getPairAddress = async () => {
    try {
      let pairaddress = await GetPairInfo(tokenB.address, tokenA.address);
      setlpAddress(pairaddress);
      if (pairaddress != ZEROTH_ADDRESS) {
        let calls = [
          {
            address: pairaddress,
            name: "getReserves",
          },
          {
            address: pairaddress,
            name: "token0",
          },
          {
            address: pairaddress,
            name: "token1",
          },
        ];
        var pooldata = await multicall(pairABI, calls);

        if (pooldata) {
          let token0 = pooldata[1][0];
          let token1 = pooldata[2][0];
          let reserveA =
            new BigNumber(pooldata[0][0]._hex).toNumber() / 10 ** 18;
          let reserveB =
            new BigNumber(pooldata[0][1]._hex).toNumber() / 10 ** 18;
          console.log(
            "getPairAddress",
            pooldata[1][0],
            pooldata[2][0],
            reserveA,
            reserveB,
            pairaddress
          );
          // if(tokenA.address.toLowerCase() ==token1.toLowerCase() && tokenB.address.toLowerCase() ==token0.toLowerCase()){
          //     console.log('condition')
          //     setSwapsec(false)
          // }
          setReserveA(
            toFixedNumber(
              new BigNumber(pooldata[0][0]._hex).toNumber() / 10 ** 18
            )
          );
          setReserveB(
            toFixedNumber(
              new BigNumber(pooldata[0][1]._hex).toNumber() / 10 ** 18
            )
          );
        }
      }
    } catch (err) {
      consolelog("getPairAddress__err", err, true);
    }
  };

  const getAmountOut = async () => {
    try {
      console.log(multihops, "multihops");
      if (multihops >= 3) {
        let {
          priceImpact,
          amountOut,
          routePath,
          route,
          realizedLPFee,
          status,
        } = await UseTradeExactIn(tokenAval, tokenA.address, tokenB.address);
        console.log(routePath, "routePath");
        if (status) {
          if (!isNaN(amountOut)) {
            setTokenBval(amountOut);
            miniReciviedOut(amountOut);
            setRoutepath(routePath);
            setRoute(route);
            setlpFee(parseFloat(realizedLPFee).toFixed(16));
            // MidPrice(amountOut)
            priceImpactValidation(priceImpact);
            priceImpact =
              parseFloat(priceImpact.toFixed(2)) < 0.01
                ? "<0.01"
                : priceImpact.toFixed(2);
            setpriceImpact(priceImpact);
          }
          let price = tokenAval / amountOut;
          if (!isNaN(price)) {
            setPrice(price);
          }
        } else {
          let AddressArr = [tokenA.address, tokenB.address];
          let amountsIn = toFixedNumber(tokenAval * 10 ** 18).toFixed(0);
          let { price, amountOut } = await GetAmountsOut(AddressArr, amountsIn);
          consolelog("getAmountOut", { price, amountOut }, true);
          if (!isNaN(amountOut)) {
            setRoutepath([]);
            setRoute("");
            setTokenBval(amountOut);
            miniReciviedOut(amountOut);
            MidPrice(amountOut);
            LpFee();
          }
          if (!isNaN(price)) {
            setPrice(price);
          }
        }
      } else {
        let AddressArr = [tokenA.address, tokenB.address];
        let amountsIn = toFixedNumber(tokenAval * 10 ** 18).toFixed(0);
        let { price, amountOut } = await GetAmountsOut(AddressArr, amountsIn);
        consolelog("getAmountOut", { price, amountOut }, true);
        if (!isNaN(amountOut)) {
          setTokenBval(amountOut);
          miniReciviedOut(amountOut);
          MidPrice(amountOut);
          LpFee();
        }
        if (!isNaN(price)) {
          setPrice(price);
        }
      }
    } catch (err) {
      consolelog("getPairAddress__err", err, true);
    }
  };

  const getAmountIn = async () => {
    try {
      console.log(multihops, "multihops");
      if (multihops >= 3) {
        let { priceImpact, amountIn, routePath, route, realizedLPFee, status } =
          await UseTradeExactOut(tokenBval, tokenA.address, tokenB.address);
        console.log(routePath, "routePath");
        if (status) {
          if (!isNaN(amountIn)) {
            setTokenAval(amountIn);
            maximumsold(amountIn);
            setRoutepath(routePath);
            setRoute(route);
            setlpFee(parseFloat(realizedLPFee).toFixed(16));
            // MidPrice(amountOut)
            priceImpactValidation(priceImpact);
            priceImpact =
              parseFloat(priceImpact.toFixed(2)) < 0.01
                ? "<0.01"
                : priceImpact.toFixed(2);
            setpriceImpact(priceImpact);
          }
          let price = amountIn / tokenBval;
          if (!isNaN(price)) {
            setPrice(price);
          }
        } else {
          let AddressArr = [tokenA.address, tokenB.address];
          let amountsOut = toFixedNumber(tokenBval * 10 ** 18).toFixed(0);
          let { price, amountIn } = await GetAmountsIn(AddressArr, amountsOut);
          consolelog("getAmountOut", { price, amountIn }, true);
          if (!isNaN(amountIn)) {
            setRoutepath([]);
            setRoute("");
            setTokenAval(amountIn);
            maximumsold(amountIn);
            MidPrice(amountIn);
            LpFee();
          }
          if (!isNaN(price)) {
            setPrice(price);
          }
        }
      } else {
        let AddressArr = [tokenA.address, tokenB.address];
        let amountsIn = toFixedNumber(tokenAval * 10 ** 18).toFixed(0);
        let { price, amountIn } = await GetAmountsIn(AddressArr, amountsIn);
        consolelog("getAmountOut", { price, amountIn }, true);
        if (!isNaN(amountIn)) {
          setTokenAval(amountIn);
          maximumsold(amountIn);
          MidPrice(amountIn);
          LpFee();
        }
        if (!isNaN(price)) {
          setPrice(price);
        }
      }
    } catch (err) {
      console.log(err, "getAmountIn__err");
    }
  };

  //Change func
  const onTokenAChange = (value) => {
    try {
      if (value.address == tokenB?.address) {
        setTokenB(tokenA);
      }
      setTokenA(value);
    } catch (err) {
      consolelog("HandleChange__err", err, true);
    }
  };
  const onTokenBChange = (value) => {
    try {
      if (value.address == tokenA?.address) {
        setTokenA(tokenB);
      }
      setTokenB(value);
    } catch (err) {
      consolelog("HandleChange__err", err, true);
    }
  };

  const handleChange = (e) => {
    try {
      const { value, id } = e.target;
      var numbers = /^\d+((.)|(.\d{0,30})?)$/;
      consolelog(
        "handleChange",
        { val: value, id: id, con: !numbers.test(value) && value !== "" },
        true
      );
      if (!numbers.test(value) && value !== "") {
        return false;
      }
      if (id == "tokenAval") {
        setisFromEdit(true);
        setisToEdit(false);
        setTokenAval(value);
      }
      if (id == "tokenBval") {
        setisToEdit(true);
        setisFromEdit(false);
        setTokenBval(value);
      }
    } catch (err) {
      consolelog("HandleChange__err", err, true);
    }
  };

  //calulation
  const miniReciviedOut = (amountOut) => {
    try {
      //minimumReceived
      let slippageval = parseFloat(slippage) / 100;
      var minimumReceived = parseFloat(amountOut) * (1 - slippageval);
      minimumReceived = toFixedNumber(minimumReceived);
      // minimumReceived = Math.round(minimumReceived)
      setMiniReceived(minimumReceived);

      consolelog("miniRecivied", { minimumReceived: minimumReceived }, true);
    } catch (err) {
      consolelog("miniRecivied__err", err, true);
    }
  };

  const maximumsold = (amountIn) => {
    try {
      //minimumReceived
      let slippageval = parseFloat(slippage) / 100;
      var minimumReceived = parseFloat(tokenAval) * (1 + slippageval);
      minimumReceived = toFixedNumber(minimumReceived);
      // minimumReceived = Math.round(minimumReceived)
      setMaxSold(minimumReceived);

      consolelog("miniRecivied", { minimumReceived: minimumReceived }, true);
    } catch (err) {
      consolelog("miniRecivied__err", err, true);
    }
  };

  const LpFee = () => {
    try {
      //lp fee
      let realizedLPFee = (tokenAval * 0.25) / 100;
      setlpFee(toFixedNumber(realizedLPFee));
    } catch (err) {
      consolelog("LpFee__err", err, true);
    }
  };

  const MidPrice = (amountOut) => {
    try {
      let midprice;
      if (isFromEdit) {
        midprice = Number(ReserveA) / Number(ReserveB);
        var exactQuote = midprice * parseFloat(tokenAval);
        var priceImpact = (exactQuote - amountOut) / exactQuote;
        priceImpact = (priceImpact - 0.25 / 100) * 100;
        console.log(priceImpact, "priceImpact");
        priceImpactValidation(priceImpact);
        priceImpact =
          parseFloat(priceImpact.toFixed(2)) < 0.01
            ? "<0.01"
            : priceImpact.toFixed(2);
        setpriceImpact(priceImpact);
      } else {
        midprice = Number(ReserveB) / Number(ReserveA);
        var exactQuote = midprice * parseFloat(tokenBval);
        var priceImpact = (exactQuote - amountOut) / exactQuote;
        priceImpact = (priceImpact - 0.25 / 100) * 100;
        console.log(priceImpact, "priceImpact");
        priceImpactValidation(priceImpact);
        priceImpact =
          parseFloat(priceImpact.toFixed(2)) < 0.01
            ? "<0.01"
            : priceImpact.toFixed(2);
        setpriceImpact(priceImpact);
      }
    } catch (err) {
      consolelog("MidPrice__err", err, true);
    }
  };

  //validation
  const priceImpactValidation = (priceImpact) => {
    try {
      if (!(priceImpact < BLOCKED_PRICE_IMPACT_NON_EXPERT)) {
        setSwapShow("Price Impact High");
        setdisableSwap(true);
        return 4;
      }
      if (!(priceImpact < ALLOWED_PRICE_IMPACT_HIGH)) {
        setSwapShow("Swap Anyway");
        setdisableSwap(false);
        return 3;
      }
      if (!(priceImpact < ALLOWED_PRICE_IMPACT_MEDIUM)) {
        setSwapShow("Swap");
        setdisableSwap(false);
        return 2;
      }
      if (!(priceImpact < ALLOWED_PRICE_IMPACT_LOW)) {
        setSwapShow("Swap");
        setdisableSwap(false);
        return 1;
      } else {
        setSwapShow("Swap");
        setdisableSwap(false);
        return 0;
      }
    } catch (err) {
      consolelog("priceImpactValidation__err", err, true);
    }
  };

  //submit
  const SwapSubmit = async () => {
    try {
      setWaitingShow(true);
      let to =
        expertmode && !isEmpty(recipient) ? recipient : getWalletAddress();
      console.log(
        tokenA.symbol == "BNB" && isFromEdit,
        tokenA.symbol == "BNB" && isToEdit,
        "SwapSubmit",
        tokenA.symbol,
        tokenB.symbol
      );
      if (tokenA.symbol == "BNB" && isFromEdit) {
        let amountETH = toFixedNumber(tokenAval * 10 ** 18).toFixed(0);
        let amountOutMin = toFixedNumber(MiniReceived * 10 ** 18).toFixed(0);
        let path = isEmpty(Routepath)
          ? [tokenA.address, tokenB.address]
          : Routepath;
        let deadLine = (
          new Date().getTime() / 1000 +
          parseFloat(deadline)
        ).toFixed(0);
        let qureyString = `Swap ${tokenAval} ${tokenA.symbol} for ${tokenBval} ${tokenB.symbol}`;
        let result = await SwapExactETHForTokens(
          amountETH,
          amountOutMin,
          path,
          to,
          deadLine,
          qureyString
        );
        if (result) {
          setWaitingShow(false);
          setCompeletedShow(true);
          initalStateSet();
          return true;
        } else {
          setCofirmShow(true);
          setWaitingShow(false);
        }
      } else if (tokenA.symbol == "BNB" && isToEdit) {
        let amountETH = toFixedNumber(tokenAval * 10 ** 18).toFixed(0);
        let amountOut = toFixedNumber(tokenBval * 10 ** 18).toFixed(0);
        let path = isEmpty(Routepath)
          ? [tokenA.address, tokenB.address]
          : Routepath;
        // let to = getWalletAddress()
        let deadLine = (
          new Date().getTime() / 1000 +
          parseFloat(deadline)
        ).toFixed(0);
        console.log("SwapETHForExactTokens");
        let qureyString = `Swap ${tokenAval} ${tokenA.symbol} for ${tokenBval} ${tokenB.symbol}`;
        let result = await SwapETHForExactTokens(
          amountETH,
          amountOut,
          path,
          to,
          deadLine,
          qureyString
        );
        if (result) {
          setWaitingShow(false);
          setCompeletedShow(true);
          initalStateSet();
        } else {
          setCofirmShow(true);
          setWaitingShow(false);
        }
      } else if (tokenB.symbol == "BNB" && isFromEdit) {
        let amountIn = toFixedNumber(tokenAval * 10 ** 18).toFixed(0);
        let amountOutMin = toFixedNumber(MiniReceived * 10 ** 18).toFixed(0);
        let path = isEmpty(Routepath)
          ? [tokenA.address, tokenB.address]
          : Routepath;
        // let to = getWalletAddress()
        let deadLine = (
          new Date().getTime() / 1000 +
          parseFloat(deadline)
        ).toFixed(0);
        let qureyString = `Swap ${tokenAval} ${tokenA.symbol} for ${tokenBval} ${tokenB.symbol}`;
        let result = await SwapExactTokensForETH(
          amountIn,
          amountOutMin,
          path,
          to,
          deadLine,
          qureyString
        );
        if (result) {
          setWaitingShow(false);
          setCompeletedShow(true);
          initalStateSet();
        } else {
          setCofirmShow(true);
          setWaitingShow(false);
        }
      } else if (tokenB.symbol == "BNB" && isToEdit) {
        let amountOut = toFixedNumber(tokenBval * 10 ** 18).toFixed(0);
        let amountInMax = toFixedNumber(MaxSold * 10 ** 18).toFixed(0);
        let path = isEmpty(Routepath)
          ? [tokenA.address, tokenB.address]
          : Routepath;
        // let to = getWalletAddress()
        let deadLine = (
          new Date().getTime() / 1000 +
          parseFloat(deadline)
        ).toFixed(0);
        let qureyString = `Swap ${tokenAval} ${tokenA.symbol} for ${tokenBval} ${tokenB.symbol}`;
        let result = await SwapTokensForExactETH(
          amountOut,
          amountInMax,
          path,
          to,
          deadLine,
          qureyString
        );
        if (result) {
          setWaitingShow(false);
          setCompeletedShow(true);
          initalStateSet();
        } else {
          setCofirmShow(true);
          setWaitingShow(false);
        }
      } else if (isFromEdit) {
        let amountIn = toFixedNumber(tokenAval * 10 ** 18).toFixed(0);
        let amountOutMin = toFixedNumber(MiniReceived * 10 ** 18).toFixed(0);
        let path = isEmpty(Routepath)
          ? [tokenA.address, tokenB.address]
          : Routepath;
        // let to = getWalletAddress()
        let deadLine = (
          new Date().getTime() / 1000 +
          parseFloat(deadline)
        ).toFixed(0);
        let qureyString = `Swap ${tokenAval} ${tokenA.symbol} for ${tokenBval} ${tokenB.symbol}`;
        let result = await SwapExactTokensForTokens(
          amountIn,
          amountOutMin,
          path,
          to,
          deadLine,
          qureyString
        );
        if (result) {
          setWaitingShow(false);
          setCompeletedShow(true);
          initalStateSet();
        } else {
          setCofirmShow(true);
          setWaitingShow(false);
        }
      } else if (isToEdit) {
        let amountOut = toFixedNumber(tokenBval * 10 ** 18).toFixed(0);
        let amountInMax = toFixedNumber(MaxSold * 10 ** 18).toFixed(0);
        let path = isEmpty(Routepath)
          ? [tokenA.address, tokenB.address]
          : Routepath;
        // let to = getWalletAddress()
        let deadLine = (
          new Date().getTime() / 1000 +
          parseFloat(deadline)
        ).toFixed(0);
        let qureyString = `Swap ${tokenAval} ${tokenA.symbol} for ${tokenBval} ${tokenB.symbol}`;
        let result = await SwapTokensForExactTokens(
          amountOut,
          amountInMax,
          path,
          to,
          deadLine,
          qureyString
        );
        if (result) {
          setWaitingShow(false);
          setCompeletedShow(true);
          initalStateSet();
        } else {
          setCofirmShow(true);
          setWaitingShow(false);
        }
      }
    } catch (err) {
      consolelog("SwapSubmit_err", err, true);
    }
  };

  const initalStateSet = () => {
    try {
      setTokenAval(0);
      setTokenBval(0);
      setMiniReceived(0);
      setMaxSold(0);
      setisFromEdit(true);
      setisToEdit(false);
      setpriceImpact(0);
      setPrice(0);
      setlpFee(0);
      setRecipient("");
      setSwapsec(true);
    } catch (err) {
      consolelog("initalStateSet__err", err, true);
    }
  };

  console.log("tokenBval", expertmode, tokenA, tokenBval, true);
  return (
    <>
      <Header />
      <HeaderTabs headerTabsNames={headerTabsNames} />
      <div className={`${cascading.innerpages} ${cascading.swappage}`}>
        <div className={`container`}>
          <div
            className={`row justify-content-center ${cascading.swapcardrow}`}
          >
            <div className={`col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 `}>
              <div className={` ${cascading.cardstyle}`}>
                <div className={`${cascading.toprow}`}>
                  <div>
                    <p className={`${cascading.headings}`}>Swap</p>
                    <p className={`${cascading.desc}`}>
                      Trade tokens in an instant
                    </p>
                  </div>
                  <div className={`${cascading.iconsec}`}>
                    <IoMdSettings onClick={() => setSettingshow(true)} />
                    <MdHistory onClick={() => setRecentshow(true)} />
                  </div>
                </div>
                <div className={`${cascading.midrow}`}>
                  <div
                    className={` ${cascading.rightbtnsec}`}
                  >
                    {userdetail?.walletStatus == "connect" && (
                      <button
                        className={`btn ${cascading.currencybtn} ${cascading.rightmaxbtn}`}
                        onClick={() => {
                          setTokenAval(tokenA?.balance);
                        }}
                      >
                        {" "}
                        Max{" "}
                      </button>
                    )}
                    <button
                      className={`btn ${cascading.currencybtn}`}
                      onClick={() => setTokenshowA(true)}
                    >
                      <img
                        src={
                          isEmpty(tokenA)
                            ? fileObjectUrl(coinimg)
                            : fileObjectUrl(tokenA.image)
                        }
                        alt="bnb"
                        className={`img-fluid ${cascading.coinimg}`}
                      />{" "}
                      <p className={`${cascading.currencytype} mb-0`}>BNB</p>
                      {isEmpty(tokenA) ? "" : tokenA.symbol}{" "}
                      {/* <FaAngleDown fill="#fff" /> */}
                      <MdOutlineArrowDropDown className="fs-5" />
                    </button>
                  </div>{" "}
                  <div className={`${cascading.inputsec} `}>
                    <div className={`${cascading.balancerow}`}>
                      <p className={`${cascading.inputlabel}`}>
                        From {!isFromEdit ? "(estimated)" : ""}
                      </p>
                      {userdetail?.walletStatus == "connect" && (
                        <p className={`${cascading.inputlabel}`}>
                          Balance :{isEmpty(tokenA) ? 0 : tokenA.balance}
                        </p>
                      )}
                    </div>

                    <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                      <div>
                        <input
                          type="text"
                          className={`from-control`}
                          id="tokenAval"
                          aria-describedby="emailHelp"
                          placeholder="0.0"
                          value={tokenAval}
                          onChange={handleChange}
                        />
                      </div>
                      {/* {swapsec ? */}
                      {/* <div className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}>
                                                {(userdetail?.walletStatus == 'connect') && 
                                                <button 
                                                className={`btn ${cascading.currencybtn}`}
                                                onClick={()=>{
                                                    setTokenAval(tokenA?.balance)
                                                }}
                                                > Max 
                                                </button>}
                                                <button className={`btn ${cascading.currencybtn}`} onClick={()=> setTokenshowA(true)}>
                                                    <img src={isEmpty(tokenA)? '':fileObjectUrl(tokenA.image)} alt='bnb' className={`img-fluid ${cascading.coinimg}`} /> {isEmpty(tokenA)? '':tokenA.symbol} <FaAngleDown fill='#fff' /></button>
                                            </div>
                                            : */}
                      {/* <div
                        className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}
                      >
                        {userdetail?.walletStatus == "connect" && (
                          <button
                            className={`btn ${cascading.currencybtn}`}
                            onClick={() => {
                              setTokenAval(tokenA?.balance);
                            }}
                          >
                            {" "}
                            Max{" "}
                          </button>
                        )}
                        <button
                          className={`btn ${cascading.currencybtn}`}
                          onClick={() => setTokenshowA(true)}
                        >
                          <img
                            src={
                              isEmpty(tokenA)
                                ? fileObjectUrl(coinimg)
                                : fileObjectUrl(tokenA.image)
                            }
                            alt="bnb"
                            className={`img-fluid ${cascading.coinimg}`}
                          />{" "}
                          {isEmpty(tokenA) ? "" : tokenA.symbol}{" "}
                          <FaAngleDown fill="#fff" />
                        </button>
                      </div> */}

                      {/* } */}
                    </div>
                  </div>
                </div>

                <div
                  className={`row ${cascading.arrowrow}`}
                  onClick={() => {
                    setSwapsec(!swapsec);
                    setisFromEdit(!isFromEdit);
                    setisFromEdit(!isToEdit);
                    setTokenA(tokenB);
                    setTokenB(tokenA);
                    setTokenAval(tokenBval);
                    setTokenBval(tokenAval);
                  }}
                >
                  <div className={`${cascading.swaparrow}`}>
                    <FaArrowDown fill="#7645D9" />
                  </div>
                </div>
                <div className={`${cascading.lastrow}`}>
                  <div className={` ${cascading.rightbtnsec}`}>
                    <button
                      className={`btn ${cascading.currencybtn}`}
                      onClick={() => setTokenshowB(true)}
                    >
                      <img
                        src={
                          isEmpty(tokenB)
                            ? fileObjectUrl(coinimg)
                            : fileObjectUrl(tokenB.image)
                        }
                        alt="bnb"
                        className={`img-fluid ${cascading.coinimg}`}
                      />
                      {isEmpty(tokenB) ? "Select a Currency" : tokenB.symbol}{" "}
                      <MdOutlineArrowDropDown className="fs-5" />
                    </button>
                  </div>
                  <div className={`${cascading.inputsec} `}>
                    <div className={`${cascading.balancerow}`}>
                      <p className={`${cascading.inputlabel}`}>
                        To {!isToEdit ? "(estimated)" : ""}
                      </p>
                      {userdetail?.walletStatus == "connect" && (
                        <p className={`${cascading.inputlabel}`}>
                          Balance :{isEmpty(tokenB) ? 0 : tokenB.balance}
                        </p>
                      )}
                    </div>

                    <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                      <div>
                        <input
                          type="type"
                          className={`from-control`}
                          id="tokenBval"
                          aria-describedby="emailHelp"
                          value={tokenBval}
                          onChange={handleChange}
                          placeholder="0.0"
                        />
                      </div>
                      {/* {!swapsec ?
                                            <div className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}>
                                                {(userdetail?.walletStatus == 'connect') && <button className={`btn ${cascading.currencybtn}`}> Max </button>}
                                                <button className={`btn ${cascading.currencybtn}`} onClick={()=> setTokenshowB(true)}><img src={bnbimg} alt='bnb' className={`img-fluid ${cascading.coinimg}`} />  <FaAngleDown fill='#fff' /></button>
                                            </div>
                                            : */}
                      {/* <div className={` ${cascading.rightbtnsec}`}>
                        <button
                          className={`btn ${cascading.currencybtn}`}
                          onClick={() => setTokenshowB(true)}
                        >
                          <img
                            src={
                              isEmpty(tokenB)
                                ? fileObjectUrl(coinimg)
                                : fileObjectUrl(tokenB.image)
                            }
                            alt="bnb"
                            className={`img-fluid ${cascading.coinimg}`}
                          />
                          {isEmpty(tokenB)
                            ? "Select a Currency"
                            : tokenB.symbol}{" "}
                          <FaAngleDown fill="#fff" />
                        </button>
                      </div> */}

                      {/* } */}
                    </div>
                  </div>
                </div>

                {expertmode ? (
                  !addsend ? (
                    <p
                      className={`${cascading.cursorpointer} text-center `}
                      onClick={() => {
                        setAddsend(true);
                      }}
                    >
                      Add a send (optional)
                    </p>
                  ) : (
                    <>
                      <div
                        className={`row d-flex align-items-center justify-content-ceenter text-center ${cascading.arrowrow}`}
                      >
                        <div className={`${cascading.swaparrow}`}>
                          <FaArrowDown fill="#7645d9" />
                        </div>
                        <div>
                          <p
                            className={`${cascading.cursorpointer} text-center mt-3 mb-1`}
                            onClick={() => {
                              setAddsend(false);
                            }}
                          >
                            Remove send
                          </p>
                        </div>
                      </div>
                    </>
                  )
                ) : (
                  ""
                )}

                {expertmode && addsend && (
                  <div className={`${cascading.lastrow}`}>
                    <div className={`${cascading.valuecardsec}`}>
                      <div className={`${cascading.secrow}`}>
                        <p className={`mb-0 ${cascading.label}`}>Recipient</p>
                      </div>

                      <div
                        className={`${cascading.inputsecrowrecipeinet} mt-3`}
                      >
                        <div>
                          <input
                            type="text"
                            className={`from-control`}
                            id="recipient"
                            aria-describedby="emailHelp"
                            placeholder="Wallet address or ENS name"
                            onChange={(e) => {
                              setRecipient(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                )}

                {tokenBval && !isEmpty(tokenBval) ? (
                  <div className={`${cascading.lastrow} `}>
                    <div className={`${cascading.valuecardsec} `}>
                      <div className={`${cascading.secrow}`}>
                        <p className={`mb-0 ${cascading.label}`}>Price</p>
                        <p className={`mb-0 ${cascading.labels}`}>
                          {price}{" "}
                          {swapsec
                            ? `${tokenA.symbol} per ${tokenB.symbol}`
                            : `${tokenB.symbol} per ${tokenA.symbol}`}
                          <button className={`${cascading.roundiconbtn}`}>
                            <FaSyncAlt />
                          </button>
                        </p>
                      </div>
                      <div className={`mt-3 pb-2 ${cascading.secrow}`}>
                        <p className={`mb-0 ${cascading.label}`}>
                          Slippage Tolerance
                        </p>
                        <p className={`mb-0 ${cascading.labels}`}>
                          {slippage}%
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className={`${cascading.btnsec}`}>
                  {userdetail?.walletStatus == null ||
                  userdetail?.walletStatus == "disconnect" ? (
                    <button
                      className={`btn ${cascading.connectwalletbtn}`}
                      onClick={() => setWalletmodalshow(true)}
                    >
                      Connect Wallet
                    </button>
                  ) : lpAddress == ZEROTH_ADDRESS ? (
                    <button className={`btn ${cascading.connect_btn}`}>
                      Insufficient liquidity for this trade.
                    </button>
                  ) : isEmpty(tokenAval) ? (
                    <button className={`btn ${cascading.connectwalletbtn}`}>
                      Enter an Amount
                    </button>
                  ) : isEmpty(tokenA) || isEmpty(tokenB) ? (
                    <button className={`btn ${cascading.connectwalletbtn}`}>
                      Select a token
                    </button>
                  ) : isEmpty(tokenBval) ? (
                    <button
                      className={`btn ${cascading.connectwalletbtn}`}
                      disabled={true}
                    >
                      Loading...
                    </button>
                  ) : approve ? (
                    <button
                      className={`btn ${cascading.connect_btn}`}
                      disabled={disableSwap}
                      onClick={() => {
                        setCofirmShow(true);
                        // SwapSubmit()
                      }}
                    >
                      {SwapShow}
                    </button>
                  ) : (
                    <button
                      className={`btn ${cascading.connect_btn}`}
                      onClick={() => {
                        ApproveCall();
                        // SwapSubmit()
                      }}
                    >
                      Enable {tokenA.symbol}
                    </button>
                  )}
                </div>
              </div>

              {tokenBval && !isEmpty(tokenBval) ? (
                <div className={`mt-3 pb-1 ${cascading.cardstyle}`}>
                  <div
                    className={`${cascading.valuecardsec} ${cascading.valuecardsecnpng} `}
                  >
                    <div className={`${cascading.secrow}`}>
                      <p
                        className={`mb-0 d-flex align-items-center ${cascading.label}`}
                      >
                        {isFromEdit
                          ? "Minimum Received"
                          : isToEdit
                          ? "Maximum sold"
                          : ""}
                        <OverlayTrigger
                          className={`${cascading.tooltip_style}`}
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              Your transaction will revert if there is a large,
                              unfavorable price movement before it is confirmed.
                            </Tooltip>
                          }
                        >
                          <Button className={`${cascading.tooltip_button}`}>
                            <FaRegQuestionCircle />
                          </Button>
                        </OverlayTrigger>
                      </p>
                      <p className={`mb-0 ${cascading.labels}`}>
                        {isFromEdit
                          ? `${MiniReceived} ${tokenB.symbol}`
                          : isToEdit
                          ? `${MaxSold} ${tokenA.symbol}`
                          : ""}
                      </p>
                    </div>

                    <div className={`mt-3 ${cascading.secrow}`}>
                      <p
                        className={`mb-0 d-flex align-items-center ${cascading.label}`}
                      >
                        Price Impact
                        <OverlayTrigger
                          className={`${cascading.tooltip_style}`}
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              The difference between the market price and
                              estimated price due to trade size.
                            </Tooltip>
                          }
                        >
                          <Button className={`${cascading.tooltip_button}`}>
                            <FaRegQuestionCircle />
                          </Button>
                        </OverlayTrigger>
                      </p>
                      <p className={`mb-0 ${cascading.labels}`}>
                        {priceImpact} %
                      </p>
                    </div>

                    <div className={`mt-3 ${cascading.secrow}`}>
                      <p
                        className={`mb-0 d-flex align-items-center ${cascading.label}`}
                      >
                        Liquidity Provider Fee
                        <OverlayTrigger
                          className={`${cascading.tooltip_style}`}
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              <p className="mb-1">
                                For each trade a 0.25% fee is paid
                              </p>
                              <p className="mb-1">
                                - 0.17% to LP token holders
                              </p>
                              <p className="mb-1">- 0.03% to the Treasury</p>
                              <p className="mb-1">
                                - 0.05% towards DSP buyback and burn
                              </p>
                            </Tooltip>
                          }
                        >
                          <Button className={`${cascading.tooltip_button}`}>
                            <FaRegQuestionCircle />
                          </Button>
                        </OverlayTrigger>
                      </p>
                      <p className={`mb-0 ${cascading.labels}`}>
                        {lpFee} {tokenA.symbol}
                      </p>
                    </div>
                    {!isEmpty(Route) ? (
                      <div className={`mt-3 ${cascading.secrow}`}>
                        <p
                          className={`mb-0 d-flex align-items-center ${cascading.label}`}
                        >
                          Route
                        </p>
                        <p className={`mb-0 ${cascading.labels}`}>{Route}</p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
      {compeletedShow && (
        <CompletedSwapmodal
          show={compeletedShow}
          onHide={() => setCompeletedShow(false)}
        />
      )}
      {waitingShow && (
        <WaitingSwapmodal
          show={waitingShow}
          onHide={() => {
            setWaitingShow(false);
          }}
          tokenA={tokenA}
          tokenB={tokenB}
          tokenAval={tokenAval}
          tokenBval={tokenBval}
        />
      )}
      {confirmShow && (
        <ConfirmSwapmodal
          show={confirmShow}
          onHide={() => {
            setCofirmShow(false);
          }}
          onConfirm={() => {
            SwapSubmit();
          }}
          price={price}
          miniRecivied={MiniReceived}
          maxSold={MaxSold}
          priceImpact={priceImpact}
          lpFee={lpFee}
          tokenA={tokenA}
          tokenB={tokenB}
          tokenAval={tokenAval}
          tokenBval={tokenBval}
          isToEdit={isToEdit}
          isFromEdit={isFromEdit}
        />
      )}
      {walletmodalshow && (
        <Walletmodal
          show={walletmodalshow}
          onHide={() => setWalletmodalshow(false)}
        />
      )}
      {recentshow && (
        <Recenthistory show={recentshow} onHide={() => setRecentshow(false)} />
      )}
      {tokenshowA && (
        <Selecttoken
          show={tokenshowA}
          SetToken={(data) => {
            onTokenAChange(data);
          }}
          onHide={() => setTokenshowA(false)}
        />
      )}
      {tokenshowB && (
        <Selecttoken
          show={tokenshowB}
          SetToken={(data) => {
            onTokenBChange(data);
          }}
          onHide={() => setTokenshowB(false)}
        />
      )}
      {settingshow && (
        <Settingsmodal
          show={settingshow}
          onHide={() => setSettingshow(false)}
        />
      )}
    </>
  );
}
