//local
// export const POOLS = [
//   {
//     sousId: 1,
//     stakingToken: '0x2F30560d17044230eF4c6cEb9768D860e2cFf36E',
//     earningToken: '0x2F30560d17044230eF4c6cEb9768D860e2cFf36E',
//     // poolCategory: PoolCategory.CORE,
//     contractAddress: {
//         97: '0xAb56d6585A4dB40e9779FFA8fc9DE6b16e0129d0',//'0xa373B9f29b40500dF7F538Cb9d0ae73376986533',//'0xeAa81cc2174e73b4C4311f4c7182dC746D1B1627',//0x64C2E898410e6F0dD58eF934072770e7D9536192
//         56: '',
//       },
//     harvest: true,
//     tokenPerBlock: '0.0001',
//     sortOrder: 0,
//     isFinished: false,
//   }

// ]

//demo
export const POOLS = [
  {
    sousId: 1,
    stakingToken: "0xa2c5C316365981B1ff903b76AB0b1B7f3A77C7E5",
    earningToken: "0xa2c5C316365981B1ff903b76AB0b1B7f3A77C7E5",
    // poolCategory: PoolCategory.CORE,
    contractAddress: {
      97: "0x3ee56db5b4B1566A1BcE95C8BF9CF3456A788218", //'0xE80f257AC5d2002CDd073248cAd1279A5Fe389bB',
      56: "",
    },
    harvest: true,
    tokenPerBlock: "0.001",
    sortOrder: 0,
    isFinished: false,
  },
];
