import React, { useState } from "react";
import cascading from "../assests/css/PredictionFlipCard.module.css";
import { IoPlayCircleOutline } from "react-icons/io5";
import { FaArrowDown, FaArrowLeft, FaArrowUp } from "react-icons/fa";

import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

import bscicon from "../assests/images/bscicon.png";
import Walletmodal from "../modals/walletmodal";

const PredictionFlipCard = ({
  setFlipCard,
  isConnectWallet,
  setIsConnectWallet,
  setIsConfirm,
  isDownClick,
  setIsDownClick,
  setEnterDown,
}) => {
  const [upDown, setUpDown] = useState(false);
  const [showmodal, setShowmodal] = useState(false);

  const handleConfirm = () => {
    setFlipCard(false);
    setIsConfirm(true);
  };

  const handleDownConfirm = () => {
    setFlipCard(false);
    setIsConfirm(true);
    setEnterDown(true);
  };

  console.log("down", isDownClick);

  return (
    <>
      {isDownClick ? (
        <div
          className={`${cascading.predictionFlipCardPage} ${cascading.enterDown} enterdown`}
        >
          <div
            className={`${cascading.predictSliderCard} ${cascading.predictSliderCardGray}`}
          >
            <div className={`${cascading.cardSecondaryBg} mx-auto`}>
              <div
                className={`${cascading.topSection} d-flex align-items-center justify-content-between`}
              >
                <div
                  className={`${cascading.backButton} d-flex align-items-center gap-3`}
                >
                  <FaArrowLeft
                    onClick={() => setFlipCard(false)}
                    style={{ cursor: "pointer" }}
                  />
                  <h5 className={`mb-0`}>Set Position</h5>
                </div>
                {upDown ? (
                  <button
                    className={`${cascading.upButton} d-flex align-items-center gap-2`}
                    onClick={() => setUpDown(!upDown)}
                  >
                    <FaArrowUp fontSize={13} />
                    <p className={`mb-0`}>UP</p>
                  </button>
                ) : (
                  <button
                    className={`${cascading.downButton} d-flex align-items-center gap-2`}
                    onClick={() => setUpDown(!upDown)}
                  >
                    <FaArrowDown fontSize={13} />
                    <p className={`mb-0`}>Down</p>
                  </button>
                )}
              </div>
              <div className={`${cascading.inputSection} `}>
                <div
                  className={`${cascading.inputTop} d-flex align-items-center justify-content-between`}
                >
                  <p className={`mb-0`}>Commit:</p>
                  <div
                    className={`${cascading.inputRight} d-flex align-items-center gap-1 `}
                  >
                    <div
                      className={`${cascading.iconBg} d-flex align-items-center justify-content-center`}
                    >
                      <img src={bscicon} alt="icon" className={`img-fluid`} />
                    </div>
                    <p className={`mb-0`}>BSC</p>
                  </div>
                </div>
                <input type="text" className={cascading.inputField} />
              </div>
              <div className={`${cascading.bottomSection}`}>
                <div className={`predict_range`}>
                  <RangeSlider
                    className={`${cascading.single_thumb} mt-3`}
                    defaultValue={[0, 50]}
                    thumbsDisabled={[true, false]}
                    rangeSlideDisabled={true}
                  />
                </div>
                <div
                  className={`${cascading.percentage} d-flex align-items-center justify-content-between`}
                >
                  <div
                    className={`${cascading.percentValue} ${cascading.activePercentValue}`}
                  >
                    10%
                  </div>
                  <div className={`${cascading.percentValue}`}>25%</div>
                  <div className={`${cascading.percentValue}`}>50%</div>
                  <div className={`${cascading.percentValue}`}>75%</div>
                  <div className={`${cascading.percentValue}`}>Max</div>
                </div>
                {isConnectWallet ? (
                  <button
                    className={`${cascading.connectWallet}`}
                    onClick={() => handleDownConfirm()}
                  >
                    {" "}
                    Confirm
                  </button>
                ) : (
                  <button
                    className={`${cascading.connectWallet}`}
                    onClick={() => setShowmodal(true)}
                  >
                    Connect wallet
                  </button>
                )}

                <p className={`${cascading.desc}`}>
                  You won’t be able to remove or change your position once you
                  enter it.
                </p>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className={`${cascading.predictionFlipCardPage}`}>
          <div
            className={`${cascading.predictSliderCard} ${cascading.predictSliderCardGray}`}
          >
            <div className={`${cascading.cardSecondaryBg} mx-auto`}>
              <div
                className={`${cascading.topSection} d-flex align-items-center justify-content-between`}
              >
                <div
                  className={`${cascading.backButton} d-flex align-items-center gap-3`}
                >
                  <FaArrowLeft
                    onClick={() => setFlipCard(false)}
                    style={{ cursor: "pointer" }}
                  />
                  <h5 className={`mb-0`}>Set Position</h5>
                </div>
                {upDown ? (
                  <button
                    className={`${cascading.upButton} d-flex align-items-center gap-2`}
                    onClick={() => setUpDown(!upDown)}
                  >
                    <FaArrowUp fontSize={13} />
                    <p className={`mb-0`}>UP</p>
                  </button>
                ) : (
                  <button
                    className={`${cascading.downButton} d-flex align-items-center gap-2`}
                    onClick={() => setUpDown(!upDown)}
                  >
                    <FaArrowDown fontSize={13} />
                    <p className={`mb-0`}>Down</p>
                  </button>
                )}
              </div>
              <div className={`${cascading.inputSection} `}>
                <div
                  className={`${cascading.inputTop} d-flex align-items-center justify-content-between`}
                >
                  <p className={`mb-0`}>Commit:</p>
                  <div
                    className={`${cascading.inputRight} d-flex align-items-center gap-1 `}
                  >
                    <div
                      className={`${cascading.iconBg} d-flex align-items-center justify-content-center`}
                    >
                      <img src={bscicon} alt="icon" className={`img-fluid`} />
                    </div>
                    <p className={`mb-0`}>BSC</p>
                  </div>
                </div>
                <input type="text" className={cascading.inputField} />
              </div>
              <div className={`${cascading.bottomSection}`}>
                <div className={`predict_range`}>
                  <RangeSlider
                    className={`${cascading.single_thumb} mt-3`}
                    defaultValue={[0, 50]}
                    thumbsDisabled={[true, false]}
                    rangeSlideDisabled={true}
                  />
                </div>
                <div
                  className={`${cascading.percentage} d-flex align-items-center justify-content-between`}
                >
                  <div
                    className={`${cascading.percentValue} ${cascading.activePercentValue}`}
                  >
                    10%
                  </div>
                  <div className={`${cascading.percentValue}`}>25%</div>
                  <div className={`${cascading.percentValue}`}>50%</div>
                  <div className={`${cascading.percentValue}`}>75%</div>
                  <div className={`${cascading.percentValue}`}>Max</div>
                </div>
                {isConnectWallet ? (
                  <button
                    className={`${cascading.connectWallet}`}
                    onClick={() => handleConfirm()}
                  >
                    {" "}
                    Confirm
                  </button>
                ) : (
                  <button
                    className={`${cascading.connectWallet}`}
                    onClick={() => setShowmodal(true)}
                  >
                    Connect wallet
                  </button>
                )}

                <p className={`${cascading.desc}`}>
                  You won’t be able to remove or change your position once you
                  enter it.
                </p>
              </div>{" "}
            </div>
          </div>
        </div>
      )}
      {showmodal && (
        <Walletmodal show={showmodal} onHide={() => setShowmodal(false)} />
      )}{" "}
    </>
  );
};

export default PredictionFlipCard;
