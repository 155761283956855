import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import cascading from "../assests/css/buyticketstyle.module.css";
import { FaRegQuestionCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { IoMdClose } from "react-icons/io";



export default function Buyticketmodal(props) {
  // state
  const [buyticketmodal, setBuyticketmodal] = useState(true);



  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal
          centered
          show={buyticketmodal}
          onHide={props.onHide}
          className={`${cascading.modalstyle}`}
        >
          <Modal.Header
            className={`${cascading.modalclr} ${cascading.modalheader}`}
          >
            <h4 className={`${cascading.modaltitle} mb-0`}>Buy Tickets</h4>
            <IoMdClose
              className={`${cascading.closebtn}`}
              onClick={props.onHide}
            />
          </Modal.Header>
          <Modal.Body
            className={`${cascading.modalclr} ${cascading.modalbody}`}
          >
            <p className={`${cascading.innerhead}`}>Buy:</p>

            <div className={`${cascading.inputsec} `}>
                   

                    <div className={`${cascading.inputsecrow}`}>
                      <div>
                        <input
                          type="text"
                          className={`from-control`}
                          id="tokenAval"
                          aria-describedby="emailHelp"
                          placeholder="0.0"
                         
                        />
                      </div>
                      
                    </div>
                    <div className={`${cascading.balancerow}`}>
                    
                     
                    <p className={`${cascading.inputlabel} mb-0 mt-2`}>
                      -12 BSC
                    </p>
              
                </div>
                  </div>

                  <p className={`${cascading.balancetext}`}>BSC Balace: 3.405</p>

            <ul className={`${cascading.button_ul}`}>
              <li>
                <button className={`btn me-2 ${cascading.buttonstyle_ul}`}>
                  1
                </button>
              </li>
              <li>
                <button className={`btn me-2 ${cascading.buttonstyle_ul}`}>
                 Max
                </button>
              </li>
            
            </ul>


 <div className='row mt-3'>
            <div className='col-8 col-md-7'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Cost (BSC)</p>
            </div>
            <div className='col-4 col-md-5'>
              <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>12 BSC</p>
            </div>
          </div>
          
          <div className='row mt-3'>
            <div className='col-8 col-md-7'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>0% Bulk discount</p>
            </div>
            <div className='col-4 col-md-5'>
              <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>~0 BSC</p>
            </div>
          </div>
         
         <hr />

         <div className='row mt-3'>
            <div className='col-8 col-md-7'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>You pay</p>
            </div>
            <div className='col-4 col-md-5'>
              <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>~1.2 BSC</p>
            </div>
          </div>
      

       
            <button
              className={`btn mt-4 ${cascading.connectwalletbtn}`}
              type="button"
              onClick={props.onHide}
            >
              Confirm
            </button>
            <button
              className={`btn mt-2 ${cascading.cancelbtn}`}
              type="button"
              onClick={props.onShowEdit}
            >
              View/ Edit Numbers
            </button>

            <p className={`${cascading.head} mt-4`}>
                  
                    "Buy Instantly" chooses random numbers, with no duplicates among your tickets. Prices are set before each round starts, equal to $5 at that time purchases are final.
                  </p>
          </Modal.Body>
        </Modal>
      </div>
    
    </>
  );
}
