import React, { useState } from 'react';
import cascading from '../../assests/css/swap.module.css';
import Footer from '../../common/footer'
import { FaArrowDown } from "react-icons/fa";

import { MdOutlineArrowDropDown } from "react-icons/md";


import moonpayimg from "../../assests/images/moonpay.png"

import Header from '../../common/header';
import { useNavigate } from 'react-router-dom';
import bnbimg from '../../assests/images/bnb.png'
import Walletmodal from '../../modals/walletmodal';
import { useSelector } from 'react-redux';
import Selecttoken from '../../modals/selecttoken';
import HeaderTabs from "../../common/HeaderTabs";
import Moonpaymodal from "../../modals/moonpaymodal";

const headerTabsNames = [
  {
    id: 1,
    name: "Swap",
    linkTo: "/swap",
  },
  {
    id: 2,
    name: "Liquidity",
    linkTo: "/liquidity",
  },
  {
    id: 3,
    name: "Buy Crypto",
    linkTo: "/buycrypto",
  }
];


export default function Buycrypto() {
    const navigate = useNavigate()
    const [swapsec, setSwapsec] = useState(true)
    const [walletmodalshow, setWalletmodalshow] = useState(false)
    const [tokenshow, setTokenshow] = useState(false);
    const [quoteshow, setQuoteshow] = useState(false);
  const [moonpay, setMoonpay] = useState(false);



    const userdetail = useSelector((state) => state.admin)
    return (
        <>
            <Header />
            <HeaderTabs headerTabsNames={headerTabsNames} />
            <div className={`${cascading.innerpages} ${cascading.swappage}`}>
                <div className={`container`}>
                    <div className={`row justify-content-center ${cascading.swapcardrow}`}>
                        <div className={`col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4`}>
                            <div className={` ${cascading.cardstyle}`}>
                                <div className={`${cascading.toprow}`}>
                                    <div>
                                        <p className={`${cascading.headings}`}>Buy Crypto</p>
                                        <p className={`${cascading.desc}`}>Buy crypto in just a few clicks</p>
                                    </div>
                                  
                                </div>
                                <div className={`${cascading.midrow}`}>
                                <div
                    className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}
                  >
                               
                                            <div className={` ${cascading.rightbtnsec}`}>
                                                <button className={`btn ${cascading.currencybtn}`} onClick={()=> setTokenshow(true)}>
                                                    {/* <img src={bnbimg} alt='bnb'className={`img-fluid ${cascading.coinimg}`}/>  */}
                                                    Select a Currency   <MdOutlineArrowDropDown className="fs-5" />
                                                    </button>
                                            </div>

                                        
                                        </div>
                                <div className={`${cascading.inputsec} ${cascading.inputsecrowblk} mb-4`}>
                                    <div className={`${cascading.balancerow}`}>
                                        <p className={`${cascading.inputlabel}`}>I want to spend</p>
                                    </div>

                                    <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                                        <div >
                                            <input type="email" className={`from-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="0.0" />
                                        </div>
                                       


                                    </div>

                                </div>
                                <div className={`row ${cascading.arrowrow}`} onClick={() => setSwapsec(!swapsec)}>
                                    <div className={`${cascading.swaparrow}`}>
                                        <FaArrowDown />
                                    </div>
                                   
                                </div>
                                <div
                    className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}
                  >
                               
                                            <div className={` ${cascading.rightbtnsec}`}>
                                                <button className={`btn ${cascading.currencybtn}`} onClick={()=> setTokenshow(true)}><img src={bnbimg} alt='bnb' className={`img-fluid ${cascading.coinimg}`} /> BNB  <MdOutlineArrowDropDown className="fs-5" /></button>
                                         
                                              </div>
                                              </div>
                             
                                <div className={`${cascading.inputsec} ${cascading.inputsecrowblk} mb-4`}>
                                <div className={`${cascading.balancerow}`}>
                                        <p className={`${cascading.inputlabel}`}>I want to buy</p>
                                    </div>
                                   
                                    <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                                        <div >
                                            <input type="email" className={`from-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="0.0" />
                                        </div>
                                      

                                    </div>

                                </div>

                                <p className={`${cascading.proceedtext}`}>Proceed to get live aggregated quotes from a variety of different fiat onramp providers.</p>
                                {userdetail?.walletStatus == null ||
                  userdetail?.walletStatus == "disconnect" ? (
                                <button
                      className={`btn ${cascading.connectwalletbtn}`}
                      onClick={() => setWalletmodalshow(true)}
                    >
                      Connect Wallet
                    </button>
                  )
                  :
                  <button
                      className={`btn ${cascading.connectwalletbtn}`}
                      onClick={() => setQuoteshow(true)}
                    >
                      Get Quotes
                    </button>
}
                            
                                </div>


                            </div>
{quoteshow &&
                            <div className={`mt-3 pb-1 ${cascading.cardstyle}`}>
                  <div
                    className={`${cascading.valuecardsec} ${cascading.valuecardsecnpng} `}
                  >
                    <div className={`${cascading.secrow}`}>
                      
                      <p
                        className={`mb-0 d-flex align-items-center ${cascading.label}`}
                      >
                                   <img src={moonpayimg} />

                   
                      </p>
                      <p className={`mb-0 ${cascading.labels}`}>
                      0.487 BNB
                      </p>
                    </div>

                    <div className={`mt-3 ${cascading.secrow}`}>
                      <p
                        className={`mb-0 d-flex align-items-center ${cascading.label}`}
                      >
                        BNB rate
                     
                      </p>
                      <p className={`mb-0 ${cascading.labels}`}>
                      603.361 USD
                      </p>
                    </div>

                    <div className={`mt-3 ${cascading.secrow}`}>
                      <p
                        className={`mb-0 d-flex align-items-center ${cascading.label}`}
                      >
                       Est. Total Fees
                       
                      </p>
                      <p className={`mb-0 ${cascading.labels}`}>
                        6.19 USD
                      </p>
                    </div>

                    <div className={`mt-3 ${cascading.secrow}`}>
                      <p
                        className={`mb-0 d-flex align-items-center ${cascading.label}`}
                      >
                      Networking Fees
                       
                      </p>
                      <p className={`mb-0 ${cascading.labels}`}>
                        0.1 USD
                      </p>
                    </div>

                    <div className={`mt-3 ${cascading.secrow}`}>
                      <p
                        className={`mb-0 d-flex align-items-center ${cascading.label}`}
                      >
                       Provider Fees
                       
                      </p>
                      <p className={`mb-0 ${cascading.labels}`}>
                        6.09 USD
                      </p>
                    </div>

                    <button
                      className={`btn ${cascading.connectwalletbtn} mt-4`}
                      onClick={() => setMoonpay(true)}
                    >
                      Buy with MoonPay
                    </button>
                   
                  </div>
                </div>
}
                         
                        </div>

                    </div>


                </div>


            </div>



            <Footer />
            {walletmodalshow && <Walletmodal show={walletmodalshow} onHide={() => setWalletmodalshow(false)} />}
            {tokenshow && <Selecttoken show={tokenshow} onHide={() => setTokenshow(false)} />}
            {moonpay && <Moonpaymodal show={moonpay} onHide={() => setMoonpay(false)} />}
       
             </>
    )
}
