import React, { useState, useEffect } from "react";
import cascading from "../../assests/css/farms.module.css";
import Accordion from "react-bootstrap/Accordion";
import Footer from "../../common/footer";
import Header from "../../common/header";
import { useNavigate } from "react-router-dom";
import { FaThList } from "react-icons/fa";
import { BsFillGrid3X2GapFill } from "react-icons/bs";
import { RiSearchLine } from "react-icons/ri";
import { BsGridFill } from "react-icons/bs";
import Switch from "react-switch";
import { Dropdown } from "react-bootstrap";
import Farmcard from "../../components/farmcard";
import bnblogo from "../../assests/images/bnb.png";
import coinlogo from "../../assests/images/coinicon.png";
import Farmlist from "../../components/farmlist";
import BigNumber from "bignumber.js";

//hooks
import { getFarmsInfo } from "../../hooks/useFarms";
import { getTokens } from "../../hooks/useTokens";
import { UserDeposits } from "../../hooks/useStake";

//lib
import { isEmpty } from "../../lib/isEmpty";
import { toFixedNumber } from "../../lib/FixedNumber";

import listView from "../../assests/images/listview.png";
import HeaderTabs from "../../common/HeaderTabs";

const headerTabsNames = [
  {
    id: 1,
    name: "Farms",
    linkTo: "/farms",
  },
  {
    id: 2,
    name: "Pools",
    linkTo: "/pools",
  }
];

export default function Farms() {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [listtype, setListtype] = useState(false);
  const [status, setStatus] = useState(true);
  const [stakedOnly, setStakedOnly] = useState(false);
  const [sortBy, setSortBy] = useState("Hot");

  const [farmdetails, setFarmdetails] = useState([
    {
      firstimg: bnblogo,
      secimg: coinlogo,
      fromname: "DSP",
      toname: "BNB",
      apr: "39,401,732,475.65%",
      multi: "1",
      liquidity: "0",
      earned: "0",
    },
    {
      firstimg: bnblogo,
      secimg: coinlogo,
      fromname: "DSP",
      toname: "BNB",
      apr: "39,401,732,475.65%",
      multi: "1",
      liquidity: "0",
      earned: "0",
    },
    {
      firstimg: bnblogo,
      secimg: coinlogo,
      fromname: "DSP",
      toname: "BNB",
      apr: "39,401,732,475.65%",
      multi: "1",
      liquidity: "0",
      earned: "0",
    },
  ]);

  const [Farms, setFarms] = useState([]);
  const [activeFarms, setActiveFarms] = useState([]);
  const [inactiveFarms, setinActiveFarms] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [stakedAmount, setStakedAmount] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("testnetTokens");

    let results = [];

    if (!isEmpty(searchTerm)) {
      let regx = new RegExp(searchTerm, "i");

      results = activeFarms?.filter((val) => {
        return val?.lpSymbol?.match(regx);
      });
    } else {
      results = activeFarms;
    }
    setSearchResults(results);
    console.log(activeFarms, "search", results);
  }, [searchTerm]);

  const onChangefn = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    farmsInfo();
  }, []);

  const farmsInfo = async () => {
    setLoading(true);
    console.log("form");
    var data;
    if (localStorage.getItem("FarmInterval")) {
      let oldInterval = localStorage.getItem("FarmInterval");
      clearInterval(oldInterval);
    }
    const interval = setInterval(async () => {
      console.log("interval");

      data = await getFarmsInfo();
      console.log("getFarmsInfo", data);
      setFarms(data);
      const active = data.filter((farm) => {
        console.log(
          !!farm.isTokenOnly === !!false && farm.multiplier !== "0X",
          "filter",
          !!true,
          !!farm.isTokenOnly
        );
        return !!farm.isTokenOnly === !!false && farm.multiplier !== "0X";
      });
      const inactive = data.filter(
        (farm) => !!farm.isTokenOnly === !!false && farm.multiplier === "0X"
      );
      setActiveFarms(active);
      setSearchResults(active);
      setinActiveFarms(inactive);
      setLoading(false);

      // let farms =[]
      console.log(active, "farms__farms");
    }, 3000);
    localStorage.setItem("FarmInterval", interval);
    // if ((localStorage.getItem("Farms"))) {
    //     setFarms(JSON.parse(localStorage.getItem("Farms")));
    //     let Farms = JSON.parse(localStorage.getItem("Farms"))
    //     const active = Farms.filter((farm) => !!farm.isTokenOnly === !!false && farm.multiplier !== '0X')
    //     console.log("active", active)
    //     const inactive = Farms.filter((farm) => !!farm.isTokenOnly === !!false && farm.multiplier === '0X')
    //     console.log("inactive", inactive)
    //     setActiveFarms(active);
    //     setSearchResults(active)
    //     setinActiveFarms(inactive);
    //     return
    // }

    console.log("interval", interval);

    // for(let i = 0;i<data.length;i++){
    //     let e= data[i]

    //     // return{
    //     //     ...farm,
    //     //     StakedAmount:
    //     // }
    //     if(i==data.length-1){
    //         console.log('StakedAmount',farms)

    //         setActiveFarms(farms)
    //         setSearchResults(farms)
    //     }
    // }
  };

  // const handleChange = nextChecked => {
  //     setChecked(nextChecked);
  // };
  console.log("activea", activeFarms);
  console.log("inactiveFarms", inactiveFarms);

  const stakedPools = (status) => {
    console.log("stakedPools");
    setChecked(status);
    setStakedOnly(status);
    if (status) {
      let FilterFarm = activeFarms.filter((val) => {
        return val.StakedAmount > 0;
      });
      console.log(FilterFarm, "FilterFarm");
      setSearchResults(FilterFarm);
    } else {
      setSearchResults(activeFarms);
    }
  };

  const HotOnclick = () => {
    console.log("HotOnclick", activeFarms);
    setSearchResults(activeFarms);
  };

  const APROnclick = () => {
    console.log("APROnclick");
    let Active = [...activeFarms];
    let apr = Active.sort((a, b) => {
      console.log("apr", a, b);
      return a.apy - b.apy;
    });
    console.log("apr", apr);
    setSearchResults(apr);
  };

  const MultiplierOnclick = () => {
    console.log("multiplierOnclick");
    let Active = [...activeFarms];
    let multiplier = Active.sort((a, b) => {
      console.log("multiplier", a, b);
      return a.multiplier - b.multiplier;
    });
    console.log("multiplier", multiplier);
    setSearchResults(multiplier);
  };

  const EarnedOnclick = () => {
    console.log("earnings");
    let Active = [...activeFarms];
    let earnings = Active.sort((a, b) => {
      console.log("earnings", a, b);
      return a.earnings - b.earnings;
    });
    console.log("earnings", earnings);
    setSearchResults(earnings);
  };
  const Liquidity = () => {
    let Active = [...activeFarms];
    let Liquidity = Active.sort((a, b) => {
      const totalLiquidity = new BigNumber(a?.lpTotalInQuoteToken).times(
        a?.quoteTokenPriceBusd
      );
      const totalLiquidity2 = new BigNumber(b?.lpTotalInQuoteToken).times(
        b?.quoteTokenPriceBusd
      );
      return totalLiquidity.toString() - totalLiquidity2.toString();
    });
    console.log("Liquidity", Liquidity);
    setSearchResults(Liquidity);
  };

  return (
    <>
      <Header />
      <HeaderTabs headerTabsNames={headerTabsNames} />
      <div className={`${cascading.innerpages} ${cascading.earnpage}`}>
        <div className={`container`}>
          <div className={`row ${cascading.headrow}`}>
            <p className={`${cascading.pagehead}`}>Farms</p>
            <p className={`${cascading.pagedesc}`}>Stake LP tokens to earn.</p>
          </div>

          <div className={`row ${cascading.filterrow}`}>
            <div className={`col-lg-6 mb-3 mb-lg-0`}>
              <div className={`${cascading.leftfiltersec}`}>
                <div className={`${cascading.liststyle}`}>
                  {/* <BsFillGrid3X2GapFill
                    onClick={() => setListtype(true)}
                    fill={listtype ? "#FE80C9" : "#9d85cd"}
                    fontSize={21}
                  /> */}
                  <BsGridFill
                    onClick={() => setListtype(true)}
                    fill={listtype ? "#7645d9 " : "#c1a5fc"}
                    fontSize={21}
                  />
                  {/* <FaThList
                    fill={!listtype ? "#FE80C9" : "#fff"}
                    fontSize={15}
                  /> */}
                  <img
                    src={listView}
                    alt="list"
                    className="img-fluid"
                    onClick={() => setListtype(false)}
                  />
                </div>
                <div className={`${cascading.togglesec} `}>
                  <Switch
                    // onChange={handleChange}
                    checked={checked}
                    className="react-switch"
                    onHandleColor="#fff"
                    offHandleColor="#fff"
                    onColor="#7645d9"
                    handleDiameter={10}
                    offColor="#9D85CD"
                    height={20}
                    width={40}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onChange={(e) => {
                      stakedPools(e);
                    }}
                  />

                  <span className={`${cascading.spanlabel}`}>Staked only</span>
                </div>
                <div className={`${cascading.tabsec}`}>
                  <p
                    onClick={() => setStatus(true)}
                    className={status ? `${cascading.active}` : ""}
                  >
                    Live
                  </p>
                  <p
                    onClick={() => setStatus(false)}
                    className={!status ? `${cascading.active}` : ""}
                  >
                    Finished
                  </p>
                </div>
              </div>
            </div>
            <div className={`offset-lg-2 col-lg-4 mb-3`}>
              <div className={`${cascading.rightfiltersec}`}>
                <div>
                  <p className={`${cascading.labelname} mb-2`}>Sort by</p>
                  <Dropdown
                    className={`${cascading.dropdownstyle}`}
                    onSelect={(e) => {
                      setSortBy(e);
                    }}
                  >
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {sortBy}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={`${cascading.dropdownmenustyle}`}>
                      <Dropdown.Item
                        eventKey="Hot"
                        onClick={() => {
                          HotOnclick();
                        }}
                      >
                        Hot
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#/action-1"
                        eventKey="APR"
                        onClick={() => {
                          APROnclick();
                        }}
                      >
                        APR
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#/action-2"
                        eventKey="Multiplier"
                        onClick={() => {
                          MultiplierOnclick();
                        }}
                      >
                        Multiplier
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#/action-3"
                        eventKey="Earned"
                        onClick={() => {
                          EarnedOnclick();
                        }}
                      >
                        Earned
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#/action-3"
                        eventKey="Liquidity"
                        onClick={() => {
                          Liquidity();
                        }}
                      >
                        Liquidity
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div>
                  <p className={`${cascading.labelname} mb-2`}>Search</p>
                  <div className={` ${cascading.inputstyle}`}>
                    <div className={`row h-100`}>
                      <div className="col-10">
                        <input
                          type="text"
                          className={`form-control p-0 `}
                          placeholder="Search Farms"
                          value={searchTerm}
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          onChange={onChangefn}
                        />
                      </div>
                      <div className="col-2 px-0 d-flex align-items-center ">
                        <RiSearchLine />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${cascading.farmcontentsec}  mt-4`}>
            {console.log("searchResults", searchResults)}

            {stakedOnly && isEmpty(searchResults) && (
              <p className={`${cascading.emptylabels}`}>
                Only Staked Pools will be displayed
              </p>
            )}

            {loading && (
              <p className={`${cascading.emptylabels}`}>Loading...</p>
            )}

            {isEmpty(searchResults) && !loading && (
              <p className={`${cascading.emptylabels}`}>No farms found!</p>
            )}

            {status ? (
              <>
                {listtype ? (
                  <div className={`row `}>
                    {searchResults &&
                      searchResults?.map((e) => (
                        <div className={`col-md-6 col-lg-4 mb-4`}>
                          <Farmcard datas={e} />
                        </div>
                      ))}
                  </div>
                ) : (
                  <div className={`${cascading.farmContentWrap}`}>
                    {searchResults &&
                      searchResults?.map((e, i) => (
                        <div className={`row `}>
                          <Farmlist datas={e} index={i} />
                        </div>
                      ))}
                  </div>
                )}
              </>
            ) : isEmpty(inactiveFarms) ? (
              <>
                <p className={`${cascading.emptylabels}`}>
                  These pools are no longer distributing rewards. Please unstake
                  your tokens.
                </p>
              </>
            ) : (
              <>
                {listtype ? (
                  <div className={`row `}>
                    {inactiveFarms &&
                      inactiveFarms?.map((e) => (
                        <div className={`col-md-6 col-lg-4 mb-4`}>
                          <Farmcard datas={e} status="inactive" />
                        </div>
                      ))}
                  </div>
                ) : (
                  <>
                    {inactiveFarms &&
                      inactiveFarms?.map((e, i) => (
                        <div className={`row`}>
                          <Farmlist datas={e} index={i} status="inactive" />
                        </div>
                      ))}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
