import React, { useState, useRef } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";

import Header from "../../common/header";
import Footer from "../../common/footer";

import cascading from "../../assests/css/Prediction.module.css";

import bscIcon from "../../assests/images/bscicon.png";
import clipPath from "../../assests/images/clippath.png";
import clock from "../../assests/images/clock.png";
import history from "../../assests/images/history.png";
import PredictionCard from "../../components/PredictionCard";

import expireIcon from "../../assests/images/expireicon.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

import SwiperCore from "swiper";
import PredictionModal from "../../modals/PredictionModal";
import { Offcanvas } from "react-bootstrap";
import HistoryCanva from "../../OffCanva/HistoryCanva";

// Install Swiper modules
SwiperCore.use([Navigation]);

const Prediction = () => {
  const swiperRef = useRef(null);

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  const [predictionCards, setPredictionCards] = useState([
    {
      id: 1,
      cardType: "Live",
      title: "Live",
      icon: expireIcon,
      cardCount: "#254034",
      payout: "1.96",
      priceType: "Closed Price",
      priceValue: "406.7348",
      increaseValue: "0.1500",
      lockedPrice: "406.1500",
      pricePool: "7.5566",
      downPayout: "2.04",
    },
    {
      id: 2,
      cardType: "Next",
      title: "Next",
      icon: expireIcon,
      cardCount: "#254033",
      payout: "1.96",
      priceType: "Closed Price",
      priceValue: "406.7348",
      increaseValue: "0.1500",
      lockedPrice: "406.1500",
      pricePool: "7.5566",
      downPayout: "2.04",
    },
    {
      id: 3,
      cardType: "Later",
      title: "Later",
      icon: expireIcon,
      cardCount: "#254033",
      payout: "1.96",
      priceType: "Closed Price",
      priceValue: "406.7348",
      increaseValue: "0.1500",
      lockedPrice: "406.1500",
      pricePool: "7.5566",
      downPayout: "2.04",
    },
    {
      id: 4,
      cardType: "Later",
      title: "Later",
      icon: expireIcon,
      cardCount: "#254033",
      payout: "1.96",
      priceType: "Closed Price",
      priceValue: "406.7348",
      increaseValue: "0.1500",
      lockedPrice: "406.1500",
      pricePool: "7.5566",
      downPayout: "2.04",
    },
    {
      id: 5,
      cardType: "Expired",
      title: "Expired",
      icon: expireIcon,
      cardCount: "#254033",
      payout: "1.96",
      priceType: "Closed Price",
      priceValue: "406.7348",
      increaseValue: "0.1500",
      lockedPrice: "406.1500",
      pricePool: "7.5566",
      downPayout: "2.04",
    },
    {
      id: 6,
      cardType: "Expired",
      title: "Expired",
      icon: expireIcon,
      cardCount: "#254033",
      payout: "1.96",
      priceType: "Closed Price",
      priceValue: "406.7348",
      increaseValue: "0.1500",
      lockedPrice: "406.1500",
      pricePool: "7.5566",
      downPayout: "2.04",
    },
    {
      id: 7,
      cardType: "Expired",
      title: "Expired",
      icon: expireIcon,
      cardCount: "#254033",
      payout: "1.96",
      priceType: "Closed Price",
      priceValue: "406.7348",
      increaseValue: "0.1500",
      lockedPrice: "406.1500",
      pricePool: "7.5566",
      downPayout: "2.04",
    },
  ]);
  const [predictionModalShow, setPredictionModalShow] = useState(true);
  const [isConnectWallet, setIsConnectWallet] = useState(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      {predictionModalShow && (
        <PredictionModal
          show={predictionModalShow}
          onHide={() => {
            setPredictionModalShow(false);
          }}
        />
      )}
      <Header />
      <div className={`${cascading.innerpages} ${cascading.predictionPage}`}>
        <div className={`container`}>
          <div className={`row flex-column ${cascading.topSection}`}>
            <h3 className={`text-center ${cascading.pageTitle}`}>Prediction</h3>
            <div className={`row py-5`}>
              <div
                className={`col-sm-4 col-md-4 d-flex justify-content-center justify-content-md-start`}
              >
                <button
                  className={`d-flex align-items-center justify-content-between ${cascading.valueButton}`}
                >
                  <div className={`d-flex align-items-center gap-2`}>
                    <div
                      className={`d-flex align-items-center justify-content-center ${cascading.logoBg}`}
                    >
                      <img src={bscIcon} alt="icons" className={`img-fluid`} />
                    </div>
                    <p className={`mb-0`}>BSC</p>
                  </div>
                  <p className={`mb-0 ${cascading.amount}`}>$45326</p>
                </button>
              </div>
              <div
                className={`d-flex col-sm-4 col-md-4 mt-3 mt-sm-0  d-md-flex justify-content-center align-items-center`}
              >
                <button
                  className="swiper-button-prev1 border-0 outline-0 bg-transparent"
                  onClick={() => goPrev()}
                >
                  <FaArrowLeft fill="#7645d9" fontSize={18} className="me-2" />
                </button>

                <div>
                  <img src={clipPath} alt="clip path" className={`img-fluid`} />
                </div>
                <button
                  className="swiper-button-next1 border-0 outline-0 bg-transparent"
                  onClick={() => goNext()}
                >
                  {" "}
                  <FaArrowRight fill="#7645d9" fontSize={18} className="ms-2" />
                </button>
              </div>
              <div
                className={`${cascading.right} ps-sm-0 pe-sm-0 col-sm-4 col-md-4  d-flex justify-content-center justify-content-md-end   align-items-center mt-4 mt-sm-0`}
              >
                <div className={`d-flex align-items-center gap-3 gap-sm-1 gap-md-3`}>
                  <p className={`mb-0 ${cascading.timerValue}`}>
                    03:19 <span className={`ms-1`}>5m</span>
                  </p>
                  <img
                    src={clock}
                    alt="clock"
                    className={`${cascading.clock}`}
                  />
                  <div
                    className={` d-flex align-items-center justify-content-center ${cascading.historyBg}`}
                    onClick={() => handleShow()}
                  >
                    <img src={history} alt="histroy" className={`img-fluid`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`prediction_swiper`}>
          {" "}
          <Swiper
            slidesPerView={"auto"}
            centeredSlides={true}
            spaceBetween={15}
            loop={true}
            ref={swiperRef}
            // navigation={{
            //   nextEl: ".next",
            //   prevEl: ".prev",
            // }}
            // pagination={{
            //   clickable: true,
            // }}
            navigation={{
              nextEl: ".swiper-button-next1",
              prevEl: ".swiper-button-prev1",
            }}
            modules={[Navigation]}
            className="mySwiper"
          >
            {predictionCards.map((val) => (
              <SwiperSlide>
                <PredictionCard
                  val={val}
                  isConnectWallet={isConnectWallet}
                  setIsConnectWallet={setIsConnectWallet}
                />
              </SwiperSlide>
            ))}
            {/* <button className="next">next</button>
            <button className="prev">prev</button> */}
          </Swiper>
        </div>
      </div>

      <Footer />

      <HistoryCanva show={show} handleClose={handleClose} />
    </div>
  );
};

export default Prediction;
