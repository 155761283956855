import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import cascading from "../assests/css/HistoryCanvaStyle.module.css";
import { IoMdClose } from "react-icons/io";
import { FaRegCirclePlay } from "react-icons/fa6";
import ClaimModal from "../modals/ClaimModal";

const HistoryCanva = (props) => {
  const [activeTabs, setActiveTabs] = useState(1);
  const [filterTabs, setFilterTabs] = useState(1);
  const [confirmShow, setCofirmShow] = useState(false);
  const [tabHead, setTabHead] = useState([
    {
      id: 1,
      heading: "Rounds",
    },
    {
      id: 2,
      heading: "PNL",
    },
  ]);
  const [filterHead, setFilterHead] = useState([
    { id: 1, heading: "All" },
    { id: 2, heading: "Collected" },
    { id: 3, heading: "Uncollected" },
  ]);

  const handleSelectTabs = (getId) => {
    setActiveTabs(getId);
  };

  const handleFilterTabs = (getId) => {
    setFilterTabs(getId);
  };
  return (
    <div>
      <Offcanvas
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        placement="end"
        className={`${cascading.canvaInner} canvaInner historyCanva`}
      >
        <Offcanvas.Header>
          <div
            className={`${cascading.header} d-flex align-items-center justify-content-between`}
          >
            <h5 className={`${cascading.title} mb-0`}>History</h5>
            <button
              className={`${cascading.closeButton}`}
              onClick={() => props.handleClose()}
            >
              <IoMdClose />
            </button>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className={`${cascading.body}`}>
            <div className={`${cascading.tabs}`}>
              <div className={`${cascading.tabRow} row mx-auto`}>
                <div
                  className={`${cascading.tabsWrap} d-flex align-items-center`}
                >
                  {tabHead.map((val) => (
                    <div
                      className={`${cascading.tabHead} ${
                        activeTabs === val.id ? `tabHeadActive` : ""
                      } col-6 px-0 d-flex justify-content-center`}
                      id={1}
                      onClick={() => handleSelectTabs(val.id)}
                    >
                      {val.heading}
                    </div>
                  ))}
                </div>
              </div>
              {activeTabs === 1 && (
                <div className={`${cascading.tabsContent}`}>
                  <div className={`${cascading.filter} filter pt-0`}>
                    <p className={`${cascading.filterLabel} mb-2`}>Filter</p>
                    <div
                      className={`${cascading.filterHeadWrap} d-flex align-items-center gap-3`}
                    >
                      {filterHead.map((filterVal) => (
                        <div className={`${cascading.filterValues}`}>
                          <div className={`d-flex align-items-center gap-2`}>
                            <div
                              className={`${cascading.filterChoose} ${
                                filterTabs === filterVal.id
                                  ? "filterChooseActive"
                                  : ""
                              }`}
                              onClick={() => handleFilterTabs(filterVal.id)}
                            ></div>
                            <p className={`${cascading.filterTitle}  mb-0 `}>
                              {filterVal.heading}
                            </p>
                          </div>{" "}
                        </div>
                      ))}
                    </div>{" "}
                  </div>{" "}
                  <div className={`${cascading.filterContentWrap} `}>
                    {filterTabs === 1 && (
                      <div className={`${cascading.filterContent}`}>
                        <div className={`${cascading.predictionSec}`}>
                          <p className={`${cascading.title} mb-0`}>
                            Showing history for Prediction v0.2
                          </p>
                          <div
                            className={`${cascading.desc} mb-0`}
                            style={{ cursor: "pointer" }}
                            onClick={() => setCofirmShow(true)}
                          >
                            Check for unclaimed v 0.1 winning
                          </div>
                        </div>
                        <div className={`${cascading.round}`}>
                          <div className={`row align-items-center`}>
                            <div className={`col-3`}>
                              <div>
                                <p className={`${cascading.title} mb-0`}>
                                  Round
                                </p>
                                <p className={`${cascading.value} mb-0`}>
                                  258939
                                </p>
                              </div>
                            </div>
                            <div className={`col-9`}>
                              <div
                                className={`${cascading.liveNow} d-flex align-items-center gap-2`}
                              >
                                <FaRegCirclePlay fontSize={22} />
                                <p className={` mb-0`}>Live Now</p>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                        <div
                          className={`${cascading.round} ${cascading.round2}`}
                        >
                          <div className={`row align-items-center`}>
                            <div className={`col-3`}>
                              <div>
                                {" "}
                                <p className={`${cascading.title} mb-0`}>
                                  Round
                                </p>
                                <p className={`${cascading.value} mb-0`}>
                                  258939
                                </p>
                              </div>
                            </div>
                            <div className={`col-9`}>
                              <div className={`${cascading.result} `}>
                                <p className={`${cascading.title} mb-0`}>
                                  Your Result
                                </p>
                                <p className={`${cascading.resultValue} mb-0`}>
                                  {" "}
                                  -0.0010
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {filterTabs === 2 && (
                      <div className={`${cascading.filterContent}`}>
                        <div className={`${cascading.predictionSec}`}>
                          <p className={`${cascading.title} mb-0`}>
                            Showing history for Prediction v0.2
                          </p>
                          <div
                            className={`${cascading.desc} mb-0`}
                            style={{ cursor: "pointer" }}
                            onClick={() => setCofirmShow(true)}
                          >
                            Check for unclaimed v 0.1 winning
                          </div>
                        </div>

                        <div className={`${cascading.noPrediction}`}>
                          <p className={`${cascading.title} mb-0`}>
                            No prediction history available
                          </p>
                          <p className={`${cascading.desc} mb-0`}>
                            If you are sure you should see history here, make
                            sure you're connected to the correct wallet and try
                            again.
                          </p>
                        </div>
                      </div>
                    )}
                    {filterTabs === 3 && (
                      <div className={`${cascading.filterContent}`}>
                        <div className={`${cascading.predictionSec}`}>
                          <p className={`${cascading.title} mb-0`}>
                            Showing history for Prediction v0.2
                          </p>
                          <div
                            className={`${cascading.desc} mb-0`}
                            style={{ cursor: "pointer" }}
                            onClick={() => setCofirmShow(true)}
                          >
                            Check for unclaimed v 0.1 winning
                          </div>
                        </div>
                        <div className={`${cascading.round}`}>
                          <div className={`row align-items-center`}>
                            <div className={`col-3`}>
                              <div>
                                <p className={`${cascading.title} mb-0`}>
                                  Round
                                </p>
                                <p className={`${cascading.value} mb-0`}>
                                  258939
                                </p>
                              </div>
                            </div>
                            <div className={`col-9`}>
                              <div
                                className={`${cascading.liveNow} d-flex align-items-center gap-2`}
                              >
                                <FaRegCirclePlay fontSize={22} />
                                <p className={` mb-0`}>Live Now</p>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    )}
                  </div>
                </div>
              )}{" "}
              {activeTabs === 2 && (
                <div className={`${cascading.pnlContent}`}>
                  <div className={`${cascading.historyDetails}`}>
                    <h5 className={`${cascading.title} mb-0`}>Your History</h5>

                    <div
                      className={`row mt-3 mx-auto d-flex align-items-center`}
                    >
                      <div className={`col-6 px-0`}>
                        <div
                          className={`${cascading.donut} d-flex flex-column align-items-center justify-content-center`}
                        >
                          <p className={`${cascading.wonText} mb-0 `}>Won</p>
                          <p className={`${cascading.percentage} `}>0/1</p>
                          <p className={`${cascading.wonText} mb-0`}>0.00%</p>
                        </div>
                      </div>
                      <div className={`col-6 px-0 `}>
                        <div>
                          <p className={`${cascading.violetText} mb-1`}>
                            Net results
                          </p>
                          <h4 className={`${cascading.bnbValue} mb-1`}>
                            -0.0010 BNB
                          </h4>
                          <p className={`${cascading.violetText} mb-0`}>
                            Net results
                          </p>
                        </div>
                      </div>
                    </div>

                    <div>
                      <p className={`${cascading.mainText} mt-4 mb-1`}>
                        Average return / Round
                      </p>
                      <p className={`${cascading.bnbDetails} mb-1`}>
                        -0.0010 BNB{" "}
                      </p>
                      <p className={`${cascading.aprox}`}>~-0.57</p>
                    </div>
                  </div>{" "}
                  <div className={`${cascading.historyDetails}`}>
                    <div>
                      <p className={`${cascading.mainText} mb-1`}>
                        Average return / Round
                      </p>
                      <p className={`${cascading.bnbDetails} mb-1`}>
                        -0.0010 BNB{" "}
                      </p>
                      <p className={`${cascading.aprox}`}>~-0.57</p>
                    </div>
                    <p className={`${cascading.mainText} mb-1 mt-4 pt-2`}>
                      Won
                    </p>
                    <div className={`row mt-2`}>
                      <div className={`col-4`}>
                        <div>
                          <p className={`${cascading.roundCount} mb-0`}>
                            0 rounds
                          </p>
                          <p className={`${cascading.roundPercent} `}>0.00%</p>
                        </div>
                      </div>{" "}
                      <div className={`col-8`}>
                        <div>
                          <p className={`${cascading.roundCount} mb-0`}>
                            +0 BNB
                          </p>
                          <p className={`${cascading.roundPercent}`}>~$0.57</p>
                        </div>
                      </div>
                    </div>{" "}
                    <p className={`${cascading.mainText} mb-1 mt-4 `}>Lost</p>
                    <div className={`row mt-2`}>
                      <div className={`col-4`}>
                        <div>
                          <p className={`${cascading.roundCount} mb-0`}>
                            1 rounds
                          </p>
                          <p className={`${cascading.roundPercent} `}>
                            100.00%
                          </p>
                        </div>
                      </div>{" "}
                      <div className={`col-8`}>
                        <div>
                          <p className={`${cascading.roundCount} mb-0`}>
                            -0.0010 BNB
                          </p>
                          <p className={`${cascading.roundPercent}`}>~$0.57</p>
                        </div>
                      </div>
                    </div>{" "}
                    <p className={`${cascading.mainText} mb-1 mt-4 `}>
                      Entered
                    </p>
                    <div className={`row mt-2`}>
                      <div className={`col-4`}>
                        <div>
                          <p className={`${cascading.roundCount} mb-0`}>
                            1 rounds
                          </p>
                          <p className={`${cascading.roundPercent} `}>Total</p>
                        </div>
                      </div>{" "}
                      <div className={`col-8`}>
                        <div>
                          <p className={`${cascading.roundCount} mb-0`}>
                            0.0010 BNB
                          </p>
                          <p className={`${cascading.roundPercent}`}>~$0.57</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {confirmShow && (
        <ClaimModal
          show={confirmShow}
          onHide={() => {
            setCofirmShow(false);
          }}
        />
      )}
    </div>
  );
};

export default HistoryCanva;
