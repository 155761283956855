import React, { useState, useEffect } from "react";
import cascading from "../assests/css/landing.module.css";
import Footer from "../common/footer";
import Header from "../common/header";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import bannerimg from "../assests/images/bannersec.png";
import AOS from "aos";
import swapicon from "../assests/images/home/swap.png";
import liqicon from "../assests/images/home/liquidity.png";
import coinicon from "../assests/images/home/coin.png";
import farmicon from "../assests/images/home/farm.png";
import poolicon from "../assests/images/home/pools.png";
import lotteryicon from "../assests/images/home/lottery.png";
import preditioncion from "../assests/images/home/prediction.png";
import addremoveicon from "../assests/images/home/removeadd.png";
import ticketicon from "../assests/images/home/ticket.png";


import swapgrp from "../assests/images/home/swapgrp.png";
import regimg from "../assests/images/home/reg_img.png";
import defiimg from "../assests/images/home/defi.png";
import predicon from "../assests/images/home/predicon.png";
import techimg from "../assests/images/home/techimg.png";
import shadowimg from "../assests/images/home/shadow.png";
import patternimg from "../assests/images/home/pattern.png";
import "aos/dist/aos.css";
import Walletmodal from "../modals/walletmodal";

export default function Landingpage() {
  const [showmodal, setShowmodal] = useState(false);
  const options = {
    items: 3,
    dots: false,
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1200: {
        items: 3,
      },
    },
  };
  const [features, setFeatures] = useState([
    {
      img: swapicon,
      head: "Swap",
      desc: "Trade crypto instantly across BSC chain",
      categories: "Trade",
    },
    {
      img: liqicon,
      head: "Liquidity",
      desc: "Fund liquidity pools, earn trading fees",
      categories: "Trade",
    },
    {
      img: addremoveicon,
      head: "Add/Remove Liquidity",
      desc: "Add or remove your liquidity",
      categories: "Trade",
    },
    {
      img: coinicon,
      head: "Buy Crypto",
      desc: "Purchasing cryptos with fiat through  moonpay",
      categories: "Earn",
    },
    {
      img: farmicon,
      head: "Farms",
      desc: "Depsoit LP tokens in the farms and earn native token",
      categories: "Earn",
    },
   
    {
      img: poolicon,
      head: "Pools",
      desc: "Stake native token in pools and earns extra rewards in native token",
      categories: "Earn",
    },
    {
      img: lotteryicon,
      head: "Lottery Game",
      desc: "Innovative feature that allows users to participate",
      categories: "Game",
    },
    {
      img: preditioncion,
      head: "Prediction",
      desc: "Predict the price of BNB whether it goes high or low and win rewards",
      categories: "Game",
    },
    {
      img: ticketicon,
      head: "Buy Tickets",
      desc: "Randomised & Manual Ticket Numbers and win rewards",
      categories: "Game",
    },
  ]);

  return (
    <>
      {showmodal && (
        <Walletmodal show={showmodal} onHide={() => setShowmodal(false)} />
      )}
      <Header />

      <section className={`${cascading.bannersec}`}>
        <img
          src={shadowimg}
          className={`img-fluid ${cascading.shadowleft}`}
          alt="shadow_img"
        />
        <img
          src={shadowimg}
          className={`img-fluid ${cascading.shadowright}`}
          alt="shadow_img"
        />
        <div className={`container`}>
          <div className={`row ${cascading.bannerrow}`}>
            <div className={`col-lg-6 ${cascading.leftsec}`}>
              <img
                src={patternimg}
                className={`${cascading.pattern}`}
                alt="pattern"
              />
              <p className={`${cascading.head}`}>
                <span className={`${cascading.headline}`}>Everyone's</span>{" "}
                <br />
                Favorite <span className={`${cascading.headspan}`}>DEX</span>
              </p>
              <p className={`${cascading.desc}`}>
                Trade, earn, and own crypto on the BSC chain, decentralized platform in the gallery.
              </p>
              <div className={`${cascading.btnsec}`}>
                <button
                  className={`btn ${cascading.connectwalletbtn}`}
                  onClick={() => setShowmodal(true)}
                >
                  Connect Wallet
                </button>

                <Link
                  to="/swap"
                  className={`btn d-flex align-items-center justify-content-center ${cascading.connectwalletbtn1}`}
                >
                  Trade Now
                </Link>
              </div>
            </div>
            <div className={`offset-lg-1 col-lg-5`}>
              <img src={bannerimg} className={`img-fluid`} alt="banner_img" />
            </div>
          </div>
        </div>
      </section>
      <section className={`${cascading.marqsec}`}>
        <Marquee>
          <div>
            <p className={`${cascading.marqtext}`}>
              GM_BNB LP: (162.915% APR) AOG-BUSD LP (150.437% APR)
            </p>
          </div>
          <div>
            <p className={`${cascading.marqtext}`}>
              GM_BNB LP: (162.915% APR) AOG-BUSD LP (150.437% APR)
            </p>
          </div>
          <div>
            <p className={`${cascading.marqtext}`}>
              GM_BNB LP: (162.915% APR) AOG-BUSD LP (150.437% APR)
            </p>
          </div>
          <div>
            <p className={`${cascading.marqtext}`}>
              GM_BNB LP: (162.915% APR) AOG-BUSD LP (150.437% APR)
            </p>
          </div>
        </Marquee>
      </section>
      <section className={`${cascading.featuresec}`}>
        <div className={`container`}>
          <div className={`row ${cascading.headrow}`}>
            <p className={`${cascading.catname}`}>Features</p>
            <p className={`${cascading.head}`}>Discover the ecosystem</p>
          </div>
          <div className={`${cascading.firstsec}`}>
            <p className={`${cascading.sechead}`}>Trade</p>
            <OwlCarousel
              className="owl-theme"
              loop
              margin={20}
              dots
              dotsEach={true}
              {...options}
            >
              {features
                .filter((item) => item.categories == "Trade")
                .map((e) => (
                  <div class="item">
                    <div className={`${cascading.featurecard}`}>
                      <img
                        src={e.img}
                        alt="icon"
                        className={`img-fluid ${cascading.icons}`}
                      />
                      <div>
                        <p className={`${cascading.name}`}>{e.head}</p>
                        <p className={`${cascading.desc}`}>{e.desc}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </OwlCarousel>
          </div>
          <div className={`${cascading.secsec} mt-4`}>
            <p className={`${cascading.sechead}`}>Earn</p>
            <OwlCarousel
              className="owl-theme"
              loop
              margin={20}
              dots
              dotsEach={true}
              {...options}
            >
              {features
                .filter((item) => item.categories == "Earn")
                .map((e) => (
                  <div class="item">
                    <div className={`${cascading.featurecard}`}>
                      <img
                        src={e.img}
                        alt="icon"
                        className={`img-fluid ${cascading.icons}`}
                      />
                      <div>
                        <p className={`${cascading.name}`}>{e.head}</p>
                        <p className={`${cascading.desc}`}>{e.desc}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </OwlCarousel>
          </div>
          <div className={`${cascading.lastsec} mt-4`}>
            <p className={`${cascading.sechead}`}>Game</p>
            <OwlCarousel
              className="owl-theme"
              margin={20}
              loop
              dots
              dotsEach={true}
              {...options}
            >
              {features
                .filter((item) => item.categories == "Game")
                .map((e) => (
                  <div class="item">
                    <div className={`${cascading.featurecard}`}>
                      <img
                        src={e.img}
                        alt="icon"
                        className={`img-fluid ${cascading.icons}`}
                      />
                      <div>
                        <p className={`${cascading.name}`}>{e.head}</p>
                        <p className={`${cascading.desc}`}>{e.desc}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </OwlCarousel>
          </div>
        </div>
      </section>
      <section className={`${cascading.regsec}`}>
        <div className={`container`}>
          <div className={`row align-item-center`}>
            <div className={`col-lg-6`}>
              <p className={`${cascading.head}`}>
                <span>Prediction</span> <br />
                Connect wallet & win rewards
              </p>
              <p className={`${cascading.desc}`}>
              Win BNB if you can predict whether the BNB price will rise or fall.
New rounds every 5 minutes!
              </p>
              <p className={`${cascading.desc}`}>
              Keep watching at the
game, price changes from high to low pretty quick. And it's pure luck whether
the price go high or low in that 5 minutes of time frame.
              </p>
              <div className={`${cascading.btnsec}`}>
                {/* <button
                  className={`btn ${cascading.connectwalletbtn}`}
                  onClick={() => setShowmodal(true)}
                >
                  Connect Wallet
                </button> */}
                <Link
                  to="/swap"
                  className={`btn d-flex align-items-center justify-content-center ${cascading.connectwalletbtn1}`}
                >
                  Trade Now
                </Link>
              </div>
            </div>
            <div className={`col-lg-6`}>
              <img src={regimg} className={`img-fluid`} alt="reg_img" />
            </div>
          </div>
        </div>
      </section>
      <section className={`${cascading.joinsec}`}>
        <div className={`container`}>
          <div className={`row ${cascading.topbtnsec}`}>
            <button className={`btn ${cascading.connectwalletbtn}`}>
              JOIN WITH USDTSWAP
            </button>
          </div>
          <div className={`row ${cascading.headrow}`}>
            <p className={`${cascading.head}`}>
              <span>Used by Millions.</span> Trusted with Billions.
            </p>
            <p className={`${cascading.desc}`}>
              Trade, earn, and own crypto on the BSC chain most
              popular
              <br />
              decentralized platform in the gallery.
            </p>
          </div>
          <div className={`row mt-4`}>
            <div className={`col-md-6 col-lg-4 mb-4`}>
              <div className={`${cascading.joincard}`}>
                <img
                  src={swapgrp}
                  className={`img-fluid ${cascading.joinicon}`}
                  alt="coin"
                />
                <p className={`${cascading.head}`}>Swap</p>
                <p className={`${cascading.desc}`}>with multiple tokens</p>
              </div>
            </div>
            <div className={`col-md-6 col-lg-4 mb-4`}>
              <div className={`${cascading.joincard}`}>
                <img
                  src={swapgrp}
                  className={`img-fluid ${cascading.joinicon}`}
                  alt="coin"
                />
                <p className={`${cascading.head}`}>Games</p>
                <p className={`${cascading.desc}`}>Lottery and Prediction</p>
              </div>
            </div>
            <div className={`col-md-6 col-lg-4 mb-4`}>
              <div className={`${cascading.joincard}`}>
                <img
                  src={swapgrp}
                  className={`img-fluid ${cascading.joinicon}`}
                  alt="coin"
                />
                <p className={`${cascading.head}`}>Buy Crypto</p>
                <p className={`${cascading.desc}`}>Moonpay</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={`${cascading.regsec}`}>
        <div className={`container`}>
          <div className={`${cascading.rowrev} row align-item-center`}>
            <div className={`col-lg-6`}>
              <img src={defiimg} className={`img-fluid`} alt="reg_img" />
            </div>
            <div className={`offset-lg-1 col-lg-5 ${cascading.deficontentsec}`}>
              <p className={`${cascading.head}`}>
                <span>USDTSWAP</span>
              </p>
              <p className={`${cascading.desc}`}>
              Embark on a seamless trading journey with USDTSwap, Decentralised finance platform on BNB Smart Chain. 
              </p>  
              <p className={`${cascading.desc}`}>
               The DAPP will be coming up
with the Chainlink price feed, Lottery module, prediction game as in pancakeswap
and Moon pay payment gateway integration.
              </p>
              <p className={`${cascading.desc}`}>
              A cutting-edge, trustworthy, and vibrant DeFi platform offering dependable returns on investment.
              </p>
              {/* <div className={`${cascading.btnsec}`}>
                <button
                  className={`btn ${cascading.connectwalletbtn}`}
                  onClick={() => setShowmodal(true)}
                >
                  Connect Wallet
                </button>
                <Link
                  to="/swap"
                  className={`btn d-flex align-items-center justify-content-center ${cascading.connectwalletbtn1}`}
                >
                  Trade Now
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className={`${cascading.joinsec}`}>
        <div className={`container`}>
          <div className={`row ${cascading.headrow}`}>
            <p className={`${cascading.head}`}>
              <span>Win Millions</span> in Prizes.
            </p>
            <p className={`${cascading.desc}`}>
            Shaping the Future of Decentralized gaming 
             
            </p>
            <div className={`row mt-4 ${cascading.pricecardrow}`}>
              <div className={`col-md-6 col-lg-4 mb-4`}>
                <div className={`${cascading.pricecard}`}>
                  <img
                    src={predicon}
                    className={`img-fluid ${cascading.priceicon}`}
                    alt="predicon"
                  />
                  <p className={`${cascading.name}`}>Prediction</p>
                  {/* <p className={`${cascading.price}`}>$760 million</p> */}
                  <p className={`${cascading.desc}`}>
                    Will BNB price rise or fall? guess correctly to win!
                  </p>
                  <button
                    className={`btn ${cascading.connectwalletbtn} mt-4`}
                    onClick={() => setShowmodal(true)}
                  >
                    Connect Wallet
                  </button>
                </div>
              </div>
              <div className={`col-md-6 col-lg-4 mb-4`}>
                <div className={`${cascading.pricecard}`}>
                  <img
                    src={predicon}
                    className={`img-fluid ${cascading.priceicon}`}
                    alt="predicon"
                  />
                  <p className={`${cascading.name}`}>Lottery</p>
                  {/* <p className={`${cascading.price}`}>$760 million</p> */}
                  <p className={`${cascading.desc}`}>
                  Innovative feature that allows users to participate in a randomised draw
                  </p>
                  <button
                    className={`btn ${cascading.connectwalletbtn} mt-4`}
                    onClick={() => setShowmodal(true)}
                  >
                    Connect Wallet
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={`${cascading.blocktechsec}`}>
        <div className={`container`}>
          <div className={`row ${cascading.techrow}`}>
            <div className="col-lg-7 mb-4">
              <p className={`${cascading.head}`}>
                USDTSwap Expands to <br />
                BSC Network
              </p>
              <p className={`${cascading.desc}`}>
              Experience the power of community ownership, global governance, and explore infinite use cases within the USDTSwap ecosystem.
              </p>
              <button
                className={`btn ${cascading.connectwalletbtn}`}
                onClick={() => setShowmodal(true)}
              >
                Join Now!
              </button>
            </div>
            <div className="offset-lg-1 col-lg-4">
              <img src={techimg} className={`img-fluid`} alt="techimg" />
            </div>
          </div>
        </div>
      </section>
      <section className={`${cascading.tradsec}`}>
        <div className={`container`}>
          <div className={`${cascading.tradrow}`}>
            <p className={`${cascading.head}`}>
              Join USDTSwap and start <br />
              Trading Today !
            </p>
            <div className={`${cascading.btnsec}`}>
              {/* <button
                className={`btn ${cascading.connectwalletbtn}`}
                onClick={() => setShowmodal(true)}
              >
                Connect Wallet
              </button> */}
              <Link
                to="/swap"
                className={`btn d-flex align-items-center justify-content-center ${cascading.connectwalletbtn1}`}
              >
                Trade Now
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
