import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import cascading from "../assests/css/headerstyle.module.css";
import { FaTelegramPlane } from "react-icons/fa";
// import logo from "../assests/images/techlogo.png";
import logo from "../assests/images/usdtlogo.png";
import { FaAngleDown } from "react-icons/fa";
// import optmimg from "../assests/images/eth.png";
import optmimg from "../assests/images/bsc.png";

import { Link } from "react-router-dom";
import Walletmodal from "../modals/walletmodal";
import Networkmodal from "../modals/networkmodal";
import Calculatormodal from "../modals/calculatormodal";

import Settingsmodal from "../modals/settingsmodal";
import { walletControl } from "../lib/localStorage";
import { useDispatch, useSelector } from "react-redux";
import Yourwallet from "../modals/yourwallet";
export default function Header() {
  const [netmodal, setNetmodal] = useState(false);
  const [showmodal, setShowmodal] = useState(false);
  const [showmymodal, setShowmymodal] = useState(false);
  const [tabmodal, setTabmodal] = useState(false);
  const userdetail = useSelector((state) => state.admin);
  console.log("userdetail_userdetail", userdetail);
  const dispatch = useDispatch();
  const location = useLocation();
  const walletdis = () => {
    dispatch(walletControl("disconnect"));
    localStorage.setItem("walletStatus", "disconnect");
  };

  const walletdetail = useSelector((state) => state.wallet);
  console.log("walletdetail", walletdetail);

  return (
    <>
      <div className={`${cascading.header}`}>
        <div className={`container ${cascading.customcontainer}`}>
          <div className={`${cascading.headerrow}`}>
            <div className={`${cascading.leftsec}`}>
              <Link to="/">
                {" "}
                <img
                  src={logo}
                  alt="logo"
                  className={` ${cascading.logoimg}`}
                />
              </Link>
              <ul className={`${cascading.navsec} mb-0`}>
                <li className={`${cascading.hidemble}`}>
                  <div className={`${cascading.dropdown}`}>
                    <button className={`${cascading.dropbtn}`}>Trade </button>
                    <div className={`${cascading.dropcontent}`}>
                      <Link to="/swap">Swap</Link>
                      <Link to="/liquidity">Liquidity</Link>
                      <Link to="/buycrypto">Buy Crypto</Link>

                    </div>
                  </div>
                </li>
                <li className={`${cascading.hidemble}`}>
                  <div className={`${cascading.dropdown}`}>
                    <button className={`${cascading.dropbtn}`}>Earn</button>
                    <div className={`${cascading.dropcontent}`}>
                      <Link to="/farms">Farms</Link>
                      <Link to="/pools">Pools</Link>
                    </div>
                  </div>
                </li>
                <li className={`${cascading.hidemble}`}>
                  <div className={`${cascading.dropdown}`}>
                    <button className={`${cascading.dropbtn}`}>Game</button>
                    <div className={`${cascading.dropcontent}`}>
                      <Link to="/prediction">Prediction</Link>
                      <Link to="/lottery">Lottery</Link>

                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div className={`${cascading.rightside}`}>
              {/* <div>
                <IoMdSettings className={`${cascading.settingicon}`} fill='#fff' />
              </div> */}
              <div className={`${cascading.networksec}`}>
                {/* <button className={`${cascading.networkbtn}`}<img src={optmimg} alt='' className={`${cascading.netimg}`} /> <p className={`d-none d-lg-block mb-0`}>Ethereum</p></button> */}
                {/* <button
                  className={`${cascading.networkbtn}`}
                  onClick={() => setNetmodal(true)}
                >
                  <img src={optmimg} alt="" className={`${cascading.netimg}`} />{" "}
                  <p className={`d-none d-lg-block mb-0`}>BNB</p>
                </button> */}
              </div>
              <div>
                {
                  userdetail && userdetail?.walletStatus == "connect" ? (
                    <div className={`${cascading.headdrop}`}>
                      <div className={`${cascading.btnsec}`}>
                        {console.log(
                          "wallet",
                          userdetail?.walletStatus == "connect"
                        )}

                        <svg
                          viewBox="0 0 24 24"
                          color="primary"
                          width="24px"
                          xmlns="http://www.w3.org/2000/svg"
                          class="sc-bdnxRM yzynT"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M17 4C18.5 4 19 4.5 19 6L19 8C20.1046 8 21 8.89543 21 10L21 17C21 19 20 20 17.999 20H6C4 20 3 19 3 17L3 7C3 5.5 4.5 4 6 4L17 4ZM5 7C5 6.44772 5.44772 6 6 6L19 6L19 8L6 8C5.44772 8 5 7.55229 5 7ZM17 16C18 16 19.001 15 19 14C18.999 13 18 12 17 12C16 12 15 13 15 14C15 15 16 16 17 16Z"
                          ></path>
                        </svg>
                        <button className={`btn ${cascading.dropbtns}`}>
                          <span className={`${cascading.dropbtnstext}`}>
                            {`${(walletdetail?.walletaddress).substring(
                              0,
                              5
                            )}...${walletdetail?.walletaddress.substring(
                              38,
                              42
                            )}`}{" "}
                          </span>
                          <FaAngleDown
                            fill="#fff"
                            className={`${cascading.arrowicon}`}
                          />
                        </button>
                      </div>

                      <div className={`${cascading.dropcontentstyle}`}>
                        <ul>
                          <li
                            onClick={() => {
                              setShowmymodal(true);
                              setTabmodal(true);
                            }}
                          >
                            Wallet
                          </li>
                          <li
                            onClick={() => {
                              setShowmymodal(true);
                              setTabmodal(false);
                            }}
                          >
                            Transactions
                          </li>
                          <li onClick={() => walletdis()}>
                            Disconnect{" "}
                            <svg
                              viewBox="0 0 24 24"
                              fill="#7645d9"
                              color="text"
                              width="20px"
                              xmlns="http://www.w3.org/2000/svg"
                              class="sc-bdnxRM bNbPUR"
                            >
                              <path d="M16.3 8.09014C15.91 8.48014 15.91 9.10014 16.3 9.49014L18.2 11.3901H9C8.45 11.3901 8 11.8401 8 12.3901C8 12.9401 8.45 13.3901 9 13.3901H18.2L16.3 15.2901C15.91 15.6801 15.91 16.3001 16.3 16.6901C16.69 17.0801 17.31 17.0801 17.7 16.6901L21.29 13.1001C21.68 12.7101 21.68 12.0801 21.29 11.6901L17.7 8.09014C17.31 7.70014 16.69 7.70014 16.3 8.09014ZM4 19.3901H11C11.55 19.3901 12 19.8401 12 20.3901C12 20.9401 11.55 21.3901 11 21.3901H4C2.9 21.3901 2 20.4901 2 19.3901V5.39014C2 4.29014 2.9 3.39014 4 3.39014H11C11.55 3.39014 12 3.84014 12 4.39014C12 4.94014 11.55 5.39014 11 5.39014H4V19.3901Z"></path>
                            </svg>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <button
                      className={`btn ${cascading.connectwalletbtn}`}
                      type="button"
                      onClick={() => setShowmodal(true)}
                    >
                      {" "}
                      Connect Wallet
                    </button>
                  )
                  // <button className={`btn ${cascading.connectwalletbtn}`} type='button' > Connect Wallet</button>
                }
              </div>
            </div>
          </div>
        </div>

        <div className={`${cascading.mobileheader}`}>
          <div className={`${cascading.menurow}`}>
            <div>
              <div className={`${cascading.dropdown}`}>
                <div className={`${cascading.imgrow}`}>
                  {/* <img src={tradeimg} className={`img-fluid ${cascading.menuimg}`} alt='menuimg' />
                   */}
                  <svg
                    viewBox="0 0 20 20"
                    color="secondary"
                    width="20px"
                    xmlns="http://www.w3.org/2000/svg"
                    class="sc-bdnxRM cRvwtG"
                  >
                    <path d="M1.87 6.163a.75.75 0 01-1.06-1.06l2.608-2.61H1.694a.75.75 0 110-1.5h3.535a.75.75 0 01.75.75V5.28a.75.75 0 11-1.5 0V3.554L1.87 6.164zM13.072 1.976a5 5 0 01.421 9.983A6.505 6.505 0 008.09 6.555a5 5 0 014.982-4.579z"></path>
                    <path d="M12.072 12.976a5 5 0 10-10 0 5 5 0 0010 0zM19.26 14.213a.75.75 0 010 1.061l-2.61 2.609h1.726a.75.75 0 010 1.5H14.84a.75.75 0 01-.75-.75v-3.536a.75.75 0 011.5 0v1.725l2.609-2.609a.75.75 0 011.06 0z"></path>
                  </svg>
                  <button className={`${cascading.dropbtn}`}>Trade </button>
                </div>
                <div
                  className={`${cascading.dropcontent} ${cascading.dropcontentone}`}
                >
                  <Link to="/swap">Swap</Link>
                  <Link to="/liquidity">Liquidity</Link>
                  <Link to="/buycrypto">Buy Crypto</Link>

                </div>
              </div>
            </div>
            <div>
              <div className={`${cascading.dropdown}`}>
                <div className={`${cascading.imgrow}`}>
                  <svg
                    fill="#7645D9"
                    viewBox="0 0 24 24"
                    color="textSubtle"
                    width="20px"
                    xmlns="http://www.w3.org/2000/svg"
                    class="sc-bdnxRM cRvwtG"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.4063 19.9329C12.066 19.9329 12.6742 19.779 13.207 19.5106V21.6632C12.6449 21.8378 12.0415 21.9329 11.4063 21.9329H7.00792C2.52538 21.9329 -0.374267 17.1964 1.66429 13.2043L3.70684 9.20426C4.30576 8.03138 5.25922 7.11243 6.39803 6.55101L5.46396 4.68288C5.08785 3.93066 5.54693 3.02913 6.3765 2.89087L11.6153 2.01773C12.5647 1.8595 13.3292 2.78847 12.9912 3.68962L11.9333 6.51092C13.1087 7.06815 14.094 8.00302 14.7074 9.20426L16.6114 12.9329H14.3657L12.9261 10.1138C12.2427 8.77534 10.8666 7.93292 9.36372 7.93292H9.05047C7.54759 7.93292 6.17153 8.77534 5.48805 10.1138L3.4455 14.1138C2.08646 16.7753 4.01956 19.9329 7.00792 19.9329H11.4063ZM10.6623 4.20415L7.70695 4.69671L8.32504 5.93291H10.014L10.6623 4.20415Z"
                    ></path>
                    <path d="M14.707 13.9329C14.4309 13.9329 14.207 14.1568 14.207 14.4329V15.4329C14.207 15.7091 14.4309 15.9329 14.707 15.9329H20.707C20.9832 15.9329 21.207 15.7091 21.207 15.4329V14.4329C21.207 14.1568 20.9832 13.9329 20.707 13.9329H14.707Z"></path>
                    <path d="M15.207 17.4329C15.207 17.1568 15.4309 16.9329 15.707 16.9329H21.707C21.9832 16.9329 22.207 17.1568 22.207 17.4329V18.4329C22.207 18.7091 21.9832 18.9329 21.707 18.9329H15.707C15.4309 18.9329 15.207 18.7091 15.207 18.4329V17.4329Z"></path>
                    <path d="M14.707 19.9329C14.4309 19.9329 14.207 20.1568 14.207 20.4329V21.4329C14.207 21.7091 14.4309 21.9329 14.707 21.9329L20.707 21.9329C20.9832 21.9329 21.207 21.7091 21.207 21.4329V20.4329C21.207 20.1568 20.9832 19.9329 20.707 19.9329L14.707 19.9329Z"></path>
                    <path d="M9.9212 9.93292C9.9212 9.51871 9.58541 9.18292 9.1712 9.18292C8.75699 9.18292 8.4212 9.51871 8.4212 9.93292V10.2471C7.4372 10.4874 6.70692 11.3749 6.70692 12.4329C6.70692 13.6756 7.71427 14.6829 8.95691 14.6829H9.64423C10.0043 14.6829 10.3136 14.9388 10.381 15.2926C10.469 15.7548 10.1147 16.1829 9.64423 16.1829H8.89883C8.62969 16.1829 8.38118 16.0387 8.24765 15.805L8.1081 15.5608C7.90259 15.2012 7.44445 15.0762 7.08481 15.2817C6.72517 15.4872 6.60023 15.9454 6.80573 16.305L6.94528 16.5492C7.26526 17.1092 7.80531 17.4979 8.4212 17.6317V17.9329C8.4212 18.3471 8.75699 18.6829 9.1712 18.6829C9.58541 18.6829 9.9212 18.3471 9.9212 17.9329V17.6662C11.1913 17.5114 12.101 16.3061 11.8545 15.0119C11.6524 13.9507 10.7245 13.1829 9.64423 13.1829H8.95691C8.5427 13.1829 8.20692 12.8471 8.20692 12.4329C8.20692 12.0187 8.5427 11.6829 8.95691 11.6829H9.44357C9.71272 11.6829 9.96123 11.8271 10.0948 12.0608L10.2343 12.305C10.4398 12.6647 10.898 12.7896 11.2576 12.5841C11.6172 12.3786 11.7422 11.9205 11.5367 11.5608L11.3971 11.3166C11.0771 10.7566 10.5371 10.3679 9.9212 10.2341V9.93292Z"></path>
                  </svg>
                  {/* <img src={earnimg} className={`img-fluid ${cascading.menuimg}`} alt='menuimg' /> */}
                  <button className={`${cascading.dropbtn}`}>Earn</button>
                </div>
                <div
                  className={`${cascading.dropcontent} ${cascading.dropcontenttwo}`}
                >
                  <Link to="#">Farms</Link>
                  <Link to="#">Pools</Link>
                </div>
              </div>
            </div>
            <div>
              <div className={`${cascading.dropdown} ${cascading.lastmenu}`}>
                <div className={`${cascading.imgrow}`}>
                  {/* <img src={yumimg} className={`img-fluid ${cascading.menuimg}`} alt='menuimg' /> */}
                  <svg
                    fill="#7645D9"
                    viewBox="0 0 24 24"
                    color="textSubtle"
                    width="20px"
                    xmlns="http://www.w3.org/2000/svg"
                    class="sc-bdnxRM cRvwtG"
                  >
                    <path d="M8.99984 5.89661V6.27661C8.16984 5.94661 7.27984 5.77661 6.38984 5.77661C4.96985 5.77661 3.54985 6.20661 2.33985 7.06661C1.82985 7.42661 1.76985 8.15661 2.20985 8.59661L4.77985 11.1666H5.88984V12.2766C6.74984 13.1366 7.86984 13.5866 8.99984 13.6366V15.8966H6.99985C6.44984 15.8966 5.99985 16.3466 5.99985 16.8966V18.8966C5.99985 19.9966 6.89985 20.8966 7.99984 20.8966H17.9998C19.6598 20.8966 20.9998 19.5566 20.9998 17.8966V5.89661C20.9998 5.34661 20.5498 4.89661 19.9998 4.89661H9.99984C9.44984 4.89661 8.99984 5.34661 8.99984 5.89661ZM7.88984 11.3066V9.15661H5.60984L4.56985 8.11661C5.13985 7.89661 5.75985 7.77661 6.38984 7.77661C7.72984 7.77661 8.97985 8.29661 9.92984 9.23661L11.3398 10.6466L11.1398 10.8466C10.6298 11.3566 9.94984 11.6466 9.21984 11.6466C8.74985 11.6466 8.28984 11.5266 7.88984 11.3066ZM18.9998 17.8966C18.9998 18.4466 18.5498 18.8966 17.9998 18.8966C17.4498 18.8966 16.9998 18.4466 16.9998 17.8966V16.8966C16.9998 16.3466 16.5498 15.8966 15.9998 15.8966H10.9998V13.3066C11.5698 13.0766 12.0998 12.7366 12.5598 12.2766L12.7598 12.0766L15.5898 14.8966H16.9998V13.4866L10.9998 7.51661V6.89661H18.9998V17.8966Z"></path>
                  </svg>
                  <button className={`${cascading.dropbtn}`}>Game</button>
                </div>
                <div
                  className={`${cascading.dropcontent} ${cascading.dropcontentthree}`}
                >
                  <Link to="/prediction">Prediction</Link>
                      <Link to="/lottery">Lottery</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showmodal && (
        <Walletmodal show={showmodal} onHide={() => setShowmodal(false)} />
      )}
      {netmodal && (
        <Networkmodal show={netmodal} onHide={() => setNetmodal(false)} />
      )}
      {/* {netmodal && <Calculatormodal show={netmodal} onHide={() => setNetmodal(false)} />} */}

      {showmymodal && (
        <Yourwallet
          show={showmymodal}
          statedata={tabmodal}
          onHide={() => setShowmymodal(false)}
        />
      )}
    </>
  );
}
