import React, { useState } from "react";
import cascading from "../assests/css/PredictionCard.module.css";

import { IoPlayCircleOutline } from "react-icons/io5";
import { FaRegClock } from "react-icons/fa";
import { FaArrowUp, FaArrowDown } from "react-icons/fa6";
import { CgUnavailable } from "react-icons/cg";
import PredictionFlipCard from "./PredictionFlipCard";

const PredictionCard = ({ val, isConnectWallet, setIsConnectWallet }) => {
  console.log("propsssss", val.cardType);

  const [flipCard, setFlipCard] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isDownClick, setIsDownClick] = useState(false);
  const [enterDown, setEnterDown] = useState(false);

  const handleEnterDown = () => {
    setFlipCard(true);
    setIsDownClick(true);
  };
  return (
    <>
      {val.cardType === "Live" ? (
        <div className={`${cascading.predictionCardPage}`}>
          <div className={`${cascading.predictSliderCard}`}>
            <div className={`${cascading.cardSecondaryBg} mx-auto`}>
              <div
                className={`${cascading.cardTop} d-flex align-items-center justify-content-between`}
              >
                <div
                  className={`${cascading.liveContainer} d-flex align-items-center gap-2`}
                >
                  <IoPlayCircleOutline fontSize={20} fontWeight={900} />
                  <p className={`mb-0 ${cascading.liveLabel}`}>{val.title}</p>
                </div>
                <p className={`${cascading.cardCount} mb-0`}>{val.cardCount}</p>
              </div>
              <div className={` ${cascading.progress} position-relative`}>
                <div className={`${cascading.totalProgress} `}></div>
                <div
                  className={`${cascading.updatedProgress} position-absolute`}
                  style={{ width: "50%" }}
                ></div>
              </div>

              <div className={`${cascading.cardBottom} `}>
                <div className={`${cascading.upperImage} `}>
                  <div className={` d-flex flex-column align-items-center`}>
                    <h5 className={`${cascading.rangeLabel} mb-0  `}>UP</h5>
                    <p className={`${cascading.payoutLabel} mb-0`}>
                      <span>{val.payout}x </span>Payout
                    </p>
                  </div>
                </div>
                <div className={`${cascading.borderContent} mx-auto`}>
                  <p className={`${cascading.priceLabel} mb-0`}>Last price</p>

                  <div
                    className={`d-flex align-items-center justify-content-between mt-3`}
                  >
                    <h5 className={`${cascading.priceAmount} mb-0`}>
                      ${val.priceValue}
                    </h5>
                    <button className={`${cascading.valueButton}`}>
                      <FaArrowUp className={`me-2`} />${val.increaseValue}
                    </button>
                  </div>

                  <div
                    className={`${cascading.lockedPrice} d-flex align-items-center justify-content-between mt-4`}
                  >
                    <p className={` mb-0`}>Locked Price :</p>
                    <p className={` mb-0`}>${val.lockedPrice}</p>
                  </div>
                  <div
                    className={`${cascading.prizePool} d-flex align-items-center justify-content-between mt-1`}
                  >
                    <p className={` mb-0`}>Prize Pool :</p>
                    <p className={`mb-0`}>{val.pricePool} BNB</p>
                  </div>
                </div>{" "}
                <div className={`${cascading.bottomImage} `}>
                  <div className={` d-flex flex-column align-items-center`}>
                    <p className={`${cascading.payoutLabelBottom} mb-0`}>
                      <span>{val.downPayout}x </span>Payout
                    </p>
                    <h5 className={`${cascading.rangeLabelBottom} mb-0`}>
                      Down
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : val.cardType === "Next" ? (
        <>
          {enterDown ? (
            <div className={`${cascading.flipCard}  `}>
              <div
                className={`${cascading.flipCardInner} ${
                  flipCard ? "flipActive" : ""
                }`}
              >
                <div className={`${cascading.flipCardFront} `}>
                  <div className={`${cascading.predictionCardPage}`}>
                    <div
                      className={`${cascading.predictSliderCard} ${cascading.predictSliderCardGray} ${cascading.enterDown}`}
                    >
                      <div className={`${cascading.cardSecondaryBg} mx-auto`}>
                        <div
                          className={`${cascading.cardTopNext} d-flex align-items-center justify-content-between`}
                        >
                          <div
                            className={`${cascading.liveContainer} d-flex align-items-center gap-2`}
                          >
                            <IoPlayCircleOutline
                              fontSize={20}
                              fontWeight={900}
                            />
                            <p className={`mb-0 ${cascading.liveLabel}`}>
                              {val.title}
                            </p>
                          </div>
                          <p className={`${cascading.cardCount} mb-0`}>
                            {val.cardCount}
                          </p>
                        </div>
                        <div
                          className={` ${cascading.nonProgress} position-relative`}
                        ></div>

                        <div
                          className={`${cascading.cardBottom} position-relative
                          `}
                        >
                          <div
                            className={`${cascading.enterLabel} position-absolute`}
                          >
                            {" "}
                            Entered
                          </div>
                          <div className={`${cascading.upperImageNext} `}>
                            <div
                              className={` d-flex flex-column align-items-center`}
                            >
                              <h5 className={`${cascading.rangeLabel} ${cascading.rangeLabeldowntext} mb-0  `}>
                                Down
                              </h5>
                              <p className={`${cascading.payoutLabel} mb-0`}>
                                <span>{val.payout}x </span>Payout
                              </p>
                            </div>
                          </div>

                          <div
                            className={`${cascading.borderContent}  mx-auto d-flex flex-column justify-content-center gap-3`}
                          >
                            <button className={`${cascading.upButton}`}>
                              <FaArrowDown /> Down Entered
                            </button>{" "}
                            <div
                              className={`${cascading.prizePool} d-flex align-items-center justify-content-between mt-1`}
                            >
                              <p className={` mb-0`}>Prize Pool :</p>
                              <p className={`mb-0`}>6.9344 BNB</p>
                            </div>{" "}
                          </div>

                          <div className={`${cascading.bottomImage} `}>
                            <div
                              className={` d-flex flex-column align-items-center`}
                            >
                              <p
                                className={`${cascading.payoutLabelBottom} mb-0`}
                              >
                                <span>{val.downPayout}x </span>Payout
                              </p>
                              <h5
                                className={`${cascading.rangeLabelBottom} ${cascading.rangeLabeluptext} mb-0`}
                              >
                                Up
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>{" "}
                <div className={`${cascading.flipCardBack}`}>
                  {" "}
                  <PredictionFlipCard
                    setFlipCard={setFlipCard}
                    isConnectWallet={isConnectWallet}
                    setIsConnectWallet={setIsConnectWallet}
                    setIsConfirm={setIsConfirm}
                    isDownClick={isDownClick}
                    setIsDownClick={setIsDownClick}
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              {isConfirm ? (
                <div className={`${cascading.flipCard}  `}>
                  <div
                    className={`${cascading.flipCardInner} ${
                      flipCard ? "flipActive" : ""
                    }`}
                  >
                    <div className={`${cascading.flipCardFront} `}>
                      <div className={`${cascading.predictionCardPage}`}>
                        <div
                          className={`${cascading.predictSliderCard} ${cascading.predictSliderCardGray} ${cascading.confirmCard}`}
                        >
                          <div
                            className={`${cascading.cardSecondaryBg} mx-auto`}
                          >
                            <div
                              className={`${cascading.cardTopNext} d-flex align-items-center justify-content-between`}
                            >
                              <div
                                className={`${cascading.liveContainer} d-flex align-items-center gap-2`}
                              >
                                <IoPlayCircleOutline
                                  fontSize={20}
                                  fontWeight={900}
                                />
                                <p className={`mb-0 ${cascading.liveLabel}`}>
                                  {val.title}
                                </p>
                              </div>
                              <p className={`${cascading.cardCount} mb-0`}>
                                {val.cardCount}
                              </p>
                            </div>
                            <div
                              className={` ${cascading.nonProgress} position-relative`}
                            ></div>

                            <div
                              className={`${cascading.cardBottom} position-relative
                          `}
                            >
                              <div
                                className={`${cascading.enterLabel} position-absolute`}
                              >
                                {" "}
                                Entered
                              </div>
                              <div className={`${cascading.upperImageNext} `}>
                                <div
                                  className={` d-flex flex-column align-items-center`}
                                >
                                  <h5
                                    className={`${cascading.rangeLabel} mb-0  `}
                                  >
                                    UP
                                  </h5>
                                  <p
                                    className={`${cascading.payoutLabel} mb-0`}
                                  >
                                    <span>{val.payout}x </span>Payout
                                  </p>
                                </div>
                              </div>

                              <div
                                className={`${cascading.borderContent}  mx-auto d-flex flex-column justify-content-center gap-3`}
                              >
                                <button className={`${cascading.upButton}`}>
                                  <FaArrowUp /> UP Entered
                                </button>{" "}
                                <div
                                  className={`${cascading.prizePool} d-flex align-items-center justify-content-between mt-1`}
                                >
                                  <p className={` mb-0`}>Prize Pool :</p>
                                  <p className={`mb-0`}>6.9344 BNB</p>
                                </div>{" "}
                              </div>

                              <div className={`${cascading.bottomImage} `}>
                                <div
                                  className={` d-flex flex-column align-items-center`}
                                >
                                  <p
                                    className={`${cascading.payoutLabelBottom} mb-0`}
                                  >
                                    <span>{val.downPayout}x </span>Payout
                                  </p>
                                  <h5
                                    className={`${cascading.rangeLabelBottom} mb-0`}
                                  >
                                    Down
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>{" "}
                    <div className={`${cascading.flipCardBack}`}>
                      {" "}
                      <PredictionFlipCard
                        setFlipCard={setFlipCard}
                        isConnectWallet={isConnectWallet}
                        setIsConnectWallet={setIsConnectWallet}
                        setIsConfirm={setIsConfirm}
                        isDownClick={isDownClick}
                        setIsDownClick={setIsDownClick}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className={`${cascading.flipCard} `}>
                  <div
                    className={`${cascading.flipCardInner} ${
                      flipCard ? "flipActive" : ""
                    }`}
                  >
                    <div className={`${cascading.flipCardFront} `}>
                      <div className={`${cascading.predictionCardPage}`}>
                        <div
                          className={`${cascading.predictSliderCard} ${cascading.predictSliderCardGray}`}
                        >
                          <div
                            className={`${cascading.cardSecondaryBg} mx-auto`}
                          >
                            <div
                              className={`${cascading.cardTopNext} d-flex align-items-center justify-content-between`}
                            >
                              <div
                                className={`${cascading.liveContainer} d-flex align-items-center gap-2`}
                              >
                                <IoPlayCircleOutline
                                  fontSize={20}
                                  fontWeight={900}
                                />
                                <p className={`mb-0 ${cascading.liveLabel}`}>
                                  {val.title}
                                </p>
                              </div>
                              <p className={`${cascading.cardCount} mb-0`}>
                                {val.cardCount}
                              </p>
                            </div>
                            <div
                              className={` ${cascading.nonProgress} position-relative`}
                            ></div>

                            <div className={`${cascading.cardBottom} `}>
                              <div className={`${cascading.upperImageNext} `}>
                                <div
                                  className={` d-flex flex-column align-items-center`}
                                >
                                  <h5
                                    className={`${cascading.rangeLabel} mb-0  `}
                                  >
                                    UP
                                  </h5>
                                  <p
                                    className={`${cascading.payoutLabel} mb-0`}
                                  >
                                    <span>{val.payout}x </span>Payout
                                  </p>
                                </div>
                              </div>

                              <div
                                className={`${cascading.borderContent} mx-auto`}
                              >
                                <div
                                  className={`${cascading.prizePool} d-flex align-items-center justify-content-between mt-1`}
                                >
                                  <p className={` mb-0`}>Prize Pool :</p>
                                  <p className={`mb-0`}>6.9344 BNB</p>
                                </div>{" "}
                                <div className={`d-flex flex-column mt-4`}>
                                  <button
                                    className={`${cascading.upButton}`}
                                    onClick={() => setFlipCard(true)}
                                  >
                                    Enter UP
                                  </button>
                                  <button
                                    className={`${cascading.downButton}`}
                                    onClick={() => handleEnterDown()}
                                  >
                                    Enter DOWN
                                  </button>
                                </div>
                              </div>

                              <div className={`${cascading.bottomImage} `}>
                                <div
                                  className={` d-flex flex-column align-items-center`}
                                >
                                  <p
                                    className={`${cascading.payoutLabelBottom} mb-0`}
                                  >
                                    <span>{val.downPayout}x </span>Payout
                                  </p>
                                  <h5
                                    className={`${cascading.rangeLabelBottom} mb-0`}
                                  >
                                    Down
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>{" "}
                    <div className={`${cascading.flipCardBack}`}>
                      {" "}
                      <PredictionFlipCard
                        setFlipCard={setFlipCard}
                        isConnectWallet={isConnectWallet}
                        setIsConnectWallet={setIsConnectWallet}
                        setIsConfirm={setIsConfirm}
                        isDownClick={isDownClick}
                        setIsDownClick={setIsDownClick}
                        setEnterDown={setEnterDown}
                      />
                    </div>
                  </div>
                </div>
              )}{" "}
            </>
          )}
        </>
      ) : val.cardType === "Expired" ? (
        <div
          className={`${cascading.predictionCardPage}`}
          style={{ opacity: "0.4" }}
        >
          <div
            className={`${cascading.predictSliderCard} ${cascading.predictSliderCardGray}`}
          >
            <div className={`${cascading.cardSecondaryBg} mx-auto`}>
              <div
                className={`${cascading.cardTopExpire} d-flex align-items-center justify-content-between`}
              >
                <div
                  className={`${cascading.liveContainer} d-flex align-items-center gap-2`}
                >
                  <CgUnavailable fontSize={20} fontWeight={900} />
                  <p className={`mb-0 ${cascading.liveLabel}`}>{val.title}</p>
                </div>
                <p className={`mb-0`}>{val.cardCount}</p>
              </div>
              <div
                className={` ${cascading.nonProgress} position-relative`}
              ></div>

              <div className={`${cascading.cardBottom} `}>
                <div className={`${cascading.upperImage} `}>
                  <div className={` d-flex flex-column align-items-center`}>
                    <h5 className={`${cascading.rangeLabel} mb-0  `}>UP</h5>
                    <p className={`${cascading.payoutLabel} mb-0`}>
                      <span>{val.payout}x </span>Payout
                    </p>
                  </div>
                </div>
                <div className={`${cascading.borderContent} mx-auto`}>
                  <p className={`${cascading.priceLabel} mb-0`}>Last price</p>

                  <div
                    className={`d-flex align-items-center justify-content-between mt-3`}
                  >
                    <h5 className={`${cascading.priceAmount} mb-0`}>
                      ${val.priceValue}
                    </h5>
                    <button className={`${cascading.valueButton}`}>
                      <FaArrowUp className={`me-2`} />${val.increaseValue}
                    </button>
                  </div>

                  <div
                    className={`${cascading.lockedPrice} d-flex align-items-center justify-content-between mt-4`}
                  >
                    <p className={` mb-0`}>Locked Price :</p>
                    <p className={` mb-0`}>${val.lockedPrice}</p>
                  </div>
                  <div
                    className={`${cascading.prizePool} d-flex align-items-center justify-content-between mt-1`}
                  >
                    <p className={` mb-0`}>Prize Pool :</p>
                    <p className={`mb-0`}>{val.pricePool} BNB</p>
                  </div>
                </div>{" "}
                <div className={`${cascading.bottomImage} `}>
                  <div className={` d-flex flex-column align-items-center`}>
                    <p className={`${cascading.payoutLabelBottom} mb-0`}>
                      <span>{val.downPayout}x </span>Payout
                    </p>
                    <h5 className={`${cascading.rangeLabelBottom} mb-0`}>
                      Down
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={`${cascading.predictionCardPage}`}>
          <div
            className={`${cascading.predictSliderCard} ${cascading.predictSliderCardGray}`}
          >
            <div className={`${cascading.cardSecondaryBg} mx-auto`}>
              <div
                className={`${cascading.cardTopLater}  d-flex align-items-center justify-content-between`}
              >
                <div
                  className={`${cascading.liveContainer} d-flex align-items-center gap-2`}
                >
                  <FaRegClock fontSize={18} />
                  {/* <IoPlayCircleOutline fontSize={20} fontWeight={900} /> */}
                  <p className={`mb-0 ${cascading.liveLabel}`}>{val.title}</p>
                </div>
                <p className={`${cascading.cardCount} mb-0`}>{val.cardCount}</p>
              </div>
              <div
                className={` ${cascading.nonProgress} position-relative`}
              ></div>

              <div className={`${cascading.cardBottom} `}>
                <div className={`${cascading.upperImageLater} `}>
                  <div className={` d-flex flex-column align-items-center`}>
                    <h5 className={`${cascading.rangeLabel} mb-0  `}>UP</h5>
                  </div>
                </div>
                <div
                  className={`${cascading.borderContent} ${cascading.borderMinHeight} mx-auto d-flex flex-column align-items-center justify-content-center`}
                >
                  <p className={`${cascading.entryLabel}`}>Entry starts</p>
                  <h5 className={`${cascading.timeLabel}`}>~03:19</h5>
                </div>{" "}
                <div className={`${cascading.bottomImagelater} `}>
                  <div className={` d-flex flex-column align-items-center `}>
                    <h5 className={`${cascading.rangeLabelBottom} mb-0`}>
                      Down
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PredictionCard;
