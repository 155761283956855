
export const FARMS = [
    {
        pid: 1,
        lpAddress: '0xEcfe31bA1D60b07327Aef79450a7BE3036eA7785',//"0x8454944735BD946A7dcabE41Dba8433A2b4779B7",
        lpSymbol: "BNB-DT LP",
        tokenSymbol: "BNB",
        tokenAddress: "0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c",
        quoteTokenSymbol: "DT",
        quoteTokenAddress: "0xa2c5C316365981B1ff903b76AB0b1B7f3A77C7E5",
        isTokenOnly: false,
        rewardToken: "0xa2c5C316365981B1ff903b76AB0b1B7f3A77C7E5"
    },
    {
        pid: 2,
        lpAddress: "0xB3B8C50fd47EFd04D86f21be10DB6e7546b7CE1E",
        lpSymbol: "DT-BUSD LP",
        tokenSymbol: "DT",
        tokenAddress: "0xa2c5C316365981B1ff903b76AB0b1B7f3A77C7E5",
        quoteTokenSymbol: "BUSD",
        quoteTokenAddress: "0x91C7bE4449c76a47EAe1605De7Bcd5a03Cc7d3d2",
        isTokenOnly: false,
        rewardToken: "0xa2c5C316365981B1ff903b76AB0b1B7f3A77C7E5"
    },
    {
        pid: 3,
        lpAddress: "0xf837742A38E79275F26e20B067FF98bC4f283D7F",
        lpSymbol: "BNB-BUSD LP",
        tokenSymbol: "BNB",
        tokenAddress: "0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c",
        quoteTokenSymbol: "BUSD",
        quoteTokenAddress: "0x91C7bE4449c76a47EAe1605De7Bcd5a03Cc7d3d2",
        isTokenOnly: false,
        rewardToken: "0xa2c5C316365981B1ff903b76AB0b1B7f3A77C7E5"
    }

    // {
    //     pid: 1,
    //     lpSymbol: 'DOUB-BNB LP',
    //     lpAddress: '0x56cf2Ea3921A353c6DEa3cBDde1BA5Ef10406DCB',
    //     tokenSymbol: "DOUB",
    //     quoteTokenSymbol: "WBNB",
    //     tokenAddress: '0x2F30560d17044230eF4c6cEb9768D860e2cFf36E',
    //     quoteTokenAddress: "0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c",
    //     isTokenOnly: false,
    //     rewardToken: "0x2F30560d17044230eF4c6cEb9768D860e2cFf36E"
    // },
    // {
    //     pid: 2,
    //     lpSymbol: 'DOUB-BUSD LP',
    //     lpAddress: '0x8e0793d00c4b08A7F4501D273CDF4BBaDeDF3CF5',
    //     tokenSymbol: "DOUB",
    //     quoteTokenSymbol: "BUSD",
    //     tokenAddress: '0x2F30560d17044230eF4c6cEb9768D860e2cFf36E',
    //     quoteTokenAddress: "0x91C7bE4449c76a47EAe1605De7Bcd5a03Cc7d3d2",
    //     isTokenOnly: false,
    //     rewardToken: "0x2F30560d17044230eF4c6cEb9768D860e2cFf36E"

    // },
    // {
    //     pid: 3,
    //     lpSymbol: 'BNB-BUSD LP',
    //     lpAddress: '0x74a4e192887d8FD0c3761f6ae6CCCfFa0fc65AA1',
    //     tokenSymbol: "WBNB",
    //     quoteTokenSymbol: "BUSD",
    //     tokenAddress: '0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c',
    //     quoteTokenAddress: "0x91C7bE4449c76a47EAe1605De7Bcd5a03Cc7d3d2",
    //     isTokenOnly: false,
    //     rewardToken: "0x2F30560d17044230eF4c6cEb9768D860e2cFf36E"

    // }
]