import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import cascading from "../assests/css/networkstyle.module.css";
import metaicon from "../assests/images/metamask.png";
import moonpayimg from "../assests/images/moonpay.png";

import { IoMdClose } from "react-icons/io";
export default function Moonpaymodal(props) {
  const [moonpay, setMoonpay] = useState(true);

  return (
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal
        centered
        show={moonpay}
        onHide={props.onHide}
        className={`${cascading.modalstyle}`}
      >
        <Modal.Header
          className={`${cascading.modalclr} ${cascading.modalheader}`}
        >
          <h4 className={`${cascading.modaltitle} mb-0`}>
            <img src={moonpayimg} />
          </h4>
          <IoMdClose
            className={`${cascading.closebtn}`}
            onClick={props.onHide}
          />
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody} ${cascading.modalbodymoonpay}`}>
         
         
        </Modal.Body>
      </Modal>
    </div>
  );
}
